/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField, TextFieldProps } from '@mui/material';

import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

type RhfProps = {
  name: string;
  control: Control;
  error: any;
  variant?: 'filled' | 'standard' | 'outlined';
};

type Props = Omit<TextFieldProps, 'variant' | 'error'> & RhfProps;

const RhfTextField: React.FC<Props> = ({
  name,
  label,
  control,
  defaultValue,
  error,
  rows,
  type,
  variant = 'outlined',
  ...rest
}) => {
  const intl = useIntl();
  return (
    <Controller
      render={({ field: { onChange, value, ref, ...props } }) => (
        <TextField
          label={label}
          value={value}
          error={Boolean(error)}
          multiline={!!rows}
          rows={rows}
          type={type}
          helperText={
            error && intl.formatMessage(error.message, error.message.values)
          }
          onChange={onChange}
          inputRef={ref}
          variant={variant}
          {...props}
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default RhfTextField;
