import { all } from 'redux-saga/effects';

import coupons from './coupons';
import course from './course';
import image from './image';
import level from './level';
import session from './session';
import user from './user';
import users from './users';
import workout from './workout';
import workouts from './workouts';

export default function* rootSaga() {
  yield all([
    session(),
    user(),
    users(),
    workout(),
    workouts(),
    course(),
    coupons(),
    level(),
    image(),
  ]);
}
