import { Button, ButtonProps } from '@mui/material';

import React, { useMemo } from 'react';

import { LoadingCircle } from './styles';

interface Props extends ButtonProps {
  loading?: boolean;
}

const LoadingButton: React.FC<Props> = ({
  loading,
  color,
  variant,
  children,
  ...rest
}) => {
  const className = useMemo(() => (loading ? 'loading' : undefined), [loading]);

  return (
    <Button className={className} variant={variant} color={color} {...rest}>
      {loading ? (
        <LoadingCircle
          size="1rem"
          buttonvariant={variant}
          color={color || 'primary'}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default LoadingButton;
