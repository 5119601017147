/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import FilterAutocomplete, {
  FilterAutocompleteOption,
  FilterAutocompleteOptionId,
  FilterAutocompleteProps,
} from '~/components/Inputs/FilterAutocomplete';

type RhfProps = {
  name: string;
  control: Control;
  error: any;

  defaultValue: FilterAutocompleteOptionId | FilterAutocompleteOptionId[];
};

type GetValueOrVauesOption =
  | FilterAutocompleteOptionId
  | FilterAutocompleteOptionId[]
  | string[];

type Props = Omit<FilterAutocompleteProps, 'defaultValue' | 'error'> & RhfProps;

const RhfFilterAutocomplete: React.FC<Props> = ({
  name,
  labelId,
  control,
  defaultValue,
  error,
  ...rest
}) => {
  const intl = useIntl();

  const getValueOrValues = useCallback((option: GetValueOrVauesOption) => {
    const isArray = Array.isArray(option);

    if (!isArray) {
      return option.value;
    }

    const values = option.map((item) => {
      const isString = typeof item === 'string';
      return isString ? item : item.value;
    });

    return values;
  }, []);

  return (
    <Controller
      render={({ field: { ref, onChange, ...props } }) => (
        <FilterAutocomplete
          onChange={(_event, option) => {
            const optionTyped = option as
              | FilterAutocompleteOption
              | FilterAutocompleteOption[];

            const result = getValueOrValues(optionTyped);
            onChange(result);
          }}
          labelId={labelId}
          error={!!error}
          inputRef={ref}
          helperText={
            error && intl.formatMessage(error.message, error.message.values)
          }
          {...props}
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue={getValueOrValues(defaultValue)}
    />
  );
};

export default RhfFilterAutocomplete;
