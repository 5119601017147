import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MuiAccordion = {
  defaultProps?: ComponentsProps['MuiAccordion'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAccordion'];
  variants?: ComponentsVariants['MuiAccordion'];
};

const styles = (theme: Theme): MuiAccordion => ({
  styleOverrides: {
    root: {
      borderRadius: '16px !important',

      background: theme.palette.grey[50],
      boxShadow: 'none',

      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },

      '&:before': {
        display: 'none',
      },

      '.MuiAccordionSummary-root': {
        position: 'relative',
        color: theme.palette.primary.main,
      },

      '.MuiAccordionSummary-content': {
        alignItems: 'center',
        marginLeft: theme.spacing(3),
      },

      '.MuiAccordionSummary-expandIconWrapper': {
        position: 'absolute',
        left: theme.spacing(2),
      },
    },
  },
});

export default styles;
