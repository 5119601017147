import { Grid } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { configurationCourseValidationSchema } from '~/helpers/yup';
import useTranslate from '~/hooks/useTranslate';

import { CourseForm } from '~/store/types/course';

import ImageDropzone from '~/components/ImageDropzone';
import RhfFilterAutocomplete from '~/components/RhfComponents/RhfFilterAutocomplete';
import RhfTextField from '~/components/RhfComponents/RhfTextField';

import { DropzoneLabel } from '../../styles';

const currencies = [
  {
    labelId: 'currency_eur',
    value: 'EUR',
  },
  {
    labelId: 'currency_usd',
    value: 'USD',
  },
];

interface Props {
  initialValues: CourseForm;
  onSubmit: (data: CourseForm) => void;
}

const ConfigurationCourseModalForm = ({ initialValues, onSubmit }: Props) => {
  const translate = useTranslate();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CourseForm>({
    defaultValues: initialValues,
    resolver: yupResolver(configurationCourseValidationSchema),
  });

  const handleImageChange = useCallback(
    (imageData) => {
      setValue('image', imageData);
    },
    [setValue]
  );

  const beforeSubmit = useCallback(
    (data: CourseForm) => {
      let { price } = data;
      if (typeof data.price === 'string') {
        price = Number(data.price?.replace(',', '.'));
      }
      onSubmit({ ...data, price });
      // onSubmit(data);
    },
    [onSubmit]
  );

  return (
    <form id="course-modal-form" onSubmit={handleSubmit(beforeSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={14}>
          <RhfTextField
            name="name"
            variant="outlined"
            control={control}
            error={errors.name}
            fullWidth
            label={translate('course_modal_banner_name')}
            placeholder={translate('course_modal_banner_name')}
          />
        </Grid>

        <Grid item xs={14} md={7}>
          <RhfFilterAutocomplete
            name="currency"
            control={control}
            error={errors.currency}
            labelId="course_modal_banner_currency"
            optionsLabelsIds={currencies}
            defaultValue={currencies[0]}
          />
        </Grid>

        <Grid item xs={14} md={7}>
          <RhfTextField
            name="price"
            variant="outlined"
            control={control}
            type="number"
            error={errors.price}
            fullWidth
            label={translate('course_modal_banner_price')}
            placeholder={translate('course_modal_banner_price')}
          />
        </Grid>

        <Grid item xs={14}>
          <DropzoneLabel>Cover</DropzoneLabel>

          <ImageDropzone
            onImageChange={handleImageChange}
            defaultImageSrc={initialValues.image?.url}
            sx={{ height: '106px' }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ConfigurationCourseModalForm;
