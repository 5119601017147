import { Button, Grid } from '@mui/material';

import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { profileKeywords } from '~/helpers/pages/profile';
import useTypedSelector from '~/hooks/useTypedSelector';

import { UsersActions } from '~/store/ducks/users';

import CircularLoading from '~/components/CircularLoading';
import SearchInput from '~/components/Inputs/SearchInput';

import TeamTable from './components/TeamTable';
import { TeamTableContainer } from './styles';

const TeamPanel: React.FC = () => {
  const { loading, options } = useTypedSelector((state) => state.users);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateMember = useCallback(() => {
    navigate(`/users/profile/${profileKeywords.newUser}`);
  }, [navigate]);

  const handleSearch = useCallback(
    (value: string) => {
      dispatch(UsersActions.updateUsersOptions({ search: value }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(UsersActions.loadUsersRequest());
  }, [dispatch]);

  return (
    <>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={14} sm={8}>
          <SearchInput value={options.search} onChange={handleSearch} />
        </Grid>

        <Grid item xs={14} sm={4}>
          <Button variant="contained" fullWidth onClick={handleCreateMember}>
            <FormattedMessage id="users_team_new_member" />
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        <CircularLoading />
      ) : (
        <TeamTableContainer>
          <TeamTable />
        </TeamTableContainer>
      )}
    </>
  );
};

export default TeamPanel;
