import { ChevronRight } from '@mui/icons-material';
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';

import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';

import useTypedSelector from '~/hooks/useTypedSelector';

import { UsersActions } from '~/store/ducks/users';

import CircularLoading from '~/components/CircularLoading';
import FilterAutocomplete from '~/components/Inputs/FilterAutocomplete';
import SearchInput from '~/components/Inputs/SearchInput';
import { PageContainer } from '~/components/PageContainer';

import PaymentsTable from './components/PaymentsTable';
import { PaymentsTableBox, PaymentsTableContainer } from './styles';

const paymentsOptionsIds = [
  {
    labelId: 'payments_filter_all',
    value: 'filter_all',
  },
];

const Payments: React.FC = () => {
  const dispatch = useDispatch();
  const { loading } = useTypedSelector((state) => state.users.clients);

  const handleSearch = useCallback(
    (value: string) => {
      dispatch(UsersActions.updateClientsOptions({ search: value }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(UsersActions.loadClientsRequest());
    dispatch(UsersActions.loadPurchasesRequest());
  }, [dispatch]);

  return (
    <PageContainer>
      <Breadcrumbs
        aria-label="navigation-breadcrumb"
        separator={<ChevronRight />}
      >
        <Link
          component={ReactRouterLink}
          underline="hover"
          color="inherit"
          to="/"
        >
          <FormattedMessage id="platform" />
        </Link>

        <Link
          component={ReactRouterLink}
          underline="hover"
          color="text.primary"
          to="/payments"
          aria-current="page"
        >
          <Typography variant="body1">
            <FormattedMessage id="payments_breadcrumb" />
          </Typography>
        </Link>
      </Breadcrumbs>

      <PaymentsTableBox>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={14} sm={8}>
            <SearchInput onChange={handleSearch} />
          </Grid>

          <Grid item xs={14} sm={4}>
            <FilterAutocomplete
              labelId="payments_filter_label"
              optionsLabelsIds={paymentsOptionsIds}
            />
          </Grid>
        </Grid>
      </PaymentsTableBox>
      {loading ? (
        <CircularLoading />
      ) : (
        <PaymentsTableContainer>
          <PaymentsTable />
        </PaymentsTableContainer>
      )}
    </PageContainer>
  );
};

export default Payments;
