import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getSortOptionOnHandle } from '~/helpers/table/pagination';
import useTranslate from '~/hooks/useTranslate';
import useTypedSelector from '~/hooks/useTypedSelector';

import { CouponsActions } from '~/store/ducks/coupon';
import { Coupon } from '~/store/types/coupons';

import {
  LimitChangeProps,
  TableHeadOnSortItem,
  TableHeadSort,
  TableHeadSortItem,
  TablePagination,
} from '~/components/TableComponents';

const tableHeadItems: TableHeadSortItem[] = [
  { id: 'code', labelId: 'coupons_code' },
  { id: 'createdAt', labelId: 'coupons_created_at' },
  { id: 'discount', labelId: 'coupons_discount' },
  { id: 'expireAt', labelId: 'coupons_expire_at' },
  { id: 'uses', labelId: 'coupons_times_used' },
  { id: 'usesLimit', labelId: 'coupons_uses_limit' },
  { id: '', labelId: '' },
];

const CouponsTable: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { coupons, options } = useTypedSelector((state) => state.coupons);

  const callback = () => {
    dispatch(CouponsActions.loadCouponsRequest());

    navigate('/coupons');
  };

  const handlePaginationPageChange = useCallback(
    (newPage: number) => {
      const newOptions = {
        page: newPage,
      };

      dispatch(CouponsActions.updateCouponsOptions(newOptions));
    },
    [dispatch]
  );
  const handleDeleteCoupon = useCallback(
    (coupon: Coupon) => {
      dispatch(CouponsActions.deleteCouponRequest(coupon.id, callback));
    },
    [dispatch]
  );

  const handlePaginationLimitChange = useCallback(
    (props: LimitChangeProps) => {
      dispatch(CouponsActions.updateCouponsOptions(props));
    },
    [dispatch, options]
  );
  const handleTableSort = useCallback(
    (item: TableHeadOnSortItem) => {
      const newOptions = getSortOptionOnHandle(options, item.id);
      dispatch(CouponsActions.updateCouponsOptions(newOptions));
    },
    [dispatch, options]
  );

  const translate = useTranslate();

  return (
    <>
      <Table aria-label="team table">
        <TableHeadSort
          items={tableHeadItems}
          sort={options.sort}
          direction={options.direction}
          onSort={handleTableSort}
        />
        <TableBody>
          {coupons?.map((coupon) => (
            <TableRow key={coupon.id}>
              <TableCell>{coupon.code}</TableCell>
              <TableCell>
                {new Date(coupon.createdAt).toLocaleDateString()}
              </TableCell>
              <TableCell>{coupon.formatedDiscount}</TableCell>
              <TableCell align="justify">
                {`${new Date(coupon.expireAt).toLocaleDateString()}`}
              </TableCell>
              <TableCell>{coupon.uses}</TableCell>
              <TableCell>{coupon.usesLimit}</TableCell>
              <TableCell align="right">
                <Tooltip title={translate('delete')}>
                  <IconButton onClick={() => handleDeleteCoupon(coupon)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        options={options}
        onPageChange={handlePaginationPageChange}
        onLimitChange={handlePaginationLimitChange}
      />
    </>
  );
};

export default CouponsTable;
