import { createActions, createReducer } from 'reduxsauce';

import {
  IUserCreators,
  IUserTypes,
  LoadUserSuccessAction,
  UserState,
} from '../types/user';

export const { Types: UserTypes, Creators: UserActions } = createActions<
  IUserTypes,
  IUserCreators
>({
  loadUserRequest: ['userId'],
  loadUserSuccess: ['user'],
  loadUserFailure: [],

  createUserRequest: ['data', 'callback'],
  createUserSuccess: [],
  createUserFailure: [],

  updateUserRequest: ['userId', 'data', 'callback'],
  updateUserSuccess: [],
  updateUserFailure: [],

  deleteUserRequest: ['userId', 'callback'],
  deleteUserSuccess: [],
  deleteUserFailure: [],
});

const INITIAL_STATE: UserState = {
  user: null,
  loading: false,
  loadingCreation: false,
  loadingUpdate: false,
  loadingDeletion: false,
};

// Load
const loadUserRequest = (state = INITIAL_STATE): UserState => ({
  ...state,
  loading: true,
});

const loadUserSuccess = (
  state = INITIAL_STATE,
  action: LoadUserSuccessAction
): UserState => ({
  ...state,
  loading: false,
  user: action.user,
});

const loadUserFailure = (state = INITIAL_STATE): UserState => ({
  ...state,
  loading: false,
});

// Creation
const createUserRequest = (state = INITIAL_STATE): UserState => ({
  ...state,
  loadingCreation: true,
});

const createUserSuccess = (state = INITIAL_STATE): UserState => ({
  ...state,
  user: null,
  loadingCreation: false,
});

const createUserFailure = (state = INITIAL_STATE): UserState => ({
  ...state,
  loadingCreation: false,
});

// Update
const updateUserRequest = (state = INITIAL_STATE): UserState => ({
  ...state,
  loadingUpdate: true,
});

const updateUserSuccess = (state = INITIAL_STATE): UserState => ({
  ...state,
  user: null,
  loadingUpdate: false,
});

const updateUserFailure = (state = INITIAL_STATE): UserState => ({
  ...state,
  loadingUpdate: false,
});

// Deletion
const deleteUserRequest = (state = INITIAL_STATE): UserState => ({
  ...state,
  loadingDeletion: true,
});

const deleteUserSuccess = (state = INITIAL_STATE): UserState => ({
  ...state,
  user: null,
  loadingDeletion: false,
});

const deleteUserFailure = (state = INITIAL_STATE): UserState => ({
  ...state,
  loadingDeletion: false,
});

export default createReducer(INITIAL_STATE, {
  // Load
  [UserTypes.LOAD_USER_REQUEST]: loadUserRequest,
  [UserTypes.LOAD_USER_SUCCESS]: loadUserSuccess,
  [UserTypes.LOAD_USER_FAILURE]: loadUserFailure,

  // Creation
  [UserTypes.CREATE_USER_REQUEST]: createUserRequest,
  [UserTypes.CREATE_USER_SUCCESS]: createUserSuccess,
  [UserTypes.CREATE_USER_FAILURE]: createUserFailure,

  // Update
  [UserTypes.UPDATE_USER_REQUEST]: updateUserRequest,
  [UserTypes.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [UserTypes.UPDATE_USER_FAILURE]: updateUserFailure,

  // Deletion
  [UserTypes.DELETE_USER_REQUEST]: deleteUserRequest,
  [UserTypes.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [UserTypes.DELETE_USER_FAILURE]: deleteUserFailure,
});
