import { createTheme } from '@mui/material';

import components from './components';
import palette from './palette';
import typography from './typography';

const initialTheme = createTheme({
  palette,
});

const theme = createTheme(
  {
    components: components(initialTheme),
    typography: typography(initialTheme),
  },
  initialTheme
);

export default theme;
