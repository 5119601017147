import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ModalBox } from '../styles';

export const WorkoutModalBox = styled(ModalBox)(() => ({
  width: 'calc(100% - 48px)',

  maxWidth: '970px',
  padding: '24px',
}));

export const TextBox = styled(Box)(({ theme }) => ({
  width: '100%',

  paddingBlock: theme.spacing(1),
  paddingInline: theme.spacing(1.25),
  borderRadius: '8px',

  color: theme.palette.grey[700],
  background: theme.palette.grey[50],

  textAlign: 'start',

  '&:first-of-type': {
    marginTop: 'auto',
  },
}));

export const VideoErrorStyled = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '724px',
  minHeight: '225px',
  paddingInline: '20px',
  paddingBlock: '30px',

  display: 'flex',
  gap: '5px',
  justifyContent: 'center',
  alignItems: 'center',

  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.secondary.light,

  '@media(max-width: 500px)': {
    flexWrap: 'wrap',
  },
}));
