import { Check as CheckIcon } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DisabledTypography, PermissionsBox } from './styles';

const Permissions: React.FC = () => {
  return (
    <PermissionsBox>
      <Typography variant="subtitle1">
        <FormattedMessage id="users_profile_section_permissions" />
      </Typography>

      <Stack direction="row" mt={2} spacing={10}>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2">Users</Typography>

          <Stack direction="row" spacing={1}>
            <CheckIcon fontSize="small" color="disabled" />
            <DisabledTypography variant="body1">Edit Admins</DisabledTypography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <CheckIcon fontSize="small" color="disabled" />
            <DisabledTypography variant="body1">
              Edit Clients
            </DisabledTypography>
          </Stack>
        </Stack>

        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2">Courses</Typography>

          <Stack direction="row" spacing={1}>
            <CheckIcon fontSize="small" color="disabled" />
            <DisabledTypography variant="body1">
              Edit Courses
            </DisabledTypography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <CheckIcon fontSize="small" color="disabled" />
            <DisabledTypography variant="body1">
              Edit Workouts
            </DisabledTypography>
          </Stack>
        </Stack>
      </Stack>
    </PermissionsBox>
  );
};

export default Permissions;
