import { Edit as EditIcon } from '@mui/icons-material';
import {
  Avatar,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getSortOptionOnHandle } from '~/helpers/table/pagination';
import { getUserRoleIntl } from '~/helpers/user';
import useTranslate from '~/hooks/useTranslate';
import useTypedSelector from '~/hooks/useTypedSelector';

import { UsersActions } from '~/store/ducks/users';
import { User } from '~/store/types/session';

import {
  LimitChangeProps,
  TableHeadSort,
  TableHeadSortItem,
  TablePagination,
  TableHeadOnSortItem,
} from '~/components/TableComponents';

const tableHeadItems: TableHeadSortItem[] = [
  { id: 'avatar' },
  { id: 'name', labelId: 'users_team_name' },
  { id: 'email', labelId: 'users_team_email' },
  { id: 'role', labelId: 'users_team_role' },
  { id: 'actions' },
];

const TeamTable: React.FC = () => {
  const { users, options } = useTypedSelector((state) => state.users);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslate();

  const handlePaginationPageChange = useCallback(
    (newPage: number) => {
      const newOptions = {
        page: newPage,
      };

      dispatch(UsersActions.updateUsersOptions(newOptions));
    },
    [dispatch]
  );

  const handlePaginationLimitChange = useCallback(
    (props: LimitChangeProps) => {
      dispatch(UsersActions.updateUsersOptions(props));
    },
    [dispatch]
  );

  const handleTableSort = useCallback(
    (item: TableHeadOnSortItem) => {
      const newOptions = getSortOptionOnHandle(options, item.id);
      dispatch(UsersActions.updateUsersOptions(newOptions));
    },
    [dispatch, options]
  );

  const handleEdit = useCallback(
    (user: User) => {
      navigate(`/users/profile/${user.id}`);
    },
    [navigate]
  );

  return (
    <>
      <Table aria-label="team table">
        <TableHeadSort
          items={tableHeadItems}
          sort={options.sort}
          direction={options.direction}
          onSort={handleTableSort}
        />

        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>
                <Avatar placeholder={user.name} sx={{ background: grey[400] }}>
                  {user.name.substring(0, 2).toUpperCase()}
                </Avatar>
              </TableCell>

              <TableCell>{user.name}</TableCell>

              <TableCell>{user.email}</TableCell>

              <TableCell>
                <Chip
                  label={translate(getUserRoleIntl(user.role))}
                  color="info"
                />
              </TableCell>

              <TableCell align="right">
                <Tooltip title={translate('edit')}>
                  <IconButton onClick={() => handleEdit(user)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        options={options}
        onPageChange={handlePaginationPageChange}
        onLimitChange={handlePaginationLimitChange}
      />
    </>
  );
};

export default TeamTable;
