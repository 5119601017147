import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { profileKeywords } from '~/helpers/pages/profile';

import { FormFooter } from '~/components/FormComponents';
import { FormFooterProps } from '~/components/FormComponents/FormFooter';

type ProfileFormFooterProps = FormFooterProps & {
  onDeleteAccount: () => void;
};

const ProfileFormFooter: React.FC<ProfileFormFooterProps> = ({
  onDeleteAccount,
  ...rest
}) => {
  const { userId } = useParams();
  const isToCreateUser = userId === profileKeywords.newUser;

  const footerSaveMessageId = useMemo(() => {
    return isToCreateUser ? 'create_account' : 'save';
  }, [userId]);

  return (
    <FormFooter
      {...rest}
      saveMessageId={footerSaveMessageId}
      onDelete={isToCreateUser ? undefined : onDeleteAccount}
    />
  );
};

export default ProfileFormFooter;
