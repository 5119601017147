import {
  styled,
  Box,
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
} from '@mui/material';

import RhfTextField from '~/components/RhfComponents/RhfTextField';

export const TrainingReminderBox = styled(Grid)(() => ({
  display: 'flex',
}));

export const ClockBox = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '8px',
  marginRight: '8px',
  padding: '16px 24px 20px 24px',
}));

export const Title = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '32px',
  letterSpacing: '1px',
  textAlign: 'left',
  color: theme.palette.primary.contrastText,
}));

export const Clock = styled(Box)(({ theme }) => ({
  display: 'flex;',
  flexDirection: 'row',
  fontSize: '60px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '72px',
  letterSpacing: '-0.5',
  textAlign: 'left',
  padding: 0,
  color: theme.palette.primary.contrastText,

  input: {
    fontSize: '60px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '72px',
    letterSpacing: '-0.5',
    textAlign: 'left',
    padding: 0,
    color: theme.palette.primary.contrastText,

    '~ fieldset': {
      border: 'none',
    },

    ':disabled': {
      textFillColor: '#fff',
    },
  },
}));

export const DayState = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textAlign: 'left',
  justifyContent: 'flex-end',
}));

// ====================================================

export const MessageBox = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: '8px',
}));

export const Message = styled(Box)(({ theme }) => ({
  padding: '26px 32px',
  color: theme.palette.grey[500],
}));

export const InputHours = styled(RhfTextField)(() => ({
  width: '72px',
  height: '67px',
}));

export const InputMinutes = styled(RhfTextField)(() => ({
  width: '72px',
  height: '67px',
}));

export const RadioGroupStyles = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const RadioLabel = styled(FormControlLabel)(() => ({
  margin: 0,
}));
