import { Grid } from '@mui/material';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getPageDataBasedOnPath } from '~/helpers/pages';
import useTranslate from '~/hooks/useTranslate';

import Sidebar from './components/Sidebar';
import TopBar from './components/TopBar';
import { DashboardContainer, PageContent } from './styles';

const AuthContainer: React.FC = ({ children }) => {
  const location = useLocation();
  const translate = useTranslate();

  useEffect(() => {
    const pageData = getPageDataBasedOnPath(location.pathname);
    const titleId = `${pageData?.name}_title`;

    document.title = translate(titleId);
  }, [location, translate]);

  return (
    <DashboardContainer>
      <Grid sx={{ minHeight: '100vh' }} container>
        <Grid item xs={3} md={3} lg={2}>
          <Sidebar />
        </Grid>

        <Grid
          item
          xs={11}
          sm={14}
          md={14}
          lg={11}
          ml={5}
          sx={{ margin: '0 auto', maxWidth: '100%', flexBasis: '100%' }}
        >
          <TopBar />
          <PageContent>{children}</PageContent>
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default AuthContainer;
