import { Grid } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { levelFormValidationSchema } from '~/helpers/yup';
import useTranslate from '~/hooks/useTranslate';

import { LevelForm } from '~/store/types/level';

import RhfTextField from '~/components/RhfComponents/RhfTextField';

interface Props {
  initialValues?: LevelForm;
  onSubmit: (data: LevelForm) => void;
}

const LevelModalForm = ({ initialValues, onSubmit }: Props) => {
  const translate = useTranslate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LevelForm>({
    defaultValues:
      initialValues ||
      levelFormValidationSchema.cast({
        name: '',
        rounds: 0,
      }),
    resolver: yupResolver(levelFormValidationSchema),
  });

  const beforeSubmit = useCallback(
    (data: LevelForm) => {
      onSubmit(data);
    },
    [onSubmit]
  );

  return (
    <form id="level-modal-form" onSubmit={handleSubmit(beforeSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={14}>
          <RhfTextField
            name="name"
            variant="outlined"
            control={control}
            error={errors.name}
            fullWidth
            label={translate('course_modal_level_name')}
            placeholder={translate('course_modal_level_name')}
          />
        </Grid>

        <Grid item xs={14}>
          <RhfTextField
            type="number"
            variant="outlined"
            inputProps={{ min: 0 }}
            name="rounds"
            control={control}
            error={errors.rounds}
            fullWidth
            label={translate('course_modal_level_rounds')}
            placeholder={translate('course_modal_level_rounds')}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default LevelModalForm;
