import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  getErrorIdFromResponse,
  getPaginationOptionsFromResponse,
  getPaginationQueryOptions,
} from '../../helpers/api';
import { formatOutputCouponCreation } from '../../helpers/coupons';
import api from '../../services/api';
import { ApiError, ApiPaginationResponse } from '../../types/request';
import { CouponsActions, CouponsTypes } from '../ducks/coupon';
import { FlashMessageActions } from '../ducks/flashMessage';
import {
  CreateCouponsRequestAction,
  DeleteCouponRequestAction,
  Coupon,
} from '../types/coupons';
import { Pagination, PaginationResponse } from '../types/pagination';

type LoadCouponsResponse = ApiPaginationResponse<Coupon[], PaginationResponse>;

function* loadCouponsRequest() {
  const pagination: Pagination = yield select((state) => state.coupons.options);

  try {
    const paginationOptions = getPaginationQueryOptions(pagination);

    const response: LoadCouponsResponse = yield call(api.get, '/coupon', {
      params: paginationOptions,
    });

    const { data, ...rest } = response.data;
    const options = getPaginationOptionsFromResponse(rest);

    yield put(CouponsActions.loadCouponsSuccess(data, options));
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response);

    yield put(CouponsActions.loadCouponsFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}
function* updateCouponsOptions() {
  yield put(CouponsActions.loadCouponsRequest());
}

function* createCouponsRequest({ data, callback }: CreateCouponsRequestAction) {
  try {
    yield call(api.post, '/coupon', formatOutputCouponCreation(data));

    yield put(CouponsActions.createCouponsSuccess());
    yield put(
      FlashMessageActions.showMessage({
        id: 'coupon_creation_success',
        variant: 'success',
      })
    );

    callback();
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'coupon_creation_failure');

    yield put(CouponsActions.createCouponsFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* deleteCouponRequest({
  couponId,
  callback,
}: DeleteCouponRequestAction) {
  try {
    yield call(api.delete, `/coupon/${couponId}`);
    yield put(CouponsActions.deleteCouponSuccess());
    yield put(
      FlashMessageActions.showMessage({
        id: 'coupon_deletion_success',
        variant: 'success',
      })
    );

    if (callback) callback();
  } catch (error) {
    const { response } = error as ApiError;

    const errorId = getErrorIdFromResponse(response, 'coupon_deletion_failure');

    yield put(CouponsActions.deleteCouponFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

export default function* sagas() {
  yield takeLatest(CouponsTypes.LOAD_COUPONS_REQUEST, loadCouponsRequest);
  yield takeLatest(CouponsTypes.CREATE_COUPONS_REQUEST, createCouponsRequest);
  yield takeLatest(CouponsTypes.DELETE_COUPON_REQUEST, deleteCouponRequest);
  yield takeLatest(CouponsTypes.UPDATE_COUPONS_OPTIONS, updateCouponsOptions);
}
