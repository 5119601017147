import { ChevronRight } from '@mui/icons-material';
import { Breadcrumbs, Typography, Link } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';

import { PageContainer } from '~/components/PageContainer';

import CouponsPanel from './components/CouponsPanel/components';

const Coupons: React.FC = () => {
  return (
    <PageContainer>
      <Breadcrumbs
        aria-label="navigation-breadcrumb"
        separator={<ChevronRight />}
      >
        <Link
          component={ReactRouterLink}
          underline="hover"
          color="inherit"
          to="/"
        >
          <FormattedMessage id="platform" />
        </Link>

        <Link
          component={ReactRouterLink}
          underline="hover"
          color="text.primary"
          to="/coupons"
          aria-current="page"
        >
          <Typography variant="body1">
            <FormattedMessage id="coupons_breadcrumbs" />
          </Typography>
        </Link>
      </Breadcrumbs>
      <CouponsPanel />
    </PageContainer>
  );
};
export default Coupons;
