import { Edit as EditIcon } from '@mui/icons-material';
import { AvatarProps } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  AvatarBox,
  AvatarEditButton,
  AvatarStyled,
  InputStyled,
} from './styles';

interface ImageChangeProps {
  imageFile: File;
  src: string;
}

interface Props extends AvatarProps {
  defaultImageSrc?: string;
  onImageChange: (data: ImageChangeProps) => void;
}

const ProfileAvatar: React.FC<Props> = ({ defaultImageSrc, onImageChange }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const [selectedImage, setSelectedImage] = useState('');

  const handleImageChange = useCallback(
    (imageData: ImageChangeProps) => {
      setSelectedImage(imageData.src);
      onImageChange(imageData);
    },
    [setSelectedImage, onImageChange]
  );

  useEffect(() => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);

    reader.onload = (event) => {
      const src = event.target?.result?.toString() || '';

      const imageFile = acceptedFiles[0];

      handleImageChange({
        imageFile,
        src,
      });
    };
  }, [acceptedFiles, handleImageChange]);

  useEffect(() => {
    if (!defaultImageSrc) {
      return;
    }

    setSelectedImage(defaultImageSrc);
  }, [defaultImageSrc]);

  return (
    <AvatarBox {...getRootProps()}>
      <InputStyled {...getInputProps()} accept="image/*" multiple={false} />

      <AvatarStyled src={selectedImage} />

      <AvatarEditButton color="primary" variant="contained">
        <EditIcon fontSize="small" />
      </AvatarEditButton>
    </AvatarBox>
  );
};

export default ProfileAvatar;
