import React from 'react';

import { ReactComponent as FullLogo } from '~/assets/images/logo.svg';

import { StartWrapper } from './styles';

interface Props {
  children: React.ReactNode;
}

const SignInContainer: React.FC<Props> = ({ children }) => {
  return (
    <StartWrapper>
      <FullLogo style={{ marginBottom: '160px', marginTop: '50px' }} />
      {children}
    </StartWrapper>
  );
};

export default SignInContainer;
