import { Box, LinearProgress, styled } from '@mui/material';

export const ProgressBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Progress = styled(LinearProgress)(({ theme }) => ({
  height: '15px',
  borderRadius: '20px',

  width: '100%',
  marginRight: theme.spacing(1),
}));
