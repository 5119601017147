import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MuiSwitch = {
  defaultProps?: ComponentsProps['MuiSwitch'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSwitch'];
  variants?: ComponentsVariants['MuiSwitch'];
};

const styles = ({ palette }: Theme): MuiSwitch => ({
  styleOverrides: {
    track: {
      backgroundColor: palette.grey[400],
    },
  },
});

export default styles;
