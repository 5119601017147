import { styled, Box, Typography } from '@mui/material';

export const BoxStyles = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  width: '544px',

  boxShadow: '24',
  padding: '24px 36px 36px 36px',
  borderRadius: '4px',

  '@media(max-width: 600px)': {
    width: '95%',
  },
}));

export const ModalTitle = styled(Typography)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CloseButton = styled(Box)(() => ({
  position: 'absolute',
  top: '24px',
  right: '24px',
}));

export const ActionButtonGroup = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '56px',

  button: {
    width: '154px',
  },

  '@media(max-width: 450px)': {
    flexDirection: 'column',
    marginTop: '25px',
    button: {
      width: '100%',
      marginBottom: '15px',
    },
  },
}));
