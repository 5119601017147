import { createActions, createReducer } from 'reduxsauce';

import { defaultTableOptions } from '~/helpers/table/state';

import {
  IUsersCreators,
  IUsersTypes,
  LoadUsersSuccessAction,
  LoadClientsSuccessAction,
  UpdateUsersOptions,
  UpdateClientsOptions,
  UsersState,
  LoadUserRolesSuccessAction,
  LoadPurchasesSuccessAction,
} from '../types/users';

export const { Types: UsersTypes, Creators: UsersActions } = createActions<
  IUsersTypes,
  IUsersCreators
>({
  loadUsersRequest: [],
  loadUsersSuccess: ['users', 'options'],
  loadUsersFailure: [],

  loadUserRolesRequest: [],
  loadUserRolesSuccess: ['roles'],
  loadUserRolesFailure: [],

  updateUsersOptions: ['options'],

  loadClientsRequest: [],
  loadClientsSuccess: ['clients', 'options'],
  loadClientsFailure: [],

  loadPurchasesRequest: [],
  loadPurchasesSuccess: ['purchases'],
  loadPurchasesFailure: [],

  updateClientsOptions: ['options'],
});

const INITIAL_STATE: UsersState = {
  users: [],
  loading: false,
  options: {
    sort: '',
    search: '',
    page: 1,
    total: 0,
    limit: defaultTableOptions.pagination.limit,
    direction: defaultTableOptions.sort.direction,
  },
  roles: [],
  purchases: [],
  clients: {
    clients: [],
    loading: false,
    options: {
      sort: '',
      search: '',
      page: 1,
      total: 0,
      limit: defaultTableOptions.pagination.limit,
      direction: defaultTableOptions.sort.direction,
    },
  },
};

// Users

const loadUsersRequest = (state = INITIAL_STATE): UsersState => ({
  ...state,
  loading: true,
});

const loadUsersSuccess = (
  state = INITIAL_STATE,
  action: LoadUsersSuccessAction
): UsersState => ({
  ...state,
  loading: false,
  users: action.users,
  options: {
    ...state.options,
    ...action.options,
  },
});

const loadUsersFailure = (state = INITIAL_STATE): UsersState => ({
  ...state,
  loading: false,
});

// Purchases

const loadPurchasesRequest = (state = INITIAL_STATE): UsersState => ({
  ...state,
});

const loadPurchasesSuccess = (
  state = INITIAL_STATE,
  action: LoadPurchasesSuccessAction
): UsersState => ({
  ...state,
  purchases: action.purchases,
});

const loadPurchasesFailure = (state = INITIAL_STATE): UsersState => ({
  ...state,
});

// Roles

const loadUserRolesRequest = (state = INITIAL_STATE): UsersState => ({
  ...state,
});

const loadUserRolesSuccess = (
  state = INITIAL_STATE,
  action: LoadUserRolesSuccessAction
): UsersState => ({
  ...state,
  roles: action.roles,
});

const loadUserRolesFailure = (state = INITIAL_STATE): UsersState => ({
  ...state,
});

// Options

const updateUsersOptions = (
  state = INITIAL_STATE,
  action: UpdateUsersOptions
): UsersState => ({
  ...state,
  options: {
    ...state.options,
    ...action.options,
  },
});

// Clients

const loadClientsRequest = (state = INITIAL_STATE): UsersState => ({
  ...state,
  clients: {
    ...state.clients,
    loading: true,
  },
});

const loadClientsSuccess = (
  state = INITIAL_STATE,
  action: LoadClientsSuccessAction
): UsersState => ({
  ...state,
  clients: {
    ...state.clients,
    loading: false,
    clients: action.clients,
    options: {
      ...state.clients.options,
      ...action.options,
    },
  },
});

const loadClientsFailure = (state = INITIAL_STATE): UsersState => ({
  ...state,
  clients: {
    ...state.clients,
    loading: false,
  },
});

const updateClientsOptions = (
  state = INITIAL_STATE,
  action: UpdateClientsOptions
): UsersState => ({
  ...state,
  clients: {
    ...state.clients,
    options: {
      ...state.clients.options,
      ...action.options,
    },
  },
});

export default createReducer(INITIAL_STATE, {
  // Users
  [UsersTypes.LOAD_USERS_REQUEST]: loadUsersRequest,
  [UsersTypes.LOAD_USERS_SUCCESS]: loadUsersSuccess,
  [UsersTypes.LOAD_USERS_FAILURE]: loadUsersFailure,

  // Purchases

  [UsersTypes.LOAD_PURCHASES_REQUEST]: loadPurchasesRequest,
  [UsersTypes.LOAD_PURCHASES_SUCCESS]: loadPurchasesSuccess,
  [UsersTypes.LOAD_PURCHASES_FAILURE]: loadPurchasesFailure,

  // Roles

  [UsersTypes.LOAD_USER_ROLES_REQUEST]: loadUserRolesRequest,
  [UsersTypes.LOAD_USER_ROLES_SUCCESS]: loadUserRolesSuccess,
  [UsersTypes.LOAD_USER_ROLES_FAILURE]: loadUserRolesFailure,

  // Options

  [UsersTypes.UPDATE_USERS_OPTIONS]: updateUsersOptions,

  // Users Clients

  [UsersTypes.LOAD_CLIENTS_REQUEST]: loadClientsRequest,
  [UsersTypes.LOAD_CLIENTS_SUCCESS]: loadClientsSuccess,
  [UsersTypes.LOAD_CLIENTS_FAILURE]: loadClientsFailure,

  [UsersTypes.UPDATE_CLIENTS_OPTIONS]: updateClientsOptions,
});
