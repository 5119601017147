import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Logout,
  Person,
} from '@mui/icons-material';
import { Avatar, IconButton, Menu } from '@mui/material';

import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import userAvatar from '~/assets/images/user-avatar.jpg';

import { profileKeywords } from '~/helpers/pages/profile';
import useTypedSelector from '~/hooks/useTypedSelector';

import { SessionActions } from '~/store/ducks/session';

import { MenuBox, MenuItemStyled } from './styles';

const TopBarMenu: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useTypedSelector((state) => state.session);
  const userImageProfile = user?.image ? user.image.url : userAvatar;

  const [anchorElUser, setAnchorElUser] = useState<HTMLButtonElement | null>(
    null
  );

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const handleOpenProfile = useCallback(() => {
    navigate(`/users/profile/${profileKeywords.me}`);
    handleCloseMenu();
  }, [handleCloseMenu]);

  const handleLogout = useCallback(() => {
    dispatch(
      SessionActions.logout(() => {
        handleCloseMenu();
      })
    );
  }, [dispatch, handleCloseMenu]);

  const open = useMemo(() => Boolean(anchorElUser), [anchorElUser]);

  return (
    <MenuBox>
      <IconButton onClick={handleOpenMenu} disableRipple sx={{ p: 0 }}>
        <Avatar alt="User" src={userImageProfile} />

        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </IconButton>

      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        keepMounted
        anchorEl={anchorElUser}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItemStyled onClick={handleOpenProfile}>
          <Person fontSize="small" />
          <FormattedMessage id="topbar_menu_profile" />
        </MenuItemStyled>

        <MenuItemStyled onClick={handleLogout}>
          <Logout fontSize="small" />
          <FormattedMessage id="topbar_menu_logout" />
        </MenuItemStyled>
      </Menu>
    </MenuBox>
  );
};

export default TopBarMenu;
