import { Box, Container, styled } from '@mui/material';

export const MomProgressContainer = styled(Container)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

export const LastOnlineBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  width: 'fit-content',
  padding: '22px 42px',

  borderRadius: '8px',
  background: theme.palette.grey[50],
}));

export const OnlineCircle = styled(Box)(({ theme }) => ({
  width: '11px',
  height: '11px',

  borderRadius: '50%',
  background: theme.palette.success.light,
}));
