import { CloseSharp as CloseSharpIcon } from '@mui/icons-material/';
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
  Box,
} from '@mui/material';

import { xorBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { workoutsKeywords } from '~/helpers/pages/workouts';
import { defaultListOptions } from '~/helpers/table/state';
import { workoutsLabelsOptions } from '~/helpers/workout/labels';
import useTypedSelector from '~/hooks/useTypedSelector';

import { CourseActions } from '~/store/ducks/course';
import { WorkoutsActions } from '~/store/ducks/workouts';
import { Level, WorkoutLevelType } from '~/store/types/level';
import { WorkoutLevel } from '~/store/types/workouts';

import LoadingButton from '~/components/Buttons/LoadingButton';
import { SeeMoreButton } from '~/components/Buttons/SeeMoreButton/styles';
import CircularLoading from '~/components/CircularLoading';
import ClassCard from '~/components/ClassCard';
import FilterAutocomplete, {
  FilterAutocompleteOption,
  OptionLabel,
} from '~/components/Inputs/FilterAutocomplete';
import SearchInput from '~/components/Inputs/SearchInput';

import { CloseButton, BoxStyles, WorkoutsSide, SearchSide } from './styles';

interface Workout {
  id: string;
  title: string;
  description: string;
  labels: string[];
}

interface Props {
  openModal: boolean;
  type: WorkoutLevelType;
  onClose: () => void;
  level: Level;
  lastOrder?: number;
}

const NewWorkoutModal: React.FC<Props> = ({
  openModal,
  onClose,
  level,
  type,
  lastOrder = 0,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { workouts, options, loading, loadingAddWorkoutLevel } =
    useTypedSelector((state) => state.workouts);

  const [selectedWorkouts, setSelectedWorkouts] = useState<Workout[]>([]);

  useEffect(() => {
    return () => {
      setSelectedWorkouts([]);
    };
  }, [openModal]);

  const filteredWorkouts = useMemo(() => {
    return workouts.filter((workout) => {
      if (type && level[type] && level[type]?.length > 0) {
        return !level[type]?.find((workoutLevel) => {
          return workoutLevel.id === workout.id;
        });
      }
      return true;
    });
  }, [workouts, type, level]);

  const callback = () => {
    dispatch(CourseActions.getCourseRequest(true));
    onClose();
  };

  const handleSeeMore = useCallback(() => {
    const newOptions = {
      limit: options.limit + defaultListOptions.pagination.limit,
    };

    dispatch(WorkoutsActions.updateWorkoutsOptions(newOptions));
  }, [dispatch]);

  const seeMoreDisabled = useMemo(
    () => options.limit >= options.total,
    [options]
  );

  const handleSearch = useCallback(
    (value: string) => {
      dispatch(WorkoutsActions.updateWorkoutsOptions({ search: value }));
    },
    [dispatch]
  );

  const handleEditWorkout = useCallback((workout: Workout) => {
    navigate(`
    /workouts/${workout.id}`);
  }, []);

  const handleSelectWorkout = useCallback(
    (workout: Workout) => {
      setSelectedWorkouts(xorBy(selectedWorkouts, [workout], 'id'));
    },
    [selectedWorkouts]
  );

  const isSelected = useCallback(
    (workout: Workout) => {
      return Boolean(selectedWorkouts.find((item) => item.id === workout.id));
    },
    [selectedWorkouts]
  );

  const handleAddNewWorkout = () => {
    const newWorkoutsLevel: WorkoutLevel[] = selectedWorkouts.map(
      (workoutLevel, index) => {
        return {
          workoutId: workoutLevel.id,
          order: lastOrder + (index + 1),
        };
      }
    );

    dispatch(
      WorkoutsActions.addWorkoutsLevelRequest(
        {
          workouts: newWorkoutsLevel,
          levelId: level.id || '',
        },
        type,
        callback
      )
    );
  };

  const handleCreateWorkout = useCallback(() => {
    navigate(`/workouts/${workoutsKeywords.newWorkout}`);
  }, []);

  const validateFilterLabelAndUpdate = useCallback(
    (_event, option: OptionLabel | OptionLabel[]) => {
      const optionTyped = option as FilterAutocompleteOption;

      const optionChoose = workoutsLabelsOptions.map((item) => {
        if (optionTyped.value === item.value) {
          return item.value;
        }
        return '';
      });

      dispatch(
        WorkoutsActions.updateWorkoutsOptions({
          labels: [optionChoose[optionChoose.indexOf(optionTyped.value)]],
        })
      );
    },
    [options]
  );

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      aria-labelledby="level-modal"
      aria-describedby="level-modal"
    >
      <BoxStyles>
        <Box>
          <Typography mb={4} id="modal-modal-title" variant="h6">
            <FormattedMessage id="workouts_add_workout" />
          </Typography>

          <Grid container>
            <SearchSide spacing={3}>
              <SearchInput onChange={handleSearch} />

              <FilterAutocomplete
                labelId="workout_label"
                optionsLabelsIds={workoutsLabelsOptions}
                onChange={validateFilterLabelAndUpdate}
              />

              {/* <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mandatoryCheck}
                      onChange={handleChangeMandatory}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={translate('workout_mandatory')}
                />
              </FormGroup> */}
            </SearchSide>

            {loading ? (
              <WorkoutsSide container>
                <CircularLoading />
              </WorkoutsSide>
            ) : (
              <WorkoutsSide spacing={2} container>
                {filteredWorkouts.map((workout) => {
                  return (
                    <Grid key={workout.id} item lg={7} md={7} xs={14}>
                      <ClassCard
                        sx={{
                          minWidth: 'initial',
                          marginInline: 'auto',
                          cursor: 'pointer',
                        }}
                        selected={isSelected(workout)}
                        onClick={() => handleSelectWorkout(workout)}
                        thumbnail={workout.image.url}
                        title={workout.title}
                        description={workout.description}
                        labels={workout.labels}
                        onEdit={() => handleEditWorkout(workout)}
                      />
                    </Grid>
                  );
                })}

                {!seeMoreDisabled && (
                  <Grid container spacing={2}>
                    <SeeMoreButton
                      onClick={handleSeeMore}
                      disabled={seeMoreDisabled}
                      disableRipple
                    >
                      <Typography variant="body1">
                        <FormattedMessage id="see_more" />
                      </Typography>
                    </SeeMoreButton>
                  </Grid>
                )}
              </WorkoutsSide>
            )}
          </Grid>

          <Grid container mt={4}>
            <Grid item xs={7}>
              <Button
                onClick={handleCreateWorkout}
                color="secondary"
                variant="outlined"
              >
                <FormattedMessage id="workouts_create_workout" />
              </Button>
            </Grid>

            <Grid item xs={7} pr={3} sx={{ textAlign: 'end' }}>
              <LoadingButton
                color="primary"
                variant="contained"
                disabled={!(selectedWorkouts.length > 0)}
                onClick={handleAddNewWorkout}
                loading={loadingAddWorkoutLevel}
                sx={{ width: '154px' }}
              >
                <FormattedMessage id="workouts_add_workout" />
              </LoadingButton>
            </Grid>
          </Grid>

          <CloseButton>
            <IconButton onClick={onClose}>
              <CloseSharpIcon fontSize="small" />
            </IconButton>
          </CloseButton>
        </Box>
      </BoxStyles>
    </Modal>
  );
};

export default NewWorkoutModal;
