import { Grid, Switch } from '@mui/material';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SwitchStyles, SwitchGroup, SwitchTitle, SwitchLabel } from './styles';

interface Props {
  title: string;
  label: string;
}

const SwitchCustom: React.FC<Props> = ({ title, label }) => {
  return (
    <SwitchStyles container>
      <Grid item xs={14}>
        <SwitchTitle>
          <FormattedMessage id={title} />
        </SwitchTitle>
      </Grid>

      <Grid item xs={14}>
        <SwitchGroup>
          <SwitchLabel>
            <FormattedMessage id={label} />
          </SwitchLabel>
          <Switch disabled defaultChecked color="primary" />
        </SwitchGroup>
      </Grid>
    </SwitchStyles>
  );
};

export default SwitchCustom;
