import { createActions, createReducer } from 'reduxsauce';

import { move } from '~/helpers/util';

import {
  ICourseCreators,
  ICourseTypes,
  GetCourseSuccessAction,
  CourseState,
  GetCourseFailureAction,
  UpdateCourseSuccessAction,
  UpdateCourseFailureAction,
  GetCourseRequestAction,
  MoveCourseLevelWorkoutAction,
} from '../types/course';

export const { Types: CourseTypes, Creators: CourseActions } = createActions<
  ICourseTypes,
  ICourseCreators
>({
  getCourseRequest: ['preventRefresh'],
  getCourseSuccess: ['course'],
  getCourseFailure: ['error'],

  updateCourseRequest: ['data', 'callback'],
  updateCourseSuccess: ['course'],
  updateCourseFailure: ['error'],

  moveCourseLevelWorkout: [
    'levelId',
    'workoutId',
    'workoutLevelType',
    'direction',
  ],
});

const INITIAL_STATE: CourseState = {
  course: {
    id: '',
    name: '',
    createdAt: '',
    updatedAt: '',
    levels: [],
  },
  loading: false,
  loadingUpdate: false,
};

const getCourseRequest = (
  state = INITIAL_STATE,
  { preventRefresh }: GetCourseRequestAction
): CourseState => ({
  ...state,
  loading: !preventRefresh,
});

const getCourseSuccess = (
  state = INITIAL_STATE,
  { course }: GetCourseSuccessAction
): CourseState => ({
  ...state,
  course,
  loading: false,
});

const getCourseFailure = (
  state = INITIAL_STATE,
  { error }: GetCourseFailureAction
): CourseState => ({
  ...state,
  error,
  loading: false,
});

const updateCourseRequest = (state = INITIAL_STATE): CourseState => ({
  ...state,
  loadingUpdate: true,
});

const updateCourseSuccess = (
  state = INITIAL_STATE,
  { course }: UpdateCourseSuccessAction
): CourseState => ({
  ...state,
  course,
  loadingUpdate: false,
});

const updateCourseFailure = (
  state = INITIAL_STATE,
  { error }: UpdateCourseFailureAction
): CourseState => ({
  ...state,
  error,
  loadingUpdate: false,
});

const moveCourseLevelWorkout = (
  state = INITIAL_STATE,
  {
    levelId,
    workoutId,
    workoutLevelType,
    direction,
  }: MoveCourseLevelWorkoutAction
) => {
  return {
    ...state,
    course: {
      ...state.course,
      levels: state.course.levels.map((level) => {
        if (level.id === levelId) {
          const index = level[workoutLevelType].findIndex(
            (workout) => workout.id === workoutId
          );
          const newIndex = direction === 'up' ? index - 1 : index + 1;
          const newData = move(level[workoutLevelType], index, newIndex);

          return {
            ...level,
            [workoutLevelType]: newData,
          };
        }
        return level;
      }),
    },
  };
};

export default createReducer(INITIAL_STATE, {
  [CourseTypes.GET_COURSE_REQUEST]: getCourseRequest,
  [CourseTypes.GET_COURSE_SUCCESS]: getCourseSuccess,
  [CourseTypes.GET_COURSE_FAILURE]: getCourseFailure,

  [CourseTypes.UPDATE_COURSE_REQUEST]: updateCourseRequest,
  [CourseTypes.UPDATE_COURSE_SUCCESS]: updateCourseSuccess,
  [CourseTypes.UPDATE_COURSE_FAILURE]: updateCourseFailure,

  [CourseTypes.MOVE_COURSE_LEVEL_WORKOUT]: moveCourseLevelWorkout,
});
