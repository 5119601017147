import { AxiosResponse } from 'axios';
import { takeLatest, put, call } from 'redux-saga/effects';

import { ApiError } from '~/types/request';

import { getErrorIdFromResponse } from '~/helpers/api';
import api from '~/services/api';

import { FlashMessageActions } from '../ducks/flashMessage';
import { ImageActions, ImageTypes } from '../ducks/image';
import {
  ImageUploadCallbackData,
  CreateUploadImageRequestAction,
} from '../types/image';

type ImageResponse = AxiosResponse<ImageUploadCallbackData>;

function* createUploadImageRequest({
  image,
  callback,
}: CreateUploadImageRequestAction) {
  try {
    const formData = new FormData();
    formData.append('file', image);

    const response: ImageResponse = yield call(api.post, '/images', formData);

    yield put(ImageActions.createUploadImageSuccess());

    callback(response.data);
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(
      response,
      'image_create_upload_failure'
    );

    yield put(ImageActions.createUploadImageFailure(errorId));
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

export default function* sagas() {
  yield takeLatest(
    ImageTypes.CREATE_UPLOAD_IMAGE_REQUEST,
    createUploadImageRequest
  );
}
