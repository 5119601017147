import { ObjectString } from '~/types/object';
import { ApiResponseError } from '~/types/request';

const responseErrors: ObjectString = {
  // Session
  'Incorrect email/password combination': 'signin_error_email_password',

  // Users
  "You can't delete an administrator user":
    'users_deletion_failure_delete_admin',
};

function getErrorMessage(message: string | string[]) {
  if (Array.isArray(message)) {
    return message[0];
  }

  return message;
}

export function getErrorIdFromResponse(
  response: ApiResponseError | undefined,
  defaultError?: string
) {
  const defaultErrorId = defaultError || 'default_error';

  try {
    if (!response?.data) {
      return defaultErrorId;
    }

    const errorMessage = getErrorMessage(response.data.message);

    if (errorMessage in responseErrors) {
      return responseErrors[errorMessage];
    }

    return defaultErrorId;
  } catch (error) {
    return defaultErrorId;
  }
}
