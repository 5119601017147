import '~/assets/styles/index.css';

import { CssBaseline, ThemeProvider } from '@mui/material';

import React from 'react';
import { IntlProvider } from 'react-intl';

import theme from '~/theme';

import AppRouter from '~/router';

import intlLanguages from '~/intl';

import SnackbarMessage from '~/components/SnackbarMessage';

const AppContainer: React.FC = () => {
  const locale = 'en-US';
  const messages = intlLanguages[locale];

  return (
    <IntlProvider locale={locale} messages={messages} defaultLocale={locale}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <SnackbarMessage />
        <AppRouter />
      </ThemeProvider>
    </IntlProvider>
  );
};

export default AppContainer;
