import { CircularProgress, styled } from '@mui/material';

import { ButtonColor, ButtonVariant } from '~/types/mui';

import theme from '~/theme';

interface Props {
  buttonvariant: ButtonVariant;
}

function getColor(color: ButtonColor, buttonVariant: ButtonVariant) {
  if (!color || color === 'inherit') {
    return undefined;
  }

  if (buttonVariant === 'contained') {
    return theme.palette[color].contrastText;
  }

  return theme.palette[color].main;
}

export const LoadingCircle = styled(CircularProgress)<Props>(
  ({ color, buttonvariant: buttonVariant }) => ({
    color: getColor(color, buttonVariant),

    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  })
);
