import { Grid, Switch, Typography, FormControl, Radio } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { trainingReminderValidationSchema } from '~/helpers/yup';

import {
  TrainingReminderBox,
  Clock,
  Message,
  Title,
  MessageBox,
  InputHours,
  InputMinutes,
  ClockBox,
  DayState,
  RadioLabel,
  RadioGroupStyles,
} from './styles';

const TrainingReminder: React.FC = () => {
  const {
    // handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(trainingReminderValidationSchema),
  });

  const [value, setValue] = useState('AM');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <TrainingReminderBox container>
      <Grid item xs={14} sx={{ display: 'flex' }}>
        <ClockBox item xs={9}>
          <Title variant="subtitle1">
            <FormattedMessage id="notifications_trainingreminder_title" />
          </Title>
          <Clock>
            <InputHours
              disabled
              name="hours"
              control={control}
              error={errors.hours}
              placeholder="06"
            />
            :
            <InputMinutes
              disabled
              name="minutes"
              control={control}
              error={errors.minutes}
              placeholder="01"
            />
            <DayState>
              <FormControl>
                <RadioGroupStyles
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <RadioLabel
                    sx={{ mb: 1 }}
                    value="AM"
                    control={<Radio sx={{ display: 'none' }} />}
                    label="AM"
                  />
                  <RadioLabel
                    value="PM"
                    control={<Radio sx={{ display: 'none' }} />}
                    label="PM"
                  />
                </RadioGroupStyles>
              </FormControl>
            </DayState>
          </Clock>
        </ClockBox>
        <MessageBox item xs={5}>
          <Message>
            <Typography variant="body1">
              <FormattedMessage id="notifications_trainingreminder_message" />
            </Typography>
          </Message>
        </MessageBox>
      </Grid>

      <Grid item xs={14} sx={{ my: 3, textAlign: 'end' }}>
        <Switch disabled defaultChecked color="primary" />
      </Grid>
    </TrainingReminderBox>
  );
};

export default TrainingReminder;
