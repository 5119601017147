import yup from './default';

export const userProfileValidationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(6),
  role: yup.string().required(),
});

export const clientProfileValidationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(6),
  address: yup.string(),
  state: yup.string(),
  country: yup.string(),
  postcode: yup.string(),
});

export const trainingReminderValidationSchema = yup.object().shape({
  hours: yup.string().required(),
  minutes: yup.string().required(),
  ampm: yup.boolean().required(),
});
