import { Box } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { profileKeywords } from '~/helpers/pages/profile';
import { userProfileValidationSchema } from '~/helpers/yup';
import useTypedSelector from '~/hooks/useTypedSelector';

import { ImageActions } from '~/store/ducks/image';
import { UserActions } from '~/store/ducks/user';
import { UsersActions } from '~/store/ducks/users';
import { ImageUploadCallbackData } from '~/store/types/image';
import { UserCreation, UserUpdate } from '~/store/types/user';

import CircularLoading from '~/components/CircularLoading';
import { Form } from '~/components/FormComponents';
import DeleteModal, { DeleteModalRef } from '~/components/Modals/DeleteModal';
import { PageContainerBottom } from '~/components/PageContainer';

import GeneralForm from './components/GeneralForm';
import Permissions from './components/Permissions';
import ProfileFormFooter from './components/ProfileFormFooter';
import UserProfileBreadcrumb from './components/UserProfileBreadcrumb';
import { UserFormContainer } from './styles';

const UserProfile: React.FC = () => {
  const { loading, loadingCreation, loadingUpdate, loadingDeletion } =
    useTypedSelector((state) => state.user);

  const { roles } = useTypedSelector((state) => state.users);

  const methods = useForm({
    resolver: yupResolver(userProfileValidationSchema),
  });

  const { userId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const callback = () => {
    navigate('/users');
    dispatch(UsersActions.loadUsersRequest());
  };

  const handleImageUploaded = useCallback(
    (
      imageData: ImageUploadCallbackData,
      data: UserCreation | UserUpdate,
      isToCreateUser: boolean
    ) => {
      const userRole = roles.find((role) => role.name === data.role);

      const newData = {
        name: data.name,
        email: data.email,
        password: data.password,
        imageId: imageData.id,
        roleId: userRole?.id,
      };

      if (isToCreateUser) {
        dispatch(
          UserActions.createUserRequest(newData as UserCreation, callback)
        );
      } else {
        dispatch(
          UserActions.updateUserRequest(
            userId as string,
            newData as UserUpdate,
            callback
          )
        );
      }
    },
    [dispatch, userId, roles]
  );

  const handleSubmit = useCallback(
    (data: UserCreation | UserUpdate) => {
      const isToCreateUser = userId === profileKeywords.newUser;

      const imageFile = data.image?.imageFile as File;

      if (imageFile) {
        dispatch(
          ImageActions.createUploadImageRequest(
            imageFile as File,
            (imageData) => handleImageUploaded(imageData, data, isToCreateUser)
          )
        );
      } else {
        const userRole = roles.find((role) => role.name === data.role);

        const newData = {
          name: data.name,
          email: data.email,
          password: data.password,
          imageId: data.image,
          roleId: userRole?.id,
        };
        if (isToCreateUser) {
          dispatch(
            UserActions.createUserRequest(newData as UserCreation, callback)
          );
        } else {
          dispatch(
            UserActions.updateUserRequest(
              userId as string,
              newData as UserUpdate,
              callback
            )
          );
        }
      }
    },
    [dispatch, navigate, userId, roles]
  );

  const handleDeleteAccount = useCallback(() => {
    dispatch(
      UserActions.deleteUserRequest(userId as string, () => {
        deleteModalRef.current?.handleClose();
        navigate('/users');
      })
    );
  }, [dispatch, userId, navigate]);

  useEffect(() => {
    const shouldLoad = userId && userId !== profileKeywords.newUser;

    if (shouldLoad) {
      dispatch(UserActions.loadUserRequest(userId));
    }

    dispatch(UsersActions.loadUserRolesRequest());
  }, [dispatch, userId]);

  return (
    <>
      <DeleteModal
        ref={deleteModalRef}
        onConfirm={handleDeleteAccount}
        loadingDeletion={loadingDeletion}
      />

      <PageContainerBottom>
        <UserProfileBreadcrumb />

        {loading ? (
          <CircularLoading />
        ) : (
          <UserFormContainer>
            <Form onSubmit={handleSubmit} {...methods}>
              <GeneralForm />
              <Permissions />

              <Box sx={{ mt: '48px' }}>
                <ProfileFormFooter
                  loadingSave={loadingCreation || loadingUpdate}
                  onDeleteAccount={() => deleteModalRef.current?.handleOpen()}
                />
              </Box>
            </Form>
          </UserFormContainer>
        )}
      </PageContainerBottom>
    </>
  );
};

export default UserProfile;
