import { Grid, Stack, Typography } from '@mui/material';

import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import useTranslate from '~/hooks/useTranslate';

import { User } from '~/store/types/session';
import { UserUpdate } from '~/store/types/user';

import ProfileAvatar from '~/components/ProfileAvatar';
import RhfTextField from '~/components/RhfComponents/RhfTextField';
import RhfTextFieldPassword from '~/components/RhfComponents/RhfTextFieldPassword';

interface Props {
  user: User | null;
}

const ClientForm: React.FC<Props> = ({ user }) => {
  const {
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  const translate = useTranslate();

  const userImageProfile = user?.image ? user.image.url : '';

  const handleImageChange = useCallback(() => {
    //
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    const dataToReset: UserUpdate = {
      name: user.name,
      email: user.email,
    };

    reset(dataToReset);
  }, [reset, user]);

  return (
    <Stack rowGap={2} direction="column">
      <ProfileAvatar
        alt="User"
        defaultImageSrc={userImageProfile}
        onImageChange={handleImageChange}
      />

      <Typography variant="subtitle1">
        <FormattedMessage id="clients_profile_section_general" />
      </Typography>

      <Grid container spacing={3}>
        <Grid item md={7} xs={14}>
          <RhfTextField
            name="name"
            control={control}
            error={errors.name}
            fullWidth
            label={translate('clients_profile_name')}
            placeholder={translate('clients_profile_name')}
          />
        </Grid>

        <Grid item md={7} xs={14}>
          <RhfTextField
            name="email"
            control={control}
            error={errors.email}
            fullWidth
            label={translate('clients_profile_email')}
            placeholder={translate('clients_profile_email')}
          />
        </Grid>

        <Grid item md={7} xs={14}>
          <RhfTextFieldPassword
            name="password"
            control={control}
            error={errors.password}
            fullWidth
            label={translate('clients_profile_new_password')}
            placeholder={translate('clients_profile_new_password')}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle1">
        <FormattedMessage id="clients_profile_section_address" />
      </Typography>

      <Grid container spacing={3}>
        <Grid item md={7} xs={14}>
          <RhfTextField
            disabled
            name="address"
            control={control}
            error={errors.address}
            fullWidth
            label={translate('clients_profile_address')}
            placeholder={translate('clients_profile_address')}
          />
        </Grid>

        <Grid item md={7} xs={14}>
          <RhfTextField
            disabled
            name="state"
            control={control}
            error={errors.state}
            fullWidth
            label={translate('clients_profile_state')}
            placeholder={translate('clients_profile_state')}
          />
        </Grid>

        <Grid item md={7} xs={14}>
          <RhfTextField
            disabled
            name="country"
            control={control}
            error={errors.country}
            fullWidth
            label={translate('clients_profile_country')}
            placeholder={translate('clients_profile_country')}
          />
        </Grid>

        <Grid item md={7} xs={14}>
          <RhfTextField
            disabled
            name="postcode"
            control={control}
            error={errors.postcode}
            fullWidth
            label={translate('clients_profile_postcode')}
            placeholder={translate('clients_profile_postcode')}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ClientForm;
