import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { Backdrop, Fade, Modal, Stack, Typography } from '@mui/material';

import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import VimeoPlayer from '~/components/VimeoPlayer';

import { TextBox, VideoErrorStyled, WorkoutModalBox } from './styles';

export interface WorkoutModalRef {
  handleOpen: (item?: unknown) => void;
  handleClose: () => void;
}

interface Props {
  name: string;
  description: string;
  videoId: string;
}

const videoPlayerStyle: React.CSSProperties = {
  width: '100%',
  maxWidth: '734px',
};

const WorkoutModal: ForwardRefRenderFunction<WorkoutModalRef, Props> = (
  props,
  ref
) => {
  const { name, description, videoId } = props;

  const [open, setOpen] = useState(false);
  const [videoFound, setVideoFound] = useState(true);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleErrorNotFoundVideo = useCallback(() => {
    setVideoFound(false);
  }, []);

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
  }));

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <WorkoutModalBox>
          <Stack
            direction="row"
            sx={{
              gap: '20px',
              '@media(max-width: 700px)': {
                flexDirection: 'column',
              },
            }}
          >
            {videoFound ? (
              <VimeoPlayer
                video={videoId}
                style={videoPlayerStyle}
                onError={handleErrorNotFoundVideo}
              />
            ) : (
              <VideoErrorStyled>
                <ErrorOutlineIcon fontSize="large" />
                <Typography
                  variant="h4"
                  fontWeight="600"
                  color="primary.contrastText"
                >
                  <FormattedMessage id="workout_video_upload_failure" />
                </Typography>
              </VideoErrorStyled>
            )}

            <Stack
              spacing={2}
              flex={1}
              sx={{
                '@media(max-width: 700px)': {
                  width: '100%',
                  margin: '0',
                },
              }}
            >
              <TextBox>
                <Typography variant="caption" fontWeight="700">
                  <FormattedMessage id="modal_workout_name" />{' '}
                </Typography>

                <Typography variant="caption">{name}</Typography>
              </TextBox>

              <TextBox>
                <Typography variant="caption" fontWeight="700">
                  <FormattedMessage id="modal_workout_description" />
                </Typography>

                <Typography variant="caption">{description}</Typography>
              </TextBox>
            </Stack>
          </Stack>
        </WorkoutModalBox>
      </Fade>
    </Modal>
  );
};

export default forwardRef(WorkoutModal);
