import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MuiTable = {
  defaultProps?: ComponentsProps['MuiTable'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTable'];
  variants?: ComponentsVariants['MuiTable'];
};

const styles = ({ palette }: Theme): MuiTable => ({
  styleOverrides: {
    root: {
      border: `1px solid ${palette.grey[300]}`,

      '.MuiTableBody-root .MuiTableRow-root': {
        '&:last-child td': {
          border: 0,
        },
      },

      '.MuiTableCell-root': {
        borderColor: palette.grey[200],
      },
    },
  },
});

export default styles;
