import { Link as LinkIcon } from '@mui/icons-material/';
import { Grid, InputAdornment } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import courseBanner from '~/assets/images/course-banner.png';

import { workoutsKeywords } from '~/helpers/pages/workouts';
import { workoutsEquipmentsOptions } from '~/helpers/workout/equipments';
import { workoutsLabelsOptions } from '~/helpers/workout/labels';
import useTranslate from '~/hooks/useTranslate';
import useTypedSelector from '~/hooks/useTypedSelector';

import { WorkoutActions } from '~/store/ducks/workout';

import CircularLoading from '~/components/CircularLoading';
import RhfFilterAutocomplete from '~/components/RhfComponents/RhfFilterAutocomplete';
import RhfTextField from '~/components/RhfComponents/RhfTextField';

import { Image } from './styles';

const trainers = [
  { value: 'rob', labelId: 'trainer_rob' },
  { value: 'gloria', labelId: 'trainer_gloria' },
  { value: 'mana', labelId: 'trainer_mana' },
];

const WorkoutForm: React.FC = () => {
  const { workout, loadingThumbnail } = useTypedSelector(
    (state) => state.workout
  );
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const dispatch = useDispatch();
  const { workoutId } = useParams();

  const {
    reset,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const video = watch('video');

  const translate = useTranslate();

  useEffect(() => {
    if (video && video.length) {
      const splitLink = video.split('/');
      const id = splitLink[splitLink.length - 1];
      if (id && id.length) {
        dispatch(
          WorkoutActions.loadVideoThumbnailRequest(id, (thumbnail: string) => {
            setThumbnailUrl(thumbnail);
          })
        );
      }
    }
  }, [video, thumbnailUrl]);

  useEffect(() => {
    if (workoutId !== workoutsKeywords.newWorkout && workout) {
      const dataToReset = {
        title: workout.title,
        description: workout.description,
        video: workout.video.link,
        advice: workout.advice,
        goals: workout.goals,
        trainers: workout.trainers || [],
        optionalDescription: workout.optionalDescription,
        labels: workout.labels !== null ? workout?.labels : [],
        equipments: workout.equipments !== null ? workout?.equipments : [],
      };

      reset(dataToReset);
    }
  }, [reset, workout]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={14} sm={7}>
        <RhfTextField
          error={errors.title}
          fullWidth
          variant="outlined"
          name="title"
          type="text"
          control={control}
          label={translate('workout_title')}
        />
      </Grid>

      <Grid item xs={14} sm={7}>
        <RhfFilterAutocomplete
          name="labels"
          control={control}
          error={errors?.labels}
          labelId="workout_label"
          optionsLabelsIds={workoutsLabelsOptions}
          multiple
          defaultValue={[workoutsLabelsOptions[0]]}
          ChipProps={{ variant: 'outlined', size: 'small' }}
        />
      </Grid>

      <Grid item xs={14}>
        <RhfFilterAutocomplete
          name="equipments"
          control={control}
          error={errors?.equipments}
          labelId="workout_equipments"
          optionsLabelsIds={workoutsEquipmentsOptions}
          multiple
          defaultValue={[workoutsEquipmentsOptions[0]]}
          ChipProps={{ variant: 'outlined', size: 'small' }}
        />
      </Grid>

      <Grid item xs={14}>
        <RhfFilterAutocomplete
          name="trainers"
          control={control}
          error={errors?.trainers}
          labelId="workout_trainer"
          optionsLabelsIds={trainers}
          multiple
          defaultValue={[]}
          ChipProps={{ variant: 'outlined', size: 'small' }}
        />
      </Grid>

      <Grid item xs={14} sm={7}>
        {loadingThumbnail ? (
          <CircularLoading />
        ) : (
          <Image src={thumbnailUrl || courseBanner} alt="thumbnail" />
        )}
      </Grid>

      <Grid item xs={14} sm={7}>
        <RhfTextField
          name="description"
          control={control}
          error={errors?.description}
          label={translate('workout_description')}
          multiline
          rows={5}
          fullWidth
          variant="outlined"
        />
      </Grid>

      <Grid item xs={14}>
        <RhfTextField
          name="video"
          control={control}
          error={errors.video}
          label={translate('workout_video')}
          fullWidth
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LinkIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={14} lg={7}>
        <RhfTextField
          name="goals"
          control={control}
          error={errors?.goals}
          label={translate('workout_goals')}
          multiline
          rows={4}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={14} lg={7}>
        <RhfTextField
          name="advice"
          control={control}
          error={errors?.advice}
          label={translate('workout_advice')}
          multiline
          rows={4}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={14}>
        <RhfTextField
          name="optionalDescription"
          control={control}
          error={errors?.optionalDescription}
          label={translate('workout_optional_description')}
          multiline
          rows={2}
          fullWidth
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default WorkoutForm;
