import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { clientProfileValidationSchema } from '~/helpers/yup';
import useTypedSelector from '~/hooks/useTypedSelector';

import { UserActions } from '~/store/ducks/user';
import { UserUpdate } from '~/store/types/user';

import { ClientForm, Form, FormFooter } from '~/components/FormComponents';
import DeleteModal, { DeleteModalRef } from '~/components/Modals/DeleteModal';
import { TabPanelContainer } from '~/components/TabComponents';

const ProfilePanel: React.FC = () => {
  const { user, loadingUpdate, loadingDeletion } = useTypedSelector(
    (state) => state.user
  );

  const methods = useForm({
    resolver: yupResolver(clientProfileValidationSchema),
  });

  const { userId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteModalRef = useRef<DeleteModalRef>(null);

  const handleSave = useCallback(
    (data: UserUpdate) =>
      dispatch(
        UserActions.updateUserRequest(userId as string, data, () => {
          navigate('/users');
        })
      ),
    [dispatch, userId]
  );

  const handleDeleteAccount = useCallback(() => {
    dispatch(
      UserActions.deleteUserRequest(userId as string, () => {
        deleteModalRef.current?.handleClose();
        navigate('/users');
      })
    );
  }, [dispatch, userId, navigate]);

  return (
    <>
      <DeleteModal
        ref={deleteModalRef}
        onConfirm={handleDeleteAccount}
        loadingDeletion={loadingDeletion}
      />

      <Form {...methods} onSubmit={handleSave}>
        <TabPanelContainer>
          <ClientForm user={user} />

          <FormFooter
            sx={{ mt: '48px' }}
            loadingSave={loadingUpdate}
            onDelete={() => deleteModalRef.current?.handleOpen()}
          />
        </TabPanelContainer>
      </Form>
    </>
  );
};

export default ProfilePanel;
