import { SvgIconComponent } from '@mui/icons-material';

import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { isSubpath } from '~/helpers/pages';

import { ItemButton, ItemContainer, ItemTypography } from './styles';

interface Props {
  href: string;
  text: string;
  Icon: SvgIconComponent;
}

const SidebarItem: React.FC<Props> = ({ href, text, Icon }) => {
  const location = useLocation();

  const active = useMemo(() => {
    const exactPath = location.pathname === href;
    const isSub = isSubpath(location.pathname, href, true);

    const isActive = exactPath || isSub;

    return isActive ? 1 : 0;
  }, [location.pathname, href]);

  return (
    <ItemContainer>
      <ItemButton LinkComponent={Link} to={href} active={active}>
        <Icon />
        <ItemTypography variant="body1">{text}</ItemTypography>
      </ItemButton>
    </ItemContainer>
  );
};

export default SidebarItem;
