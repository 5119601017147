import { createActions, createReducer } from 'reduxsauce';

import { getSessionData, logout as logoutHelper } from '~/helpers/session';

import {
  ISessionCreators,
  ISessionTypes,
  LoginSuccessAction,
  SessionState,
  SetUserThemeAction,
  User,
} from '../types/session';

export const { Types: SessionTypes, Creators: SessionActions } = createActions<
  ISessionTypes,
  ISessionCreators
>({
  loginRequest: ['credentials', 'callback'],
  loginSuccess: ['user', 'token'],
  loginFailure: [],

  logout: ['callback'],

  setUserTheme: ['theme'],
});

const session = getSessionData();

const INITIAL_STATE: SessionState = {
  user: session.user as User,
  token: session.token as string,
  theme: session.theme,
  loading: false,
};

const loginRequest = (state = INITIAL_STATE): SessionState => ({
  ...state,
  loading: true,
});

const loginSuccess = (
  state = INITIAL_STATE,
  action: LoginSuccessAction
): SessionState => ({
  ...state,
  user: { ...state.user, ...action.user },
  token: action.token,
  loading: false,
});

const loginFailure = (state = INITIAL_STATE): SessionState => ({
  ...state,
  loading: false,
});

const logout = (state = INITIAL_STATE): SessionState => {
  logoutHelper();

  return {
    ...state,
    token: '',
    user: {} as User,
  };
};

const setUserTheme = (
  state = INITIAL_STATE,
  action: SetUserThemeAction
): SessionState => ({
  ...state,
  theme: action.theme,
});

export default createReducer(INITIAL_STATE, {
  [SessionTypes.LOGIN_REQUEST]: loginRequest,
  [SessionTypes.LOGIN_SUCCESS]: loginSuccess,
  [SessionTypes.LOGIN_FAILURE]: loginFailure,

  [SessionTypes.LOGOUT]: logout,

  [SessionTypes.SET_USER_THEME]: setUserTheme,
});
