import { styled, Box, Grid, Stack } from '@mui/material';

export const BoxStyles = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  width: '95%',
  maxWidth: '970px',
  height: '680px',

  boxShadow: '24',
  padding: '24px 0 36px 24px',
  borderRadius: '4px',
}));

export const CloseButton = styled(Box)(() => ({
  position: 'absolute',
  top: '24px',
  right: '8px',
}));

export const WorkoutsSide = styled(Grid)(() => ({
  overflowY: 'scroll',
  height: '500px',
  width: '65%',
  padding: '0 34px 0 32px',

  '@media(max-width: 600px)': {
    width: '100%',
    height: '300px',
  },
}));

export const SearchSide = styled(Stack)(({ theme }) => ({
  width: '35%',
  paddingRight: '38px',
  borderRight: `1px solid ${theme.palette.primary.light}`,

  '@media(max-width: 600px)': {
    width: '100%',
    marginBottom: '20px',
  },
}));

export const UnselectCardWorkout = styled('div')(() => ({
  border: `2px solid transparent`,
  borderRadius: '4px',

  padding: '6px',
}));

export const SelectCardWorkout = styled('div')(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',

  padding: '6px',
}));
