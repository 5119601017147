import { ChevronRight } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Breadcrumbs, Link, Tab, Typography } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams, Link as ReactRouterLink } from 'react-router-dom';

import useTranslate from '~/hooks/useTranslate';
import useTypedSelector from '~/hooks/useTypedSelector';

import { UserActions } from '~/store/ducks/user';

import CircularLoading from '~/components/CircularLoading';
import { PageContainerBottom } from '~/components/PageContainer';
import { TabList } from '~/components/TabComponents';

import NotificationPanel from './components/NotificationPanel';
import PaymentPanel from './components/PaymentPanel';
import ProfilePanel from './components/ProfilePanel';

const ClientProfile: React.FC = () => {
  const { loading } = useTypedSelector((state) => state.user);

  const { userId } = useParams();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = useCallback((_event, newValue: string) => {
    setActiveTab(newValue);
  }, []);

  useEffect(() => {
    dispatch(UserActions.loadUserRequest(userId as string));
  }, [dispatch, userId]);

  return (
    <PageContainerBottom>
      <Breadcrumbs
        aria-label="navigation-breadcrumb"
        separator={<ChevronRight />}
      >
        <Link
          component={ReactRouterLink}
          underline="hover"
          color="inherit"
          to="/"
        >
          <FormattedMessage id="platform" />
        </Link>

        <Link
          component={ReactRouterLink}
          underline="hover"
          color="inherit"
          to="/users"
        >
          <Typography variant="body1">
            <FormattedMessage id="users_breadcrumb" />
          </Typography>
        </Link>

        <Link
          component={ReactRouterLink}
          underline="hover"
          color="text.primary"
          to={`/users/clients/profile/${userId}`}
          aria-current="page"
        >
          <Typography variant="body1">
            <FormattedMessage id="client_profile_breadcrumb" />
          </Typography>
        </Link>
      </Breadcrumbs>

      {loading ? (
        <CircularLoading />
      ) : (
        <TabContext value={activeTab}>
          <TabList
            onChange={handleTabChange}
            aria-label="navigation-tabs"
            variant="fullWidth"
          >
            <Tab label={translate('client_profile_profile_tab')} value="1" />
            <Tab
              label={translate('client_profile_notification_tab')}
              value="2"
            />
            <Tab label={translate('client_profile_payment_tab')} value="3" />
          </TabList>

          <TabPanel value="1">
            <ProfilePanel />
          </TabPanel>

          <TabPanel value="2">
            <NotificationPanel />
          </TabPanel>

          <TabPanel value="3">
            <PaymentPanel />
          </TabPanel>
        </TabContext>
      )}
    </PageContainerBottom>
  );
};

export default ClientProfile;
