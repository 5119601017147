import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import AuthContainer from '~/containers/AuthContainer';

import useTypedSelector from '~/hooks/useTypedSelector';

const AuthLayout: React.FC = ({ children }) => {
  const { user, token } = useTypedSelector((state) => state.session);

  const signed = useMemo(() => user && token, [user, token]);

  return signed ? (
    <AuthContainer>{children}</AuthContainer>
  ) : (
    <Navigate to="/signin" />
  );
};

export default AuthLayout;
