import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MuiTabs = {
  defaultProps?: ComponentsProps['MuiTabs'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTabs'];
  variants?: ComponentsVariants['MuiTabs'];
};

const styles = (_theme: Theme): MuiTabs => ({
  styleOverrides: {
    root: {
      '& ~ .MuiTabPanel-root': {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,

        padding: '42px 0 0',

        '&[hidden]': {
          display: 'none',
        },
      },
    },
  },
});

export default styles;
