import { call, put, takeLatest } from 'redux-saga/effects';

import { ApiError, ApiResponse } from '~/types/request';

import { getErrorIdFromResponse } from '~/helpers/api';
import { removeEmptyValuesFromObject } from '~/helpers/object';
import api from '~/services/api';

import { FlashMessageActions } from '../ducks/flashMessage';
import { UserActions, UserTypes } from '../ducks/user';
import { User } from '../types/session';
import {
  CreateUserRequestAction,
  DeleteUserRequestAction,
  LoadUserRequestAction,
  UpdateUserRequestAction,
} from '../types/user';

type LoadUserResponse = ApiResponse<User>;

function* loadUserRequest({ userId }: LoadUserRequestAction) {
  try {
    const response: LoadUserResponse = yield call(api.get, `/users/${userId}`);

    const user = response.data;

    yield put(UserActions.loadUserSuccess(user));
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response);

    yield put(UserActions.loadUserFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* createUserRequest({ data, callback }: CreateUserRequestAction) {
  try {
    yield call(api.post, '/users', data);

    yield put(UserActions.createUserSuccess());
    yield put(
      FlashMessageActions.showMessage({
        id: 'users_creation_success',
        variant: 'success',
      })
    );

    callback();
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'users_creation_failure');

    yield put(UserActions.createUserFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* updateUserRequest({
  userId,
  data,
  callback,
}: UpdateUserRequestAction) {
  try {
    const requestData = removeEmptyValuesFromObject(data);

    // [TODO]: Change when backend allow role
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { role, ...validData } = requestData;

    yield call(api.patch, `/users/${userId}`, validData);

    yield put(UserActions.updateUserSuccess());
    yield put(
      FlashMessageActions.showMessage({
        id: 'users_update_success',
        variant: 'success',
      })
    );

    callback();
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'users_update_failure');

    yield put(UserActions.updateUserFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* deleteUserRequest({ userId, callback }: DeleteUserRequestAction) {
  try {
    yield call(api.delete, `/users/${userId}`);

    yield put(UserActions.deleteUserSuccess());
    yield put(
      FlashMessageActions.showMessage({
        id: 'users_deletion_success',
        variant: 'success',
      })
    );

    callback();
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'users_deletion_failure');

    yield put(UserActions.deleteUserFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

export default function* sagas() {
  yield takeLatest(UserTypes.LOAD_USER_REQUEST, loadUserRequest);
  yield takeLatest(UserTypes.CREATE_USER_REQUEST, createUserRequest);
  yield takeLatest(UserTypes.UPDATE_USER_REQUEST, updateUserRequest);
  yield takeLatest(UserTypes.DELETE_USER_REQUEST, deleteUserRequest);
}
