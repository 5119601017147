import { Box, styled } from '@mui/material';

export const LoadingBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,

  width: '100%',
  height: '100%',
}));
