import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { Breadcrumbs, Link, Typography } from '@mui/material';

import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterLink, useParams } from 'react-router-dom';

import { ObjectString } from '~/types/object';

import { profileKeywords } from '~/helpers/pages/profile';

const userMessagesId: ObjectString = {
  [profileKeywords.me]: 'profile_breadcrumb_me',
  [profileKeywords.newUser]: 'profile_breadcrumb_new_user',
};

const UserProfileBreadcrumb: React.FC = () => {
  const { userId } = useParams();

  const breadcrumbMessageId = useMemo(() => {
    const messageId = userMessagesId[userId as string];
    return messageId || 'profile_breadcrumb';
  }, [userId]);

  return (
    <Breadcrumbs
      aria-label="navigation-breadcrumb"
      separator={<ChevronRightIcon />}
    >
      <Link
        component={ReactRouterLink}
        underline="hover"
        color="inherit"
        to="/"
      >
        <FormattedMessage id="platform" />
      </Link>

      <Link
        component={ReactRouterLink}
        underline="hover"
        color="inherit"
        to="/users"
      >
        <Typography variant="body1">
          <FormattedMessage id="users_breadcrumb" />
        </Typography>
      </Link>

      <Link
        component={ReactRouterLink}
        underline="hover"
        color="text.primary"
        to={`/users/profile/${userId}`}
        aria-current="page"
      >
        <Typography variant="body1">
          <FormattedMessage id={breadcrumbMessageId} />
        </Typography>
      </Link>
    </Breadcrumbs>
  );
};

export default UserProfileBreadcrumb;
