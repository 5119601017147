/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@mui/material';

import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

type RhfProps = {
  name: string;
  control: Control;
  error: any;
};

type Props = Omit<FormControlLabelProps, 'control'> & RhfProps;

const RhfFormControlLabel: React.FC<Props> = ({
  name,
  label,
  control,
  defaultChecked,
  error,
  ...rest
}) => {
  const intl = useIntl();

  return (
    <Controller
      render={({ field: { onChange, value, ref, ...props } }) => (
        <>
          <FormControlLabel
            inputRef={ref}
            onChange={onChange}
            checked={value}
            control={<Checkbox checked={value} />}
            label={label}
            {...props}
            {...rest}
          />

          {error && (
            <FormHelperText>
              {intl.formatMessage(error.message, error.message.values)}
            </FormHelperText>
          )}
        </>
      )}
      name={name}
      control={control}
      defaultValue={defaultChecked}
    />
  );
};

export default RhfFormControlLabel;
