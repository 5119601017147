import { createActions, createReducer } from 'reduxsauce';

import {
  FlashMessageState,
  IFlashMessageCreators,
  IFlashMessageTypes,
  ShowMessageAction,
} from '../types/flashMessage';

export const { Types: FlashMessageTypes, Creators: FlashMessageActions } =
  createActions<IFlashMessageTypes, IFlashMessageCreators>({
    showMessage: ['settings'],
    hideMessage: [],
  });

const INITIAL_STATE: FlashMessageState = {
  id: null,
  message: null,
  open: false,
  variant: 'error',
};

const showMessage = (
  state = INITIAL_STATE,
  action: ShowMessageAction
): FlashMessageState => ({
  ...state,
  id: action.settings.id || null,
  message: action.settings.message || null,
  variant: action.settings.variant || 'error',
  open: true,
});

const hideMessage = (state = INITIAL_STATE): FlashMessageState => ({
  ...state,
  id: null,
  message: null,
  variant: 'error',
  open: false,
});

export default createReducer(INITIAL_STATE, {
  [FlashMessageTypes.SHOW_MESSAGE]: showMessage,
  [FlashMessageTypes.HIDE_MESSAGE]: hideMessage,
});
