import { Grid, Stack } from '@mui/material';

import React from 'react';

import { TabPanelContainer } from '~/components/TabComponents';

import SwitchCustom from './components/SwitchCustom';
import TrainingReminder from './components/TrainingReminder';
import { DividerStyle, GridFlex } from './styles';

const NotificationPanel: React.FC = () => {
  return (
    <TabPanelContainer>
      <GridFlex container>
        <Grid item xs={14}>
          <TrainingReminder />
        </Grid>

        <DividerStyle />

        <Grid item xs={14}>
          <Stack spacing={4}>
            <Grid item xs={14}>
              <SwitchCustom
                title="notifications_switch_weekly_training_plan_title"
                label="notifications_switch_weekly_training_plan_label"
              />
            </Grid>
            <Grid item xs={14}>
              <SwitchCustom
                title="notifications_switch_newsletter_title"
                label="notifications_switch_newsletter_label"
              />
            </Grid>
          </Stack>
        </Grid>
      </GridFlex>
    </TabPanelContainer>
  );
};

export default NotificationPanel;
