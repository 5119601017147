import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ApiError, ApiPaginationResponse, ApiResponse } from '~/types/request';

import {
  getPaginationOptionsFromResponse,
  getPaginationQueryOptions,
  getErrorIdFromResponse,
} from '~/helpers/api';
import api from '~/services/api';

import { FlashMessageActions } from '../ducks/flashMessage';
import { UsersActions, UsersTypes } from '../ducks/users';
import { Pagination, PaginationResponse } from '../types/pagination';
import { User } from '../types/session';
import { PurchaseCallback, RoleCallback } from '../types/users';

type UsersResponse = ApiPaginationResponse<User[], PaginationResponse>;
type LoadUserRolesResponse = ApiResponse<RoleCallback[]>;
type LoadPurchasesResponse = ApiResponse<PurchaseCallback[]>;

function* loadUsersRequest() {
  const pagination: Pagination = yield select((state) => state.users.options);

  try {
    const paginationOptions = getPaginationQueryOptions(pagination);

    const response: UsersResponse = yield call(api.get, '/users/team', {
      params: paginationOptions,
    });

    const { data, ...rest } = response.data;
    const options = getPaginationOptionsFromResponse(rest);

    yield put(UsersActions.loadUsersSuccess(data, options));
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'users_load_failure');

    yield put(UsersActions.loadUsersFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* loadUserRolesRequest() {
  try {
    const response: LoadUserRolesResponse = yield call(api.get, '/roles');

    yield put(UsersActions.loadUserRolesSuccess(response.data));
  } catch (error) {
    yield put(UsersActions.loadUserRolesFailure());
  }
}

function* updateUsersOptions() {
  yield put(UsersActions.loadUsersRequest());
}

function* loadPurchasesRequest() {
  try {
    const response: LoadPurchasesResponse = yield call(api.get, '/purchases');

    yield put(UsersActions.loadPurchasesSuccess(response.data));
  } catch (error) {
    yield put(UsersActions.loadPurchasesFailure());
  }
}

function* loadClientsRequest() {
  const pagination: Pagination = yield select(
    (state) => state.users.clients.options
  );

  try {
    const paginationOptions = getPaginationQueryOptions(pagination);

    const response: UsersResponse = yield call(api.get, '/users', {
      params: paginationOptions,
    });

    const { data, ...rest } = response.data;
    const options = getPaginationOptionsFromResponse(rest);

    yield put(UsersActions.loadClientsSuccess(data, options));
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'users_load_failure');

    yield put(UsersActions.loadClientsFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* updateClientsOptions() {
  yield put(UsersActions.loadClientsRequest());
}

export default function* sagas() {
  yield takeLatest(UsersTypes.LOAD_USERS_REQUEST, loadUsersRequest);
  yield takeLatest(UsersTypes.LOAD_USER_ROLES_REQUEST, loadUserRolesRequest);
  yield takeLatest(UsersTypes.UPDATE_USERS_OPTIONS, updateUsersOptions);
  yield takeLatest(UsersTypes.LOAD_PURCHASES_REQUEST, loadPurchasesRequest);
  yield takeLatest(UsersTypes.LOAD_CLIENTS_REQUEST, loadClientsRequest);
  yield takeLatest(UsersTypes.UPDATE_CLIENTS_OPTIONS, updateClientsOptions);
}
