import { ChevronRight } from '@mui/icons-material';
import { Breadcrumbs, Link, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, format } from 'date-fns';
import { useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

import { newCouponValidationSchema } from '~/helpers/yup';

import { CouponsActions } from '~/store/ducks/coupon';
import { CouponsYuploadOutput } from '~/store/types/coupons';

import { Form } from '~/components/FormComponents';
import { PageContainer } from '~/components/PageContainer';

import CouponForm from './components/CouponForm';
import { FormCouponContainer } from './styles';

const NewCoupon: React.FC = () => {
  const defaultValues: FieldValues = {
    quantity: 1,
    unique: true,
    discount: 20,
    expirationDate: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(newCouponValidationSchema),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const callback = () => {
    navigate('/coupons');

    dispatch(CouponsActions.loadCouponsRequest());
  };

  const handleSubmit = useCallback(
    (data: CouponsYuploadOutput) => {
      dispatch(
        CouponsActions.createCouponsRequest(
          data as CouponsYuploadOutput,
          callback
        )
      );
    },
    [dispatch]
  );

  return (
    <PageContainer>
      <Breadcrumbs
        aria-label="navigation-breadcrumb"
        separator={<ChevronRight />}
      >
        <Link
          component={ReactRouterLink}
          underline="hover"
          color="inherit"
          to="/"
        >
          <FormattedMessage id="platform" />
        </Link>

        <Link
          component={ReactRouterLink}
          underline="hover"
          color="text.primary"
          to="/coupons"
          aria-current="page"
        >
          <Typography variant="body1">
            <FormattedMessage id="coupons_breadcrumbs" />
          </Typography>
        </Link>
        <Link
          component={ReactRouterLink}
          underline="hover"
          color="text.primary"
          to="/coupons/new-coupon"
          aria-current="page"
        >
          <Typography variant="body1">
            <FormattedMessage id="new_coupon_breadcrumbs" />
          </Typography>
        </Link>
      </Breadcrumbs>
      <FormCouponContainer>
        <Form onSubmit={handleSubmit} {...methods}>
          <CouponForm />
        </Form>
      </FormCouponContainer>
    </PageContainer>
  );
};
export default NewCoupon;
