import { Grid, Typography } from '@mui/material';

import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { defaultListOptions } from '~/helpers/table/state';
import useTypedSelector from '~/hooks/useTypedSelector';

import { WorkoutsActions } from '~/store/ducks/workouts';
import { Workout } from '~/store/types/workouts';

import { SeeMoreButton } from '~/components/Buttons/SeeMoreButton/styles';
import CircularLoading from '~/components/CircularLoading';
import ClassCard from '~/components/ClassCard';
import WorkoutModal, {
  WorkoutModalRef,
} from '~/components/Modals/WorkoutModal';

const WorkoutsList: React.FC = () => {
  const { workouts, loading, options } = useTypedSelector(
    (state) => state.workouts
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const workoutModalRef = useRef<WorkoutModalRef>(null);

  const [selectedWorkout, setSelectedWorkout] = useState<Workout | null>(null);

  const handlePlayWorkoutVideo = useCallback(
    (workout: Workout) => {
      setSelectedWorkout(workout);
    },
    [selectedWorkout]
  );

  useEffect(() => {
    workoutModalRef.current?.handleOpen();
  }, [selectedWorkout]);

  const handleEditWorkout = useCallback((workout: Workout) => {
    navigate(`/workouts/${workout.id}`);
  }, []);

  const handleSeeMore = useCallback(() => {
    const newOptions = {
      limit: options.limit + defaultListOptions.pagination.limit,
    };

    dispatch(WorkoutsActions.updateWorkoutsOptions(newOptions));
  }, [dispatch, options.limit]);

  const seeMoreDisabled = useMemo(
    () => options.limit >= options.total,
    [options]
  );

  if (loading) {
    return <CircularLoading />;
  }

  return (
    <>
      {selectedWorkout && (
        <WorkoutModal
          videoId={selectedWorkout.video.id}
          name={selectedWorkout.title}
          description={selectedWorkout.description}
          ref={workoutModalRef}
        />
      )}

      <Grid container spacing={3} mt={4}>
        {workouts.map((workout) => (
          <Grid key={workout.id} item lg={14 / 3} md={14 / 3} sm={7} xs={14}>
            <ClassCard
              thumbnail={workout.image.url}
              title={workout.title}
              description={workout.description}
              labels={workout.labels}
              onPlayVideo={() => handlePlayWorkoutVideo(workout)}
              onEdit={() => handleEditWorkout(workout)}
              sx={{
                '@media(max-width: 600px)': {
                  marginInline: 'auto',
                },
              }}
            />
          </Grid>
        ))}
      </Grid>

      {!seeMoreDisabled && (
        <SeeMoreButton
          onClick={handleSeeMore}
          disabled={seeMoreDisabled}
          disableRipple
        >
          <Typography variant="body1">
            <FormattedMessage id="see_more" />
          </Typography>
        </SeeMoreButton>
      )}
    </>
  );
};

export default WorkoutsList;
