import { Typography, Grid, Button, Alert } from '@mui/material';

import React from 'react';
import { useFormContext } from 'react-hook-form';

import useTranslate from '~/hooks/useTranslate';

import RhfFormControlLabel from '~/components/RhfComponents/RhfFormControlLabel';
import RhfSlider from '~/components/RhfComponents/RhfSlider';
import RhfTextField from '~/components/RhfComponents/RhfTextField';

import { DividerStyle } from '../../styles';

const CouponForm: React.FC = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const couponsQuantity = Number(watch('quantity'));
  const uniqueUse = watch('unique');

  const translate = useTranslate();

  return (
    <Grid container spacing={4}>
      <Grid item xs={14}>
        <Alert sx={{ width: '100%' }} severity="info">
          {translate('new_coupon_quantity_info')}
        </Alert>
      </Grid>
      <Grid item xs={14} sm={7}>
        <RhfTextField
          error={errors.quantity}
          fullWidth
          variant="outlined"
          type="number"
          control={control}
          name="quantity"
          label={translate('new_coupon_number_of_coupons')}
        />
      </Grid>

      <Grid item xs={14} sm={7}>
        <RhfFormControlLabel
          name="unique"
          control={control}
          error={errors?.usesLimit}
          label={translate('new_coupon_use_unique')}
        />
      </Grid>
      <Grid item xs={14} lg={7}>
        <RhfTextField
          control={control}
          error={errors?.code}
          label={translate('new_coupon_code')}
          fullWidth
          variant="outlined"
          name="code"
          disabled={couponsQuantity !== 1}
        />
      </Grid>
      <Grid item xs={14} sm={7}>
        <RhfTextField
          name="usesLimit"
          control={control}
          error={errors?.usesLimit}
          label={translate('new_coupon_number_of_uses')}
          fullWidth
          disabled={uniqueUse}
          type="number"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={14} lg={7}>
        <Typography id="new_coupon_discount" gutterBottom>
          {translate('new_coupon_discount')}
        </Typography>
        <RhfSlider
          control={control}
          min={1}
          max={100}
          name="discount"
          valueLabelDisplay="auto"
        />
      </Grid>
      <Grid item xs={14} sm={7}>
        <RhfTextField
          control={control}
          error={errors?.expirationDate}
          fullWidth
          variant="outlined"
          type="date"
          name="expirationDate"
        />
      </Grid>
      <DividerStyle />
      <Grid item xs={14} lg={7}>
        <Button type="submit" variant="contained">
          <Typography>{translate('new_coupon_create_coupon')}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default CouponForm;
