import { Add as AddIcon } from '@mui/icons-material';

import React from 'react';

import { AddWorkoutCardStyled, IconBox } from './styles';

interface Props {
  onAdd: () => void;
}

const AddWorkoutCard: React.FC<Props> = ({ onAdd }) => {
  return (
    <AddWorkoutCardStyled onClick={onAdd}>
      <IconBox color="primary">
        <AddIcon />
      </IconBox>
    </AddWorkoutCardStyled>
  );
};

export default AddWorkoutCard;
