import { Button, Grid } from '@mui/material';

import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useTypedSelector from '~/hooks/useTypedSelector';

import { CouponsActions } from '~/store/ducks/coupon';

import CircularLoading from '~/components/CircularLoading';

import CouponsTable from './CouponsTable';
import { CouponsTableContainer } from './styles';

const CouponsPannel: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleCreateCoupon = useCallback(() => {
    navigate('/coupons/new-coupon');
  }, [navigate]);

  useEffect(() => {
    dispatch(CouponsActions.loadCouponsRequest());
  }, [dispatch]);
  const { loading } = useTypedSelector((state) => state.coupons);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={14} sm={4} mt={5}>
          <Button variant="contained" fullWidth onClick={handleCreateCoupon}>
            <FormattedMessage id="coupons_new_coupon" />
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        <CircularLoading />
      ) : (
        <CouponsTableContainer>
          <CouponsTable />
        </CouponsTableContainer>
      )}
    </>
  );
};
export default CouponsPannel;
