import { PaginationDirection } from '~/store/types/pagination';

export const defaultTableOptions = {
  sort: {
    direction: 'asc' as PaginationDirection,
  },
  pagination: {
    rowsPerPageOptions: [5, 10, 25],
    limit: 5, // first of the rowsPerPageOptions
  },
};

export const defaultListOptions = {
  sort: {
    direction: 'asc' as PaginationDirection,
  },
  pagination: {
    limit: 6,
  },
};
