import { AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ApiError, ApiPaginationResponse } from '~/types/request';

import {
  getErrorIdFromResponse,
  getPaginationOptionsFromResponse,
  getPaginationQueryOptions,
} from '~/helpers/api';
import api from '~/services/api';

import { FlashMessageActions } from '../ducks/flashMessage';
import { WorkoutsActions, WorkoutsTypes } from '../ducks/workouts';
import { Level } from '../types/level';
import { Pagination, PaginationResponse } from '../types/pagination';
import {
  AddWorkoutsLevelRequestAction,
  DeleteWorkoutLevelRequestAction,
  Workout,
} from '../types/workouts';

type WorkoutsResponse = ApiPaginationResponse<Workout[], PaginationResponse>;
type WorkoutsLevelResponse = AxiosResponse<Level>;

function* loadWorkoutsRequest() {
  const pagination: Pagination = yield select(
    (state) => state.workouts.options
  );

  try {
    const paginationOptions = getPaginationQueryOptions(pagination);

    const response: WorkoutsResponse = yield call(api.get, '/workouts', {
      params: paginationOptions,
    });

    const { data, ...rest } = response.data;
    const options = getPaginationOptionsFromResponse(rest);

    yield put(WorkoutsActions.loadWorkoutsSuccess(data, options));
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'workouts_load_failure');

    yield put(WorkoutsActions.loadWorkoutsFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* addWorkoutsLevelRequest({
  data,
  workoutLevelType,
  callback,
}: AddWorkoutsLevelRequestAction) {
  try {
    const response: WorkoutsLevelResponse = yield call(
      api.post,
      `/workoutsLevels/${workoutLevelType}`,
      data
    );

    yield put(
      FlashMessageActions.showMessage({
        id: 'workouts_level_success',
        variant: 'success',
      })
    );

    yield put(WorkoutsActions.addWorkoutsLevelSuccess(response.data));

    if (callback) {
      callback();
    }
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'workouts_level_success');

    yield put(WorkoutsActions.addWorkoutsLevelFailure(errorId));
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* deleteWorkoutLevelRequest({
  workoutLevelId,
  callback,
}: DeleteWorkoutLevelRequestAction) {
  try {
    yield call(api.delete, `/workoutsLevels/${workoutLevelId}`);

    yield put(WorkoutsActions.deleteWorkoutLevelSuccess());
    yield put(
      FlashMessageActions.showMessage({
        id: 'workout_level_delete_success',
        variant: 'success',
      })
    );
    if (callback) {
      callback();
    }
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(
      response,
      'workout_level_delete_failure'
    );

    yield put(WorkoutsActions.deleteWorkoutLevelFailure(errorId));
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* updateWorkoutsOptions() {
  yield put(WorkoutsActions.loadWorkoutsRequest());
}

export default function* sagas() {
  yield takeLatest(WorkoutsTypes.LOAD_WORKOUTS_REQUEST, loadWorkoutsRequest);
  yield takeLatest(
    WorkoutsTypes.ADD_WORKOUTS_LEVEL_REQUEST,
    addWorkoutsLevelRequest
  );
  yield takeLatest(
    WorkoutsTypes.DELETE_WORKOUT_LEVEL_REQUEST,
    deleteWorkoutLevelRequest
  );
  yield takeLatest(
    WorkoutsTypes.UPDATE_WORKOUTS_OPTIONS,
    updateWorkoutsOptions
  );
}
