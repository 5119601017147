import axios from 'axios';

import { getSessionData } from '~/helpers/session';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
  const requestConfig = { ...config };
  const { token } = getSessionData();

  const authorization = token ? `Bearer ${token}` : '';

  requestConfig.headers = {
    ...config.headers,
    authorization,
  };

  return requestConfig;
});

export default api;
