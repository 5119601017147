import { Edit as EditIcon } from '@mui/icons-material';
import {
  Avatar,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { getProgressStyle } from '~/helpers/user';
import useTranslate from '~/hooks/useTranslate';

import { TablePagination } from '~/components/TableComponents';

interface _User {
  id: string;
  name: string;
  email: string;
  date: string;
  progress: number;
}

const moms: _User[] = [
  {
    id: '1',
    name: 'Carla Paes',
    email: 'carla9090@gmail.com',
    date: '10/02/2022',
    progress: 30,
  },
  {
    id: '2',
    name: 'Lucas Fugi',
    email: 'fugizãodamassa@gmail.com',
    date: '12/02/2022',
    progress: 20,
  },
];

const paginationData = {
  count: 10,
  options: {
    page: 1,
    limit: 5,
    total: 20,
  },
};

const MomsTable: React.FC = () => {
  const navigate = useNavigate();
  const translate = useTranslate();

  const handlePaginationPageChange = useCallback(() => {
    //
  }, []);

  const handlePaginationLimitChange = useCallback(() => {
    //
  }, []);

  const handleEdit = useCallback(
    (mom: _User) => navigate(`/moms/${mom.id}`),
    [navigate]
  );

  return (
    <>
      <Table aria-label="moms table">
        <TableHead>
          <TableRow>
            <TableCell />

            <TableCell>
              <FormattedMessage id="moms_name" />
            </TableCell>

            <TableCell>
              <FormattedMessage id="moms_email" />
            </TableCell>

            <TableCell>
              <FormattedMessage id="moms_progress" />
            </TableCell>

            <TableCell>
              <FormattedMessage id="moms_date" />
            </TableCell>

            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {moms.map((mom) => (
            <TableRow key={mom.id}>
              <TableCell>
                <Avatar placeholder={mom.name} sx={{ background: grey[400] }}>
                  {mom.name.substring(0, 1)}
                </Avatar>
              </TableCell>

              <TableCell>{mom.name}</TableCell>

              <TableCell>{mom.email}</TableCell>

              <TableCell>
                <Chip
                  size="medium"
                  label={`${mom.progress}%`}
                  {...getProgressStyle(mom.progress)}
                />
              </TableCell>

              <TableCell>{mom.date}</TableCell>

              <TableCell align="right">
                <Tooltip title={translate('edit')}>
                  <IconButton onClick={() => handleEdit(mom)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        options={paginationData.options}
        onPageChange={handlePaginationPageChange}
        onLimitChange={handlePaginationLimitChange}
      />
    </>
  );
};

export default MomsTable;
