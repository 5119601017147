import { Container, styled } from '@mui/material';

export const PageContainer = styled(Container)(({ theme }) => ({
  minHeight: 'calc(100vh - 113px)', // topbar height
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(4),

  display: 'flex',
  flexDirection: 'column',
}));

export const PageContainerBottom = styled(PageContainer)(() => ({
  paddingBottom: 0,
}));
