import { AxiosResponse } from 'axios';
import { takeLatest, put, call } from 'redux-saga/effects';

import { ApiError } from '~/types/request';

import { getErrorIdFromResponse } from '~/helpers/api';
import { login } from '~/helpers/session';
import api from '~/services/api';

import { FlashMessageActions } from '../ducks/flashMessage';
import { SessionTypes, SessionActions } from '../ducks/session';
import { LoginRequestAction, User } from '../types/session';

type LoginResponse = AxiosResponse<{
  token: string;
  user: User;
}>;

function* loginRequest({ credentials, callback }: LoginRequestAction) {
  try {
    const { rememberMe, ...loginCredentials } = credentials;

    const response: LoginResponse = yield call(
      api.post,
      '/admin/login',
      loginCredentials
    );

    const { user, token } = response.data;

    login({ user, token, rememberMe });

    yield put(SessionActions.loginSuccess(user, token));

    callback();
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response);

    yield put(SessionActions.loginFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

export default function* sagas() {
  yield takeLatest(SessionTypes.LOGIN_REQUEST, loginRequest);
}
