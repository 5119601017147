import { createActions, createReducer } from 'reduxsauce';

import {
  ILevelCreators,
  ILevelTypes,
  LevelState,
  UpdateLevelSuccessAction,
  UpdateLevelFailureAction,
  CreateLevelFailureAction,
  CreateLevelSuccessAction,
  DeleteLevelFailureAction,
} from '../types/level';

export const { Types: LevelTypes, Creators: LevelActions } = createActions<
  ILevelTypes,
  ILevelCreators
>({
  updateLevelRequest: ['levelId', 'data', 'callback'],
  updateLevelSuccess: ['level'],
  updateLevelFailure: ['error'],

  createLevelRequest: ['data', 'callback'],
  createLevelSuccess: ['level'],
  createLevelFailure: ['error'],

  deleteLevelRequest: ['levelId', 'callback'],
  deleteLevelSuccess: [],
  deleteLevelFailure: ['error'],
});

const INITIAL_STATE: LevelState = {
  level: {
    id: '',
    name: '',
    createdAt: '',
    updatedAt: '',
    rounds: 0,
    intro: [],
    mandatory: [],
    optional: [],
  },
  loading: false,
  loadingCreation: false,
  loadingDeletion: false,
  loadingUpdate: false,
};

const createLevelRequest = (state = INITIAL_STATE): LevelState => ({
  ...state,
  loadingCreation: true,
});

const createLevelSuccess = (
  state = INITIAL_STATE,
  { level }: CreateLevelSuccessAction
): LevelState => ({
  ...state,
  level,
  loadingCreation: false,
});

const createLevelFailure = (
  state = INITIAL_STATE,
  { error }: CreateLevelFailureAction
): LevelState => ({
  ...state,
  error,
  loadingCreation: false,
});

const updateLevelRequest = (state = INITIAL_STATE): LevelState => ({
  ...state,
  loadingUpdate: true,
});

const updateLevelSuccess = (
  state = INITIAL_STATE,
  { level }: UpdateLevelSuccessAction
): LevelState => ({
  ...state,
  level,
  loadingUpdate: false,
});

const updateLevelFailure = (
  state = INITIAL_STATE,
  { error }: UpdateLevelFailureAction
): LevelState => ({
  ...state,
  error,
  loadingUpdate: false,
});

const deleteLevelRequest = (state = INITIAL_STATE): LevelState => ({
  ...state,
  loadingDeletion: true,
});

const deleteLevelSuccess = (state = INITIAL_STATE): LevelState => ({
  ...state,
  loadingDeletion: false,
});

const deleteLevelFailure = (
  state = INITIAL_STATE,
  { error }: DeleteLevelFailureAction
): LevelState => ({
  ...state,
  error,
  loadingDeletion: false,
});

export default createReducer(INITIAL_STATE, {
  [LevelTypes.CREATE_LEVEL_REQUEST]: createLevelRequest,
  [LevelTypes.CREATE_LEVEL_SUCCESS]: createLevelSuccess,
  [LevelTypes.CREATE_LEVEL_FAILURE]: createLevelFailure,

  [LevelTypes.UPDATE_LEVEL_REQUEST]: updateLevelRequest,
  [LevelTypes.UPDATE_LEVEL_SUCCESS]: updateLevelSuccess,
  [LevelTypes.UPDATE_LEVEL_FAILURE]: updateLevelFailure,

  [LevelTypes.DELETE_LEVEL_REQUEST]: deleteLevelRequest,
  [LevelTypes.DELETE_LEVEL_SUCCESS]: deleteLevelSuccess,
  [LevelTypes.DELETE_LEVEL_FAILURE]: deleteLevelFailure,
});
