import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { Breadcrumbs, Link, Typography } from '@mui/material';

import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterLink, useParams } from 'react-router-dom';

import { ObjectString } from '~/types/object';

import { workoutsKeywords } from '~/helpers/pages/workouts';
import useTranslate from '~/hooks/useTranslate';
import useTypedSelector from '~/hooks/useTypedSelector';

const workoutMessagesId: ObjectString = {
  [workoutsKeywords.newWorkout]: 'workouts_breadcrumb_new',
};

const WorkoutDetailsBreadcrumb: React.FC = () => {
  const { workout } = useTypedSelector((state) => state.workout);

  const { workoutId } = useParams();
  const translate = useTranslate();

  const breadcrumbMessage = useMemo(() => {
    const messageId = workoutMessagesId[workoutId as string];
    return messageId ? translate(messageId) : workout?.title;
  }, [workoutId, workout]);

  return (
    <Breadcrumbs
      aria-label="navigation-breadcrumb"
      separator={<ChevronRightIcon />}
    >
      <Typography variant="body1" color="inherit">
        <FormattedMessage id="educational" />
      </Typography>

      <Link
        component={ReactRouterLink}
        underline="hover"
        color="inherit"
        to="/workouts"
      >
        <Typography variant="body1">
          <FormattedMessage id="workouts_breadcrumb" />
        </Typography>
      </Link>

      <Link
        component={ReactRouterLink}
        underline="hover"
        color="text.primary"
        to={`/workouts/${workoutId}`}
        aria-current="page"
      >
        <Typography variant="body1">{breadcrumbMessage}</Typography>
      </Link>
    </Breadcrumbs>
  );
};

export default WorkoutDetailsBreadcrumb;
