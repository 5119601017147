import {
  ExpandMore as ExpandMoreIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  Typography,
} from '@mui/material';

import React, { MouseEvent, useCallback } from 'react';

import userAvatar from '~/assets/images/user-avatar.jpg';

import useTranslate from '~/hooks/useTranslate';

import ClassCard from '~/components/ClassCard';

import { ChecksBox, LevelUserAvatar } from './styles';

interface Course {
  id: string;
  title: string;
  description: string;
  labels: string[];
}

interface Props {
  title: string;
  courses: Course[];
}

const LevelProgressAccordion: React.FC<Props> = ({ title, courses }) => {
  const translate = useTranslate();

  const handleLevelUserClick = useCallback(
    (event: MouseEvent<HTMLImageElement>) => {
      event.stopPropagation();
    },
    []
  );

  return (
    <Accordion disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="small" />}
        aria-controls="level-content"
      >
        <Typography variant="h6" sx={{ mr: 3 }}>
          {title}
        </Typography>

        <Chip label={`3 ${translate('course_rounds')}`} color="primary" />

        <ChecksBox>
          <CheckCircleOutlineIcon />
          <CheckCircleOutlineIcon color="disabled" />
          <CheckCircleOutlineIcon color="disabled" />
        </ChecksBox>

        <LevelUserAvatar onClick={handleLevelUserClick} src={userAvatar} />
      </AccordionSummary>

      <AccordionDetails>
        <Typography mt={1} mb={3}>
          Mandatory
        </Typography>

        <Grid container spacing={3}>
          {courses.map((course) => (
            <Grid key={course.id} item lg={14 / 3} md={7} xs={14}>
              <ClassCard
                title={course.title}
                description={course.description}
                labels={course.labels}
                sx={{ marginInline: 'auto' }}
              />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default LevelProgressAccordion;
