import yup from './default';

export const workoutEditValidationSchema = yup.object().shape({
  title: yup.string().required(),
  labels: yup.array().of(yup.string()).required().min(1),
  equipments: yup.array().of(yup.string()).required().min(1),
  trainers: yup.array().of(yup.string()).required(),
  description: yup.string().required(),
  video: yup.string().required(),
});
