import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  linearProgressClasses,
  Theme,
} from '@mui/material';

type MuiLinearProgress = {
  defaultProps?: ComponentsProps['MuiLinearProgress'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiLinearProgress'];
  variants?: ComponentsVariants['MuiLinearProgress'];
};

const styles = (theme: Theme): MuiLinearProgress => ({
  styleOverrides: {
    root: {
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
});

export default styles;
