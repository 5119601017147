import { useIntl } from 'react-intl';

import enTranslations from '~/intl/messages/en-US';

export type TranslationId =
  | keyof typeof enTranslations
  | (string & { zz_IGNORE_ME?: never });

export default function useTranslate() {
  const intl = useIntl();

  const translate = (
    messageId: TranslationId,
    params?: { [key: string]: string }
  ) => {
    return intl.formatMessage({ id: messageId }, params);
  };

  return translate;
}
