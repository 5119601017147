import { Box, MenuItem, styled } from '@mui/material';

export const MenuBox = styled(Box)(() => ({
  flexGrow: 'none',
}));

export const MenuItemStyled = styled(MenuItem)(() => ({
  '& > svg': {
    marginRight: '8px',
  },
}));
