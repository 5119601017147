import { createActions, createReducer } from 'reduxsauce';

import {
  IWorkoutCreators,
  IWorkoutTypes,
  LoadWorkoutSuccessAction,
  LoadVideoThumbnailFailureAction,
  UpdateWorkoutSuccessAction,
  WorkoutState,
  DeleteWorkoutFailureAction,
} from '../types/workout';

export const { Types: WorkoutTypes, Creators: WorkoutActions } = createActions<
  IWorkoutTypes,
  IWorkoutCreators
>({
  loadWorkoutRequest: ['workoutId'],
  loadWorkoutSuccess: ['workout'],
  loadWorkoutFailure: [],

  createWorkoutRequest: ['data', 'callback'],
  createWorkoutSuccess: [],
  createWorkoutFailure: [],

  updateWorkoutRequest: ['workoutId', 'data', 'callback'],
  updateWorkoutSuccess: ['workout'],
  updateWorkoutFailure: [],

  deleteWorkoutRequest: ['workoutId', 'callback'],
  deleteWorkoutSuccess: [],
  deleteWorkoutFailure: ['error'],

  loadVideoThumbnailRequest: ['videoId', 'callback'],
  loadVideoThumbnailSuccess: [],
  loadVideoThumbnailFailure: [],
});

const INITIAL_STATE: WorkoutState = {
  workout: null,
  loading: false,
  videoError: false,
  loadingCreation: false,
  loadingThumbnail: false,
  loadingUpload: false,
  loadingUpdate: false,
  loadingDelete: false,
};

// Load

const loadWorkoutRequest = (state = INITIAL_STATE): WorkoutState => ({
  ...state,
  loading: true,
});

const loadWorkoutSuccess = (
  state = INITIAL_STATE,
  action: LoadWorkoutSuccessAction
): WorkoutState => ({
  ...state,
  loading: false,
  workout: action.workout,
});

const loadWorkoutFailure = (state = INITIAL_STATE): WorkoutState => ({
  ...state,
  loading: false,
});

// Creation

const createWorkoutRequest = (state = INITIAL_STATE): WorkoutState => ({
  ...state,
  loadingCreation: true,
});

const createWorkoutSuccess = (state = INITIAL_STATE): WorkoutState => ({
  ...state,
  loadingCreation: false,
});

const createWorkoutFailure = (state = INITIAL_STATE): WorkoutState => ({
  ...state,
  loadingCreation: false,
});

// Update

const updateWorkoutRequest = (state = INITIAL_STATE): WorkoutState => ({
  ...state,
  loadingUpdate: true,
});

const updateWorkoutSuccess = (
  state = INITIAL_STATE,
  action: UpdateWorkoutSuccessAction
): WorkoutState => ({
  ...state,
  workout: action.workout,
  loadingUpdate: false,
});

const updateWorkoutFailure = (state = INITIAL_STATE): WorkoutState => ({
  ...state,
  loadingUpdate: false,
});

// Delete

const deleteWorkoutRequest = (state = INITIAL_STATE): WorkoutState => ({
  ...state,
  loadingDelete: true,
});

const deleteWorkoutSuccess = (state = INITIAL_STATE): WorkoutState => ({
  ...state,
  loadingDelete: false,
});

const deleteWorkoutFailure = (
  state = INITIAL_STATE,
  { error }: DeleteWorkoutFailureAction
): WorkoutState => ({
  ...state,
  error,
  loadingDelete: false,
});

const loadVideoThumbnailRequest = (state = INITIAL_STATE): WorkoutState => ({
  ...state,
  loadingThumbnail: true,
});

const loadVideoThumbnailSuccess = (state = INITIAL_STATE): WorkoutState => ({
  ...state,
  loadingThumbnail: false,
});

// Load Thumbnail

const loadVideoThumbnailFailure = (
  state = INITIAL_STATE,
  action: LoadVideoThumbnailFailureAction
): WorkoutState => ({
  ...state,
  videoError: action.error,
  loadingThumbnail: false,
});

export default createReducer(INITIAL_STATE, {
  [WorkoutTypes.LOAD_WORKOUT_REQUEST]: loadWorkoutRequest,
  [WorkoutTypes.LOAD_WORKOUT_SUCCESS]: loadWorkoutSuccess,
  [WorkoutTypes.LOAD_WORKOUT_FAILURE]: loadWorkoutFailure,

  [WorkoutTypes.CREATE_WORKOUT_REQUEST]: createWorkoutRequest,
  [WorkoutTypes.CREATE_WORKOUT_SUCCESS]: createWorkoutSuccess,
  [WorkoutTypes.CREATE_WORKOUT_FAILURE]: createWorkoutFailure,

  [WorkoutTypes.UPDATE_WORKOUT_REQUEST]: updateWorkoutRequest,
  [WorkoutTypes.UPDATE_WORKOUT_SUCCESS]: updateWorkoutSuccess,
  [WorkoutTypes.UPDATE_WORKOUT_FAILURE]: updateWorkoutFailure,

  [WorkoutTypes.DELETE_WORKOUT_REQUEST]: deleteWorkoutRequest,
  [WorkoutTypes.DELETE_WORKOUT_SUCCESS]: deleteWorkoutSuccess,
  [WorkoutTypes.DELETE_WORKOUT_FAILURE]: deleteWorkoutFailure,

  [WorkoutTypes.LOAD_VIDEO_THUMBNAIL_REQUEST]: loadVideoThumbnailRequest,
  [WorkoutTypes.LOAD_VIDEO_THUMBNAIL_SUCCESS]: loadVideoThumbnailSuccess,
  [WorkoutTypes.LOAD_VIDEO_THUMBNAIL_FAILURE]: loadVideoThumbnailFailure,
});
