import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MuiCssBaseline = {
  defaultProps?: ComponentsProps['MuiCssBaseline'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiCssBaseline'];
  variants?: ComponentsVariants['MuiCssBaseline'];
};

const styles = ({ palette }: Theme): MuiCssBaseline => ({
  styleOverrides: {
    '& .MuiOutlinedInput-root fieldset': {
      borderColor: palette.grey[400],
    },
    '&::-webkit-scrollbar': {
      width: '8px',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '8px',
      background: palette.grey[400],
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: palette.grey[600],
    },

    '&::-webkit-scrollbar-thumb:hover': {
      background: palette.grey[700],
    },
  },
});

export default styles;
