import { Components, Theme } from '@mui/material';

import MuiAccordion from './MuiAccordion';
import MuiButton from './MuiButton';
import MuiChip from './MuiChip';
import MuiCssBaseline from './MuiCssBaseline';
import MuiGrid from './MuiGrid';
import MuiInput from './MuiInput';
import MuiLinearProgress from './MuiLinearProgress';
import MuiModal from './MuiModal';
import MuiSwitch from './MuiSwitch';
import MuiTable from './MuiTable';
import MuiTabs from './MuiTabs';
import MuiTextField from './MuiTextField';

const components = (theme: Theme): Components<Theme> => ({
  MuiAccordion: MuiAccordion(theme),
  MuiButton: MuiButton(theme),
  MuiChip: MuiChip(theme),
  MuiCssBaseline: MuiCssBaseline(theme),
  MuiGrid: MuiGrid(theme),
  MuiInput: MuiInput(theme),
  MuiLinearProgress: MuiLinearProgress(theme),
  MuiModal: MuiModal(theme),
  MuiSwitch: MuiSwitch(theme),
  MuiTable: MuiTable(theme),
  MuiTabs: MuiTabs(theme),
  MuiTextField: MuiTextField(theme),
});

export default components;
