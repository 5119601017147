import { ContainerProps } from '@mui/material';

import React from 'react';

import { TabPanelContainerStyled } from './style';

type Props = ContainerProps;

const TabPanelContainer: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <TabPanelContainerStyled maxWidth="md" {...rest}>
      {children}
    </TabPanelContainerStyled>
  );
};

export default TabPanelContainer;
