import { User } from '~/store/types/session';

interface LoginProps {
  user: User;
  token: string;
  rememberMe: boolean;
}

const STORAGE = 'valana';
const STORAGE_USER = 'valana.user';
const STORAGE_TOKEN = 'valana.token';
const STORAGE_THEME = 'valana.theme';

// utils

function formatUser(userString: string | null) {
  const user = JSON.parse(userString || 'null');
  return user as User | null;
}

function getUserPreferedTheme() {
  let defaultTheme = 'light';

  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    defaultTheme = 'dark';
  }

  return defaultTheme;
}

// handler functions

function getStorage() {
  const isLocalStorage = Boolean(localStorage.getItem(STORAGE));

  if (isLocalStorage) {
    return localStorage;
  }

  return sessionStorage;
}

function getStorageData(storage: Storage) {
  const localUser = storage.getItem(STORAGE_USER);
  const user = formatUser(localUser);

  const preferedTheme = getUserPreferedTheme();
  const theme = storage.getItem(STORAGE_THEME) || preferedTheme;

  const token = storage.getItem(STORAGE_TOKEN);

  return {
    user,
    token,
    theme,
  };
}

function setStorageData(storage: Storage, user: User, token: string) {
  storage.setItem(STORAGE, String(true));

  storage.setItem(STORAGE_USER, JSON.stringify(user));
  storage.setItem(STORAGE_TOKEN, token);
}

function cleanStorageData(storage: Storage) {
  storage.removeItem(STORAGE);
  storage.removeItem(STORAGE_TOKEN);
  storage.removeItem(STORAGE_USER);
  storage.removeItem(STORAGE_THEME);
}

//

export function getSessionData() {
  const storage = getStorage();
  const storageData = getStorageData(storage);

  return storageData;
}

export function logout() {
  cleanStorageData(localStorage);
  cleanStorageData(sessionStorage);
}

export function login(props: LoginProps) {
  const { user, token, rememberMe } = props;
  const storage = rememberMe ? localStorage : sessionStorage;

  setStorageData(storage, user, token);
}
