type PathPage = {
  [key: string]: {
    name: string;
  };
};

const pathPageBridge: PathPage = {
  '/': {
    name: 'dashboard',
  },
  '/users': {
    name: 'users',
  },
  '/payments': {
    name: 'payments',
  },
  '/coupons': {
    name: 'coupons',
  },
  '/settings': {
    name: 'settings',
  },
  '/course': {
    name: 'course',
  },
  '/workouts': {
    name: 'workouts',
  },
  '/moms': {
    name: 'moms',
  },
  '/users/profile': {
    name: 'profile',
  },
  '/users/clients/profile': {
    name: 'client_profile',
  },
  '/coupons/new-coupon': {
    name: 'new_coupon',
  },
};

export function getPathWithoutParams(path: string) {
  const pathWithoutParams = path.substring(0, path.lastIndexOf('/'));
  return pathWithoutParams;
}

export function isSubpath(
  fullPath: string,
  href: string,
  ignoreRoot?: boolean
) {
  if (ignoreRoot && href === '/') {
    return false;
  }

  const path = getPathWithoutParams(fullPath);
  return path.includes(href);
}

export function getPageDataBasedOnPath(path: string) {
  try {
    const pathWithoutParams = getPathWithoutParams(path);

    const exactPageData = pathPageBridge[path];
    const subPageData = pathPageBridge[pathWithoutParams];

    return exactPageData || subPageData;
  } catch (error) {
    return null;
  }
}
