import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { workoutsKeywords } from '~/helpers/pages/workouts';
import { workoutEditValidationSchema } from '~/helpers/yup';
import useTypedSelector from '~/hooks/useTypedSelector';

import { WorkoutActions } from '~/store/ducks/workout';
import { WorkoutsActions } from '~/store/ducks/workouts';
import { WorkoutCreation, WorkoutFormUp } from '~/store/types/workout';

import CircularLoading from '~/components/CircularLoading';
import { Form } from '~/components/FormComponents';
import { PageContainerBottom } from '~/components/PageContainer';

import WorkoutDetailsBreadcrumb from './components/WorkoutDetailsBreadcrumb';
import WorkoutForm from './components/WorkoutForm';
import WorkoutFormFooter from './components/WorkoutFormFooter';
import { WorkoutFormContainer } from './styles';

const WorkoutDetails: React.FC = () => {
  const methods = useForm({
    resolver: yupResolver(workoutEditValidationSchema),
  });

  const {
    loading,
    loadingCreation,
    loadingUpload,
    loadingUpdate,
    loadingDelete,
  } = useTypedSelector((state) => state.workout);

  const { workoutId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const callback = () => {
    dispatch(WorkoutsActions.loadWorkoutsRequest());
    navigate('/workouts');
  };
  const handleSubmit = useCallback(
    (data: WorkoutFormUp) => {
      const isToCreate = workoutId === workoutsKeywords.newWorkout;
      const splitLink = data.video.split('/');
      const idVideo = splitLink[splitLink.length - 1];

      const newData: WorkoutCreation = {
        ...data,
        video: {
          id: idVideo,
          link: data.video,
        },
      };

      if (isToCreate) {
        dispatch(WorkoutActions.createWorkoutRequest(newData, callback));
        return;
      }

      dispatch(
        WorkoutActions.updateWorkoutRequest(
          workoutId as string,
          newData,
          callback
        )
      );
    },
    [dispatch, navigate]
  );

  const handleDeleteWorkout = useCallback(() => {
    if (workoutId) {
      dispatch(WorkoutActions.deleteWorkoutRequest(workoutId, callback));
    }
  }, [dispatch]);

  useEffect(() => {
    if (workoutId !== workoutsKeywords.newWorkout) {
      dispatch(WorkoutActions.loadWorkoutRequest(workoutId || ''));
    }
  }, [dispatch]);

  if (loading) {
    return <CircularLoading />;
  }

  return (
    <PageContainerBottom>
      <WorkoutDetailsBreadcrumb />

      <WorkoutFormContainer>
        <Form onSubmit={handleSubmit} {...methods}>
          <WorkoutForm />

          <WorkoutFormFooter
            sx={{ mt: '48px' }}
            loadingSave={loadingCreation || loadingUpdate || loadingUpload}
            loadingDelete={loadingDelete}
            deleteMessageId="workout_delete"
            onDeleteWorkout={handleDeleteWorkout}
          />
        </Form>
      </WorkoutFormContainer>
    </PageContainerBottom>
  );
};

export default WorkoutDetails;
