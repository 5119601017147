import { combineReducers } from 'redux';

import coupons from './coupon';
import course from './course';
import flashMessage from './flashMessage';
import image from './image';
import level from './level';
import session from './session';
import user from './user';
import users from './users';
import workout from './workout';
import workouts from './workouts';

export default combineReducers({
  flashMessage,
  course,
  coupons,
  level,
  session,
  user,
  users,
  workout,
  workouts,
  image,
});
