import { Visibility as VisibilityIcon } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getSortOptionOnHandle } from '~/helpers/table/pagination';
import useTypedSelector from '~/hooks/useTypedSelector';

import { UsersActions } from '~/store/ducks/users';

import {
  LimitChangeProps,
  TableHeadSort,
  TableHeadOnSortItem,
  TableHeadSortItem,
  TablePagination,
} from '~/components/TableComponents';

const tableHeadItems: TableHeadSortItem[] = [
  { id: 'avatar' },
  { id: 'name', labelId: 'payments_users_name' },
  { id: 'email', labelId: 'payments_users_email' },
  { id: 'date', labelId: 'payments_date', disableSort: true },
  { id: 'status', labelId: 'payments_status', disableSort: true },
  { id: 'actions' },
];

const PaymentsTable: React.FC = () => {
  const { clients, options } = useTypedSelector((state) => state.users.clients);
  const { purchases } = useTypedSelector((state) => state.users);

  const dispatch = useDispatch();

  const handlePaginationPageChange = useCallback(
    (newPage: number) => {
      const newOptions = {
        page: newPage,
      };

      dispatch(UsersActions.updateClientsOptions(newOptions));
    },
    [dispatch]
  );

  const handlePaginationLimitChange = useCallback(
    (props: LimitChangeProps) => {
      dispatch(UsersActions.updateClientsOptions(props));
    },
    [dispatch]
  );

  const handleTableSort = useCallback(
    (item: TableHeadOnSortItem) => {
      if (item.id === 'status') {
        // eslint-disable-next-line no-console
        console.log('The part of sort by status is not implemented on backend');

        return;
      }

      const newOptions = getSortOptionOnHandle(options, item.id);
      dispatch(UsersActions.updateClientsOptions(newOptions));
    },
    [dispatch, options]
  );

  const getPaymentDate = useCallback(
    (client) => {
      const date = purchases.find(
        (purchase) => client.id === purchase.userId
      )?.createTime;
      return date ? new Date(date).toLocaleDateString() : '-';
    },
    [purchases]
  );

  // const handlePaymentDetails = useCallback((client) => {
  //   const purchaseId = purchases.find(
  //     (purchase) => client.id === purchase.userId
  //   )?.createTime;
  //   console.log(purchaseId);
  //   navigate(`/payments/${purchaseId}`);
  // }, []);

  return (
    <>
      <Table aria-label="payments table">
        <TableHeadSort
          items={tableHeadItems}
          sort={options.sort}
          direction={options.direction}
          onSort={handleTableSort}
        />
        <TableBody>
          {clients.map((client) => (
            <TableRow key={client.id}>
              <TableCell>
                <Avatar
                  placeholder={client.name}
                  sx={{ background: grey[400] }}
                >
                  {client.name.substring(0, 1)}
                </Avatar>
              </TableCell>

              <TableCell>{client.name}</TableCell>

              <TableCell>{client.email}</TableCell>

              <TableCell>{getPaymentDate(client)}</TableCell>

              <TableCell align="right">
                {/* <IconButton onClick={() => handlePaymentDetails(client)}>
                  <VisibilityIcon />
                </IconButton> */}
                <IconButton>
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        options={options}
        onPageChange={handlePaginationPageChange}
        onLimitChange={handlePaginationLimitChange}
      />
    </>
  );
};

export default PaymentsTable;
