import { ObjectString } from '~/types/object';

import theme from '~/theme';

import { getProgressStyle } from './progress';

const statusColors: ObjectString = {
  active: theme.palette.success.main,
  expired: theme.palette.error.main,
};

const roleColors: ObjectString = {
  admin: theme.palette.info.main,
};

export function getUserRoleIntl(slug: string) {
  return `users_team_role_${slug}`;
}

export function getUserRoleColor(slug: string) {
  return roleColors[slug];
}

// client

export function getClientStatusIntl(slug: string) {
  return `users_clients_status_${slug}`;
}

export function getClientStatusColor(slug: string) {
  const color = statusColors[slug];
  return color;
}

export { getProgressStyle };
