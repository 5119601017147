import { Grid, Stack, Typography } from '@mui/material';

import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { profileKeywords } from '~/helpers/pages/profile';
import useTranslate from '~/hooks/useTranslate';
import useTypedSelector from '~/hooks/useTypedSelector';

import { UserUpdate } from '~/store/types/user';

import ProfileAvatar from '~/components/ProfileAvatar';
import RhfFilterAutocomplete from '~/components/RhfComponents/RhfFilterAutocomplete';
import RhfTextField from '~/components/RhfComponents/RhfTextField';
import RhfTextFieldPassword from '~/components/RhfComponents/RhfTextFieldPassword';

const userRoles = [
  {
    labelId: 'users_role_admin',
    value: 'admin',
  },
];

const GeneralForm: React.FC = () => {
  const { user } = useTypedSelector((state) => state.user);

  const { userId } = useParams();
  const translate = useTranslate();

  const {
    reset,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<UserUpdate>();

  const avatarSrc = useMemo(() => {
    const isToCreateUser = userId === profileKeywords.newUser;
    const userImageProfile = user?.image ? user.image.url : '';

    return isToCreateUser ? undefined : userImageProfile;
  }, [userId]);

  const handleImageChange = useCallback(
    (imageData) => {
      setValue('image', imageData);
    },
    [setValue]
  );

  useEffect(() => {
    if (userId !== profileKeywords.newUser && user) {
      const dataToReset: UserUpdate = {
        name: user.name,
        email: user.email,
        role: user.role,
      };

      reset(dataToReset);
    }
  }, [reset, user]);

  return (
    <Stack rowGap={2} direction="column">
      <ProfileAvatar
        alt="User"
        defaultImageSrc={avatarSrc}
        onImageChange={handleImageChange}
      />

      <Typography variant="subtitle1">
        <FormattedMessage id="users_profile_section_general" />
      </Typography>

      <Grid container spacing={3}>
        <Grid item md={7} xs={14}>
          <RhfTextField
            name="name"
            control={control}
            error={errors.name}
            fullWidth
            label={translate('users_profile_name')}
            placeholder={translate('users_profile_name')}
          />
        </Grid>

        <Grid item md={7} xs={14}>
          <RhfTextField
            name="email"
            control={control}
            error={errors.email}
            fullWidth
            label={translate('users_profile_email')}
            placeholder={translate('users_profile_email')}
          />
        </Grid>

        <Grid item md={7} xs={14}>
          <RhfTextFieldPassword
            name="password"
            control={control}
            error={errors.password}
            fullWidth
            label={translate('users_profile_new_password')}
            placeholder={translate('users_profile_new_password')}
          />
        </Grid>

        <Grid item md={7} xs={14}>
          <RhfFilterAutocomplete
            name="role"
            control={control}
            error={errors.role}
            labelId="users_profile_select_role"
            optionsLabelsIds={userRoles}
            defaultValue={userRoles[0]}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default GeneralForm;
