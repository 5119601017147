import { Box, styled } from '@mui/material';

export const DashboardContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const PageContent = styled(Box)(() => ({
  padding: '0 24px',
}));
