import { Pagination, PaginationOptions } from '~/store/types/pagination';

import { defaultTableOptions } from './state';

interface Options {
  page: number;
  limit: number;
  newLimit: number;
}

function getNewPageOnLimitIncrease(props: Options) {
  const { page, limit, newLimit } = props;

  const newPageDecimal = (page * limit) / newLimit;
  const newPage = Math.ceil(newPageDecimal);

  return newPage;
}

function getNewPageOnLimitDecrease(props: Options) {
  const { page, limit, newLimit } = props;

  const ratio = Math.ceil(newLimit / limit);

  if (ratio === 1) {
    return 1;
  }

  return ratio * page;
}

export function getNewPageBasedOnLimit(props: Options) {
  const { limit, newLimit } = props;

  if (newLimit > limit) {
    const newPage = getNewPageOnLimitIncrease(props);
    return newPage;
  }

  const newPage = getNewPageOnLimitDecrease(props);
  return newPage;
}

export function getSortOptionOnHandle(
  options: Pagination,
  sort: string
): PaginationOptions {
  if (options.sort === sort) {
    return {
      sort,
      direction: options.direction === 'asc' ? 'desc' : 'asc',
    };
  }

  return {
    sort,
    direction: defaultTableOptions.sort.direction,
  };
}
