import { Settings as SettingsIcon } from '@mui/icons-material';

import { useState } from 'react';

import { Course } from '~/store/types/course';

import ConfigurationCourseModal from '../ConfigurationCourseModal';
import { Banner, Gradient, SettingsButtonStyles, Title } from './styles';

type CourseBannerProps = {
  course: Course;
};

const CourseBanner = ({ course }: CourseBannerProps) => {
  const [openConfigurationCourseModal, setOpenConfigurationCourseModal] =
    useState(false);

  const handleOpenConfigurationCourseModal = () =>
    setOpenConfigurationCourseModal(true);
  const handleCloseConfigurationCourseModal = () =>
    setOpenConfigurationCourseModal(false);

  return (
    <>
      <ConfigurationCourseModal
        openModal={openConfigurationCourseModal}
        onClose={handleCloseConfigurationCourseModal}
        course={course}
        aria-labelledby="course-banner-modal"
        aria-describedby="course-banner-modal"
      />

      <Banner sx={{ backgroundImage: `url(${course.image?.url})` }}>
        <SettingsButtonStyles onClick={handleOpenConfigurationCourseModal}>
          <SettingsIcon />
        </SettingsButtonStyles>

        <Gradient />
        <Title variant="h4">{course.name}</Title>
      </Banner>
    </>
  );
};

export default CourseBanner;
