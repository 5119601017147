import { AxiosResponse } from 'axios';
import { first } from 'lodash';
import { takeLatest, put, call, debounce, select } from 'redux-saga/effects';

import { ApiError } from '~/types/request';

import { getErrorIdFromResponse } from '~/helpers/api';
import api from '~/services/api';

import { CourseTypes, CourseActions } from '../ducks/course';
import { FlashMessageActions } from '../ducks/flashMessage';
import { ApplicationState } from '../types';
import {
  Course,
  MoveCourseLevelWorkoutAction,
  UpdateCourseRequestAction,
} from '../types/course';
import { Level } from '../types/level';
import { SortWorkoutsItem, Workout } from '../types/workouts';

type CourseResponse = AxiosResponse<Course>;
type CoursesResponse = AxiosResponse<Course[]>;
type FirstCourseResponse = AxiosResponse<Course[]>;

const levelsSelector = ({ course }: ApplicationState) => course.course.levels;

function* courseRequest() {
  try {
    const response: FirstCourseResponse = yield call(api.get, '/courses');

    const course = response.data[0];

    yield put(CourseActions.getCourseSuccess(course));
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response);

    yield put(CourseActions.getCourseFailure(errorId));
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* updateCourseRequest({ data, callback }: UpdateCourseRequestAction) {
  try {
    const { data: courses }: CoursesResponse = yield call(api.get, '/courses');
    const course = first(courses)?.id;
    const response: CourseResponse = yield call(
      api.put,
      `/courses/${course}`,
      data
    );

    yield put(
      FlashMessageActions.showMessage({
        id: 'course_banner_update_success',
        variant: 'success',
      })
    );

    yield put(CourseActions.updateCourseSuccess(response.data));
    callback();
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(
      response,
      'course_banner_update_failure'
    );

    yield put(CourseActions.updateCourseFailure(errorId));
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* updateSort({
  levelId,
  workoutLevelType,
}: MoveCourseLevelWorkoutAction) {
  try {
    const levels: Level[] = yield select(levelsSelector);

    const workoutsLevels: SortWorkoutsItem[] = levels.reduce(
      (prev: SortWorkoutsItem[], curr: Level): SortWorkoutsItem[] => {
        if (curr.id === levelId) {
          return curr[workoutLevelType].map(
            (workout: Workout, index: number): SortWorkoutsItem => {
              return {
                workoutLevelId: workout.workoutLevelId,
                order: index + 1,
              };
            }
          );
        }
        return prev;
      },
      []
    );

    yield call(api.put, `/levels/${levelId}/sort`, {
      workoutsLevels,
    });
  } catch {
    yield put(FlashMessageActions.showMessage({ id: 'default_error' }));
  }
}

export default function* sagas() {
  yield takeLatest(CourseTypes.GET_COURSE_REQUEST, courseRequest);
  yield takeLatest(CourseTypes.UPDATE_COURSE_REQUEST, updateCourseRequest);
  yield debounce(2000, CourseTypes.MOVE_COURSE_LEVEL_WORKOUT, updateSort);
}
