import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ApiError, ApiResponse } from '~/types/request';

import { getErrorIdFromResponse } from '~/helpers/api';
import api from '~/services/api';

import { FlashMessageActions } from '../ducks/flashMessage';
import { WorkoutActions, WorkoutTypes } from '../ducks/workout';
import {
  CreateWorkoutRequestAction,
  DeleteWorkoutRequestAction,
  LoadVideoThumbnailRequestAction,
  LoadWorkoutRequestAction,
  UpdateWorkoutRequestAction,
} from '../types/workout';
import { Workout } from '../types/workouts';

type VimeoPictures = {
  data: {
    sizes: {
      width: number;
      height: number;
      link: string;
    }[];
  }[];
};

type WorkoutResponse = ApiResponse<Workout>;
type VimeoPicturesResponse = ApiResponse<VimeoPictures>;

function* loadWorkoutRequest({ workoutId }: LoadWorkoutRequestAction) {
  try {
    const response: WorkoutResponse = yield call(
      api.get,
      `/workouts/${workoutId}`
    );

    const workout = response.data;

    yield put(WorkoutActions.loadWorkoutSuccess(workout));
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'workout_load_failure');

    yield put(WorkoutActions.loadWorkoutFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* createWorkoutRequest({ data, callback }: CreateWorkoutRequestAction) {
  try {
    yield call(api.post, '/workouts', data);

    yield put(
      FlashMessageActions.showMessage({
        id: 'workout_creation_success',
        variant: 'success',
      })
    );

    yield put(WorkoutActions.createWorkoutSuccess());

    callback();
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(
      response,
      'workout_creation_failure'
    );

    yield put(WorkoutActions.createWorkoutFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* updateWorkoutRequest({
  workoutId,
  data,
  callback,
}: UpdateWorkoutRequestAction) {
  try {
    const response: WorkoutResponse = yield call(
      api.put,
      `/workouts/${workoutId}`,
      data
    );

    yield put(
      FlashMessageActions.showMessage({
        id: 'workout_update_success',
        variant: 'success',
      })
    );

    yield put(WorkoutActions.updateWorkoutSuccess(response.data));

    callback();
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'workout_update_failure');

    yield put(WorkoutActions.updateWorkoutFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* deleteWorkoutRequest({
  workoutId,
  callback,
}: DeleteWorkoutRequestAction) {
  try {
    yield call(api.delete, `/workouts/${workoutId}`);

    yield put(WorkoutActions.deleteWorkoutSuccess());
    yield put(
      FlashMessageActions.showMessage({
        id: 'workout_delete_success',
        variant: 'success',
      })
    );

    if (callback) {
      callback();
    }
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'workout_delete_failure');

    yield put(WorkoutActions.deleteWorkoutFailure(errorId));
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* loadVideoThumbnail({
  videoId,
  callback,
}: LoadVideoThumbnailRequestAction) {
  try {
    const { data: response }: VimeoPicturesResponse = yield call(
      axios.get,
      `https://api.vimeo.com/videos/${videoId}/pictures?sizes=850x200`,
      {
        headers: {
          Accept: 'application/vnd.vimeo.*+json;version=3.4',
          Authorization: `Bearer ${process.env.REACT_APP_VIMEO_TOKEN}`,
        },
      }
    );

    yield put(WorkoutActions.loadVideoThumbnailSuccess());

    callback(response.data[0].sizes[0].link);
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(response, 'workout_load_failure');

    yield put(WorkoutActions.loadVideoThumbnailFailure(true));
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

export default function* sagas() {
  yield takeLatest(WorkoutTypes.LOAD_WORKOUT_REQUEST, loadWorkoutRequest);

  yield takeLatest(WorkoutTypes.CREATE_WORKOUT_REQUEST, createWorkoutRequest);
  yield takeLatest(WorkoutTypes.UPDATE_WORKOUT_REQUEST, updateWorkoutRequest);
  yield takeLatest(WorkoutTypes.DELETE_WORKOUT_REQUEST, deleteWorkoutRequest);

  yield takeLatest(
    WorkoutTypes.LOAD_VIDEO_THUMBNAIL_REQUEST,
    loadVideoThumbnail
  );
}
