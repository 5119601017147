import {
  ExpandMore as ExpandMoreIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useTranslate from '~/hooks/useTranslate';

import { CourseActions } from '~/store/ducks/course';
import { WorkoutsActions } from '~/store/ducks/workouts';
import { Level, WorkoutLevelType } from '~/store/types/level';
import { Workout } from '~/store/types/workouts';

import ClassCard from '~/components/ClassCard';
import DeleteModal, { DeleteModalRef } from '~/components/Modals/DeleteModal';
import WorkoutModal, {
  WorkoutModalRef,
} from '~/components/Modals/WorkoutModal';

import LevelModal from '../LevelModal';
import NewWorkoutModal from '../NewWorkoutModal';
import AddWorkoutCard from './components/AddWorkoutCard';

interface Props {
  level: Level;
}

const LevelAccordion: React.FC<Props> = ({ level }) => {
  const { name, rounds, intro, mandatory, optional } = level;
  const translate = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workoutModalRef = useRef<WorkoutModalRef>(null);
  const deleteModalRef = useRef<DeleteModalRef>(null);

  const [selectedWorkout, setSelectedWorkout] = useState<Workout | null>(null);
  const [selectedWorkoutDelete, setSelectedWorkoutDelete] =
    useState<Workout | null>(null);

  const [openLevelModal, setOpenLevelModal] = useState(false);
  const [openNewWorkoutModal, setOpenNewWorkoutModal] = useState<
    WorkoutLevelType | undefined
  >(undefined);

  const handleOpenLevelModal = () => setOpenLevelModal(true);
  const handleCloseLevelModal = () => setOpenLevelModal(false);

  const handleOpenNewWorkoutModal = (type: WorkoutLevelType) => {
    dispatch(WorkoutsActions.updateWorkoutsOptions({ search: '' }));
    dispatch(WorkoutsActions.updateWorkoutsOptions({ labels: [] }));
    setOpenNewWorkoutModal(type);
  };
  const handleCloseNewWorkoutModal = () => setOpenNewWorkoutModal(undefined);

  const updateCourse = useCallback(() => {
    dispatch(CourseActions.getCourseRequest(true));
  }, [dispatch]);

  const handleEditWorkout = useCallback((workout: Workout) => {
    navigate(`/workouts/${workout.id}`);
  }, []);

  const handleGetDeleteWorkout = useCallback(
    (workout: Workout) => {
      deleteModalRef.current?.handleOpen();
      setSelectedWorkoutDelete(workout);
    },
    [selectedWorkoutDelete]
  );

  const handleDeleteWorkout = useCallback(() => {
    if (selectedWorkoutDelete) {
      dispatch(
        WorkoutsActions.deleteWorkoutLevelRequest(
          selectedWorkoutDelete.workoutLevelId || '',
          updateCourse
        )
      );
      deleteModalRef.current?.handleClose();
    }
  }, [selectedWorkoutDelete, updateCourse]);

  const handlePlayWorkoutVideo = useCallback((workout: Workout) => {
    setSelectedWorkout(workout);
  }, []);

  const handleLevelSettings = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      handleOpenLevelModal();
      event.stopPropagation();
    },
    []
  );

  const handleMoveWorkoutBack = useCallback(
    (type: WorkoutLevelType, workout: Workout) => {
      dispatch(
        CourseActions.moveCourseLevelWorkout(level.id, workout.id, type, 'up')
      );
    },
    [level]
  );

  const handleMoveWorkoutForward = useCallback(
    (type: WorkoutLevelType, workout: Workout) => {
      dispatch(
        CourseActions.moveCourseLevelWorkout(level.id, workout.id, type, 'down')
      );
    },
    [level]
  );

  useEffect(() => {
    workoutModalRef.current?.handleOpen();
  }, [selectedWorkout]);

  useEffect(() => {
    dispatch(WorkoutsActions.loadWorkoutsRequest());
  }, [dispatch]);

  return (
    <>
      <NewWorkoutModal
        openModal={Boolean(openNewWorkoutModal)}
        type={openNewWorkoutModal || 'mandatory'}
        onClose={handleCloseNewWorkoutModal}
        level={level}
      />
      <LevelModal
        open={openLevelModal}
        onClose={handleCloseLevelModal}
        level={level}
      />

      <DeleteModal
        ref={deleteModalRef}
        onConfirm={handleDeleteWorkout}
        // loadingDeletion={loadingDeletion}
      />

      {selectedWorkout && (
        <WorkoutModal
          videoId={selectedWorkout.video.id}
          name={selectedWorkout.title}
          description={selectedWorkout.description}
          ref={workoutModalRef}
        />
      )}

      <Accordion disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="small" />}
          aria-controls="level-content"
        >
          <Typography variant="h6" sx={{ mr: 3 }}>
            {name}
          </Typography>

          <Chip
            label={`${rounds} ${translate('course_rounds')}`}
            color="primary"
          />

          <Tooltip title={translate('settings')}>
            <IconButton
              onClick={handleLevelSettings}
              sx={{ marginLeft: 'auto' }}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </AccordionSummary>

        <AccordionDetails>
          <Typography mt={1} mb={3}>
            <FormattedMessage id="course_level_intro" />
          </Typography>

          <Grid container spacing={3}>
            {intro?.map((workout, index) => (
              <Grid
                key={workout.id}
                item
                lg={14 / 3}
                md={14 / 3}
                sm={7}
                xs={12}
              >
                <ClassCard
                  thumbnail={workout.image.url}
                  title={workout.title}
                  description={workout.description}
                  labels={workout.labels}
                  onMoveBack={
                    index !== 0
                      ? () => handleMoveWorkoutBack('intro', workout)
                      : undefined
                  }
                  onMoveForward={
                    index !== intro.length - 1
                      ? () => handleMoveWorkoutForward('intro', workout)
                      : undefined
                  }
                  onEdit={() => handleEditWorkout(workout)}
                  onDelete={() => handleGetDeleteWorkout(workout)}
                  onPlayVideo={() => handlePlayWorkoutVideo(workout)}
                />
              </Grid>
            ))}

            <Grid item lg={14 / 3} md={14 / 3} sm={7} xs={12}>
              <AddWorkoutCard
                onAdd={() => handleOpenNewWorkoutModal('intro')}
              />
            </Grid>
          </Grid>
        </AccordionDetails>

        <AccordionDetails>
          <Typography mt={1} mb={3}>
            <FormattedMessage id="course_level_mandatory" />
          </Typography>

          <Grid container spacing={3}>
            {mandatory?.map((workout, index) => (
              <Grid
                key={workout.id}
                item
                lg={14 / 3}
                md={14 / 3}
                sm={7}
                xs={12}
              >
                <ClassCard
                  thumbnail={workout.image.url}
                  title={workout.title}
                  description={workout.description}
                  labels={workout.labels}
                  onMoveBack={
                    index !== 0
                      ? () => handleMoveWorkoutBack('mandatory', workout)
                      : undefined
                  }
                  onMoveForward={
                    index !== mandatory.length - 1
                      ? () => handleMoveWorkoutForward('mandatory', workout)
                      : undefined
                  }
                  onEdit={() => handleEditWorkout(workout)}
                  onDelete={() => handleGetDeleteWorkout(workout)}
                  onPlayVideo={() => handlePlayWorkoutVideo(workout)}
                />
              </Grid>
            ))}

            <Grid item lg={14 / 3} md={14 / 3} sm={7} xs={12}>
              <AddWorkoutCard
                onAdd={() => handleOpenNewWorkoutModal('mandatory')}
              />
            </Grid>
          </Grid>
        </AccordionDetails>

        <AccordionDetails>
          <Typography mt={1} mb={3}>
            <FormattedMessage id="course_level_optional" />
          </Typography>

          <Grid container spacing={3}>
            {optional?.map((workout, index) => (
              <Grid
                key={workout.id}
                item
                lg={14 / 3}
                md={14 / 3}
                sm={7}
                xs={12}
              >
                <ClassCard
                  thumbnail={workout.image.url}
                  title={workout.title}
                  description={workout.description}
                  labels={workout.labels}
                  onMoveBack={
                    index !== 0
                      ? () => handleMoveWorkoutBack('optional', workout)
                      : undefined
                  }
                  onMoveForward={
                    index !== optional.length - 1
                      ? () => handleMoveWorkoutForward('optional', workout)
                      : undefined
                  }
                  onEdit={() => handleEditWorkout(workout)}
                  onDelete={() => handleGetDeleteWorkout(workout)}
                  onPlayVideo={() => handlePlayWorkoutVideo(workout)}
                />
              </Grid>
            ))}

            <Grid item lg={14 / 3} md={14 / 3} sm={7} xs={12}>
              <AddWorkoutCard
                onAdd={() => handleOpenNewWorkoutModal('optional')}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default LevelAccordion;
