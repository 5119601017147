import { AppBar, Container, styled, Toolbar } from '@mui/material';

export const AppBarStyled = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  boxShadow: 'none',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
}));

export const AppBarContainer = styled(Container)(() => ({
  marginTop: '48px',
}));

export const AppBarToolbar = styled(Toolbar)(() => ({
  justifyContent: 'space-between',

  paddingBottom: '20px',
}));
