import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { signInValidationSchema } from '~/helpers/yup';
import useTranslate from '~/hooks/useTranslate';
import useTypedSelector from '~/hooks/useTypedSelector';

import { SessionActions } from '~/store/ducks/session';
import { LoginCredentials } from '~/store/types/session';

import RhfFormControlLabel from '~/components/RhfComponents/RhfFormControlLabel';
import RhfTextField from '~/components/RhfComponents/RhfTextField';
import RhfTextFieldPassword from '~/components/RhfComponents/RhfTextFieldPassword';

import { SignInFormRoot, WelcomeText } from './styles';

type SignInFormValues = LoginCredentials;

const SignInForm: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>({
    resolver: yupResolver(signInValidationSchema),
  });

  const { loading } = useTypedSelector((state) => state.session);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslate();

  const handleFormSubmit = useCallback(
    (data: SignInFormValues) => {
      dispatch(
        SessionActions.loginRequest(data, () => {
          navigate('/');
        })
      );
    },
    [dispatch, navigate]
  );

  return (
    <SignInFormRoot onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={14}>
          <WelcomeText variant="h6">{translate('signin_title')}</WelcomeText>
        </Grid>

        <Grid item xs={14}>
          <RhfTextField
            error={errors?.email}
            fullWidth
            disabled={loading}
            variant="standard"
            name="email"
            type="text"
            control={control}
            label={translate('signin_field_email')}
          />
        </Grid>

        <Grid item xs={14}>
          <RhfTextFieldPassword
            error={errors?.password}
            fullWidth
            disabled={loading}
            variant="standard"
            name="password"
            control={control}
            label={translate('signin_field_password')}
          />
        </Grid>

        <Grid item xs={14}>
          <RhfFormControlLabel
            name="rememberMe"
            control={control}
            error={errors?.rememberMe}
            defaultChecked
            label={translate('signin_remember_me')}
            sx={{ width: '100%', marginTop: '-17px' }}
          />
        </Grid>

        <Grid item xs={14}>
          <LoadingButton
            loading={loading}
            variant="contained"
            fullWidth
            color="primary"
            type="submit"
          >
            <FormattedMessage id="signin_button_signin" />
          </LoadingButton>
        </Grid>
      </Grid>
    </SignInFormRoot>
  );
};

export default SignInForm;
