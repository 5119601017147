import { Box, Button, styled } from '@mui/material';

export const AddWorkoutCardStyled = styled(Button)(({ theme }) => ({
  // Same as ClassCard
  width: '100%',
  minWidth: '200px',
  maxWidth: '354px',

  height: '100%',
  minHeight: '250px',

  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',

  cursor: 'pointer',
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '56px',
  height: '56px',

  borderRadius: '50%',
  background: theme.palette.primary.main,
  boxShadow: theme.shadows[6],

  svg: {
    color: theme.palette.primary.contrastText,
  },
}));
