import { ChevronRight } from '@mui/icons-material';
import { Breadcrumbs, Link, Typography, Stack } from '@mui/material';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterLink, useParams } from 'react-router-dom';

import { PageContainer } from '~/components/PageContainer';
import { TabPanelContainer } from '~/components/TabComponents';

import {
  DividerDefault,
  DividerTotal,
  PurchaseDetails,
  StackStyles,
  Title,
} from './styles';

const PaymentDetails: React.FC = () => {
  const { paymentId } = useParams();

  return (
    <PageContainer>
      <Breadcrumbs
        aria-label="navigation-breadcrumb"
        separator={<ChevronRight />}
        sx={{ mb: '144px' }}
      >
        <Link
          component={ReactRouterLink}
          underline="hover"
          color="inherit"
          to="/"
        >
          <FormattedMessage id="platform" />
        </Link>

        <Link
          component={ReactRouterLink}
          underline="hover"
          color="text.primary"
          to="/payments"
          aria-current="page"
        >
          <Typography variant="body1">
            <FormattedMessage id="payments_breadcrumb" />
          </Typography>
        </Link>

        <Link
          component={ReactRouterLink}
          underline="hover"
          color="text.primary"
          to={`/payments/${paymentId}`}
          aria-current="page"
        >
          <Typography variant="body1">Carla</Typography>
        </Link>
      </Breadcrumbs>

      <TabPanelContainer>
        <PurchaseDetails>
          <Stack>
            <StackStyles mb={6}>
              <Title variant="h5">Purchase Details</Title>
            </StackStyles>

            <StackStyles>
              <FormattedMessage id="payments_purchase_date" />
              <Typography>13.12.2022</Typography>
            </StackStyles>

            <DividerDefault />

            <StackStyles>
              <FormattedMessage id="payments_method" />
              <Typography>PayPal</Typography>
            </StackStyles>

            <DividerDefault />

            <StackStyles>
              <FormattedMessage id="payments_price" />
              <Typography>$29,90</Typography>
            </StackStyles>
          </Stack>

          <DividerTotal />

          <StackStyles>
            <FormattedMessage id="payments_total" />
            <Typography>$29,90</Typography>
          </StackStyles>
        </PurchaseDetails>
      </TabPanelContainer>
    </PageContainer>
  );
};

export default PaymentDetails;
