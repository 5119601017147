import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { defaultTableOptions } from '~/helpers/table/state';

import { PaginationDirection } from '~/store/types/pagination';

export interface TableHeadSortItem {
  id: string;
  labelId?: string;
  disableSort?: boolean;
}

export type TableHeadOnSortItem = Required<TableHeadSortItem>;

interface Props {
  sort: string;
  direction: PaginationDirection;
  items: TableHeadSortItem[];
  onSort: (item: TableHeadOnSortItem) => void;
}

const defaultDirection = defaultTableOptions.sort.direction;

const TableHeadSort: React.FC<Props> = ({ items, sort, direction, onSort }) => {
  return (
    <TableHead>
      <TableRow>
        {items.map((item) => (
          <TableCell
            key={item.id}
            sortDirection={sort === item.id ? direction : false}
          >
            {item.labelId && !item.disableSort && (
              <TableSortLabel
                active={sort === item.id}
                direction={sort === item.id ? direction : defaultDirection}
                onClick={() => onSort(item as TableHeadOnSortItem)}
              >
                <FormattedMessage id={item.labelId} />
              </TableSortLabel>
            )}

            {item.disableSort && item.labelId && (
              <FormattedMessage id={item.labelId} />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadSort;
