import { createActions, createReducer } from 'reduxsauce';

import { defaultTableOptions } from '~/helpers/table/state';

import {
  ICouponCreators,
  ICouponsTypes,
  CouponsState,
  LoadCouponsSuccessAction,
  UpdateCouponsOptions,
} from '../types/coupons';

export const { Types: CouponsTypes, Creators: CouponsActions } = createActions<
  ICouponsTypes,
  ICouponCreators
>({
  loadCouponsRequest: [],
  loadCouponsSuccess: ['coupons', 'options'],
  loadCouponsFailure: ['error'],

  createCouponsRequest: ['data', 'callback'],
  createCouponsSuccess: [],
  createCouponsFailure: [],

  deleteCouponRequest: ['couponId', 'callback'],
  deleteCouponSuccess: [],
  deleteCouponFailure: [],

  updateCouponsOptions: ['options'],
});

const INITIAL_STATE: CouponsState = {
  coupons: [],
  loading: false,
  options: {
    sort: 'createdAt',
    search: '',
    page: 1,
    total: 0,
    limit: defaultTableOptions.pagination.limit,
    direction: 'desc',
  },
};

const loadCouponsRequest = (state = INITIAL_STATE): CouponsState => ({
  ...state,
  loading: true,
});

const loadCouponsSuccess = (
  state = INITIAL_STATE,
  action: LoadCouponsSuccessAction
): CouponsState => ({
  ...state,
  coupons: action.coupons,
  loading: false,
  options: {
    ...state.options,
    ...action.options,
  },
});

const loadCouponsFailure = (state = INITIAL_STATE): CouponsState => ({
  ...state,
  loading: false,
});

const createCouponsRequest = (state = INITIAL_STATE): CouponsState => ({
  ...state,
  loading: true,
});

const createCouponsSuccess = (state = INITIAL_STATE): CouponsState => ({
  ...state,
  loading: false,
});

const createCouponsFailure = (state = INITIAL_STATE): CouponsState => ({
  ...state,
  loading: false,
});

const deleteCouponRequest = (state = INITIAL_STATE): CouponsState => ({
  ...state,
  loading: true,
});

const deleteCouponSuccess = (state = INITIAL_STATE): CouponsState => ({
  ...state,
  loading: false,
});

const deleteCouponFailure = (state = INITIAL_STATE): CouponsState => ({
  ...state,
  loading: false,
});

const updateCouponsOptions = (
  state = INITIAL_STATE,
  action: UpdateCouponsOptions
): CouponsState => ({
  ...state,
  options: {
    ...state.options,
    ...action.options,
  },
});

export default createReducer(INITIAL_STATE, {
  [CouponsTypes.LOAD_COUPONS_REQUEST]: loadCouponsRequest,
  [CouponsTypes.LOAD_COUPONS_SUCCESS]: loadCouponsSuccess,
  [CouponsTypes.LOAD_COUPONS_FAILURE]: loadCouponsFailure,

  [CouponsTypes.CREATE_COUPONS_FAILURE]: createCouponsFailure,
  [CouponsTypes.CREATE_COUPONS_REQUEST]: createCouponsRequest,
  [CouponsTypes.CREATE_COUPONS_SUCCESS]: createCouponsSuccess,

  [CouponsTypes.DELETE_COUPON_REQUEST]: deleteCouponRequest,
  [CouponsTypes.DELETE_COUPON_SUCCESS]: deleteCouponSuccess,
  [CouponsTypes.DELETE_COUPON_FAILURE]: deleteCouponFailure,

  [CouponsTypes.UPDATE_COUPONS_OPTIONS]: updateCouponsOptions,
});
