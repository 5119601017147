import React from 'react';

import SignInContainer from './components/SignInContainer';
import SignInForm from './components/SignInForm';
import { StartFormWrapper } from './styles';

const SignIn: React.FC = () => {
  return (
    <SignInContainer>
      <StartFormWrapper>
        <SignInForm />
      </StartFormWrapper>
    </SignInContainer>
  );
};

export default SignIn;
