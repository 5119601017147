import { PaletteOptions } from '@mui/material';

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#71C6BC',
    contrastText: '#FFFFFF',
    light: '#E8F7F5',
  },
  secondary: {
    main: '#5C5C5C',
    light: '#858585',
    contrastText: '#FFFFFF',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  success: {
    main: '#66bb6a',
    light: '#81c784',
    dark: '#388e3c',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#F44336',
    light: '#F88078',
    dark: '#E31B0C',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#29b6f6',
    light: '#4fc3f7',
    dark: '#0288d1',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#ffa726',
    light: '#ffb74d',
    dark: '#f57c00',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#040404',
    secondary: '#00000099',
    disabled: '#00000042',
  },
  action: {
    active: '#757575',
    disabled: '#00000042',
    selected: '#E7E7E7',
    selectedOpacity: 0.08,
    hover: '#E8F6F4',
  },
  divider: '#DDDDDD',
  grey: {
    50: '#FBFBFB',
    100: '#F0F0F0',
  },
};

export default palette;
