import { createActions, createReducer } from 'reduxsauce';

import { defaultListOptions } from '~/helpers/table/state';

import {
  AddWorkoutsLevelFailureAction,
  IWorkoutsCreators,
  IWorkoutsTypes,
  LoadWorkoutsSuccessAction,
  UpdateWorkoutsOptions,
  WorkoutsState,
} from '../types/workouts';

export const { Types: WorkoutsTypes, Creators: WorkoutsActions } =
  createActions<IWorkoutsTypes, IWorkoutsCreators>({
    loadWorkoutsRequest: [],
    loadWorkoutsSuccess: ['workouts', 'options'],
    loadWorkoutsFailure: [],

    addWorkoutsLevelRequest: ['data', 'workoutLevelType', 'callback'],
    addWorkoutsLevelSuccess: ['level'],
    addWorkoutsLevelFailure: ['error'],

    deleteWorkoutLevelRequest: ['workoutLevelId', 'callback'],
    deleteWorkoutLevelSuccess: [],
    deleteWorkoutLevelFailure: ['error'],

    updateWorkoutsOptions: ['options'],
  });

const INITIAL_STATE: WorkoutsState = {
  workouts: [],
  loading: false,
  loadingAddWorkoutLevel: false,
  options: {
    sort: '',
    search: '',
    page: 1,
    total: 0,
    labels: [],
    limit: defaultListOptions.pagination.limit,
    direction: defaultListOptions.sort.direction,
  },
};

// Load

const loadWorkoutsRequest = (state = INITIAL_STATE): WorkoutsState => ({
  ...state,
  loading: true,
});

const loadWorkoutsSuccess = (
  state = INITIAL_STATE,
  action: LoadWorkoutsSuccessAction
): WorkoutsState => ({
  ...state,
  loading: false,
  workouts: action.workouts,
  options: {
    ...state.options,
    ...action.options,
  },
});

const loadWorkoutsFailure = (state = INITIAL_STATE): WorkoutsState => ({
  ...state,
  loading: false,
});

const addWorkoutsLevelRequest = (state = INITIAL_STATE): WorkoutsState => ({
  ...state,
  loadingAddWorkoutLevel: true,
});

const addWorkoutsLevelSuccess = (state = INITIAL_STATE): WorkoutsState => ({
  ...state,
  loadingAddWorkoutLevel: false,
});

const addWorkoutsLevelFailure = (
  state = INITIAL_STATE,
  { error }: AddWorkoutsLevelFailureAction
): WorkoutsState => ({
  ...state,
  error,
  loadingAddWorkoutLevel: false,
});

const deleteWorkoutLevelRequest = (state = INITIAL_STATE): WorkoutsState => ({
  ...state,
  loading: true,
});

const deleteWorkoutLevelSuccess = (state = INITIAL_STATE): WorkoutsState => ({
  ...state,
  loading: false,
});

const deleteWorkoutLevelFailure = (
  state = INITIAL_STATE,
  { error }: AddWorkoutsLevelFailureAction
): WorkoutsState => ({
  ...state,
  error,
  loading: false,
});

const updateWorkoutsOptions = (
  state = INITIAL_STATE,
  action: UpdateWorkoutsOptions
): WorkoutsState => ({
  ...state,
  options: {
    ...state.options,
    ...action.options,
  },
});

export default createReducer(INITIAL_STATE, {
  [WorkoutsTypes.LOAD_WORKOUTS_REQUEST]: loadWorkoutsRequest,
  [WorkoutsTypes.LOAD_WORKOUTS_SUCCESS]: loadWorkoutsSuccess,
  [WorkoutsTypes.LOAD_WORKOUTS_FAILURE]: loadWorkoutsFailure,

  [WorkoutsTypes.ADD_WORKOUTS_LEVEL_REQUEST]: addWorkoutsLevelRequest,
  [WorkoutsTypes.ADD_WORKOUTS_LEVEL_SUCCESS]: addWorkoutsLevelSuccess,
  [WorkoutsTypes.ADD_WORKOUTS_LEVEL_FAILURE]: addWorkoutsLevelFailure,

  [WorkoutsTypes.DELETE_WORKOUT_LEVEL_REQUEST]: deleteWorkoutLevelRequest,
  [WorkoutsTypes.DELETE_WORKOUT_LEVEL_SUCCESS]: deleteWorkoutLevelSuccess,
  [WorkoutsTypes.DELETE_WORKOUT_LEVEL_FAILURE]: deleteWorkoutLevelFailure,

  [WorkoutsTypes.UPDATE_WORKOUTS_OPTIONS]: updateWorkoutsOptions,
});
