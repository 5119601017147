import {
  Pagination,
  PaginationRequestQuery,
  PaginationResponse,
} from '~/store/types/pagination';

import { removeEmptyValuesFromObject } from '../object';

function formatPaginationQueryToRequest(options: Pagination) {
  return {
    page: options.page,
    sort: options.sort,
    direction: options.direction,
    limit: options.limit,
    search: options.search,
    labels: options.labels,
  };
}

export function getPaginationQueryOptions(
  options: Pagination
): PaginationRequestQuery {
  const queryOptions = formatPaginationQueryToRequest(options);
  const query = removeEmptyValuesFromObject(queryOptions);

  return query;
}

export function getPaginationOptionsFromResponse(options: PaginationResponse) {
  return {
    total: options.total,
  };
}
