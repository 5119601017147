import { ChevronRight } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Breadcrumbs, Link, Typography, Tab } from '@mui/material';

import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';

import useTranslate from '~/hooks/useTranslate';

import { PageContainer } from '~/components/PageContainer';

import ClientsPanel from './components/ClientsPanel';
import TeamPanel from './components/TeamPanel';
import { TabListUsers } from './styles';

const Users: React.FC = () => {
  const translate = useTranslate();

  const [activeTab, setActiveTab] = useState('1');

  const handleChange = useCallback((_event, newValue: string) => {
    setActiveTab(newValue);
  }, []);

  return (
    <PageContainer>
      <Breadcrumbs
        aria-label="navigation-breadcrumb"
        separator={<ChevronRight />}
      >
        <Link
          component={ReactRouterLink}
          underline="hover"
          color="inherit"
          to="/"
        >
          <FormattedMessage id="platform" />
        </Link>

        <Link
          component={ReactRouterLink}
          underline="hover"
          color="text.primary"
          to="/users"
          aria-current="page"
        >
          <Typography variant="body1">
            <FormattedMessage id="users_breadcrumb" />
          </Typography>
        </Link>
      </Breadcrumbs>

      <TabContext value={activeTab}>
        <TabListUsers
          onChange={handleChange}
          aria-label="navigation-tabs"
          variant="fullWidth"
        >
          <Tab label={translate('users_team')} value="1" />
          <Tab label={translate('users_clients')} value="2" />
        </TabListUsers>
        <TabPanel value="1">
          <TeamPanel />
        </TabPanel>
        <TabPanel value="2">
          <ClientsPanel />
        </TabPanel>
      </TabContext>
    </PageContainer>
  );
};

export default Users;
