import { Add, ChevronRight } from '@mui/icons-material';
import { Breadcrumbs, Button, Grid, Typography, Link } from '@mui/material';

import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';

import { workoutsLabelsOptions } from '~/helpers/workout/labels';
import useTypedSelector from '~/hooks/useTypedSelector';

import { WorkoutsActions } from '~/store/ducks/workouts';

import CircularLoading from '~/components/CircularLoading';
import FilterAutocomplete, {
  FilterAutocompleteOption,
  OptionLabel,
} from '~/components/Inputs/FilterAutocomplete';
import SearchInput from '~/components/Inputs/SearchInput';
import { PageContainer } from '~/components/PageContainer';

import WorkoutsFilterBy from './components/WorkoutsFilterBy';
import WorkoutsList from './components/WorkoutsList';

const Workouts: React.FC = () => {
  const { options, loading } = useTypedSelector((state) => state.workouts);

  const dispatch = useDispatch();

  const handleSearch = useCallback(
    (value: string) => {
      dispatch(WorkoutsActions.updateWorkoutsOptions({ search: value }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(WorkoutsActions.loadWorkoutsRequest());
  }, [dispatch]);

  const validateFilterLabelAndUpdate = useCallback(
    (_event, option: OptionLabel | OptionLabel[]) => {
      const optionTyped = option as FilterAutocompleteOption;

      const optionChoose = workoutsLabelsOptions.map((item) => {
        if (optionTyped.value === item.value) {
          return item.value;
        }
        return '';
      });

      dispatch(
        WorkoutsActions.updateWorkoutsOptions({
          labels: [optionChoose[optionChoose.indexOf(optionTyped.value)]],
        })
      );
    },
    [options]
  );

  return (
    <PageContainer>
      <Breadcrumbs
        aria-label="navigation-breadcrumb"
        separator={<ChevronRight />}
      >
        <Link
          component={ReactRouterLink}
          underline="hover"
          color="inherit"
          to="/"
        >
          <FormattedMessage id="platform" />
        </Link>

        <Link
          component={ReactRouterLink}
          underline="hover"
          color="text.primary"
          to="/workouts"
          aria-current="page"
        >
          <Typography variant="body1">
            <FormattedMessage id="workouts_breadcrumb" />
          </Typography>
        </Link>
      </Breadcrumbs>

      <Grid container justifyContent="space-between" mt={6}>
        <Grid item sm={7} xs={14}>
          <SearchInput value={options.search} onChange={handleSearch} />
        </Grid>

        <Grid
          item
          lg={3}
          md={3}
          sm={6}
          xs={14}
          sx={{ '@media(max-width: 600px)': { marginTop: '20px' } }}
        >
          <Button
            component={ReactRouterLink}
            variant="contained"
            startIcon={<Add />}
            fullWidth
            to="/workouts/new-workout"
          >
            <FormattedMessage id="workouts_new_workout" />
          </Button>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" mt={3}>
        <Grid item lg={3} md={3} xs={6}>
          <FilterAutocomplete
            labelId="workout_label"
            optionsLabelsIds={workoutsLabelsOptions}
            onChange={validateFilterLabelAndUpdate}
          />
        </Grid>

        <Grid item lg={3} md={3} xs={6}>
          <WorkoutsFilterBy />
        </Grid>
      </Grid>
      {loading ? <CircularLoading /> : <WorkoutsList />}
    </PageContainer>
  );
};

export default Workouts;
