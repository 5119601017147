/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

import React, { useCallback, useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

type RhfProps = {
  name: string;
  control: Control;
  error: any;
  variant?: 'filled' | 'standard' | 'outlined';
};

type Props = Omit<TextFieldProps, 'variant' | 'error' | 'type'> & RhfProps;

const RhfTextFieldPassword: React.FC<Props> = ({
  name,
  label,
  control,
  defaultValue,
  error,
  rows,
  variant,
  ...rest
}) => {
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState(false);

  const handleChangeVisibility = useCallback(() => {
    setShowPassword((oldVisibility) => !oldVisibility);
  }, []);

  const type = useMemo(
    () => (showPassword ? 'text' : 'password'),
    [showPassword]
  );

  return (
    <Controller
      render={({ field: { onChange, value, ref, ...props } }) => (
        <TextField
          label={label}
          value={value || ''}
          error={!!error}
          multiline={!!rows}
          rows={rows}
          type={type}
          helperText={
            error && intl.formatMessage(error.message, error.message.values)
          }
          onChange={onChange}
          inputRef={ref}
          variant={variant}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={handleChangeVisibility}
                sx={{ cursor: 'pointer' }}
              >
                {showPassword && <VisibilityOffIcon />}
                {!showPassword && <VisibilityIcon />}
              </InputAdornment>
            ),
          }}
          {...props}
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default RhfTextFieldPassword;
