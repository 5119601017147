import { Delete as DeleteIcon } from '@mui/icons-material';
import { BoxProps, Grid } from '@mui/material';

import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingButton from '~/components/Buttons/LoadingButton';

import { FooterBox } from './styles';

export interface FormFooterProps extends BoxProps {
  loadingSave?: boolean;
  loadingDelete?: boolean;

  saveMessageId?: string;
  deleteMessageId?: string;

  onSave?: () => void;
  onDelete?: () => void;
}

const FormFooter: React.FC<FormFooterProps> = ({
  onSave,
  onDelete,
  saveMessageId,
  deleteMessageId,
  loadingSave,
  loadingDelete,
  ...rest
}) => {
  const messagesIds = useMemo(() => {
    return {
      saveId: saveMessageId || 'save',
      deleteId: deleteMessageId || 'delete_account',
    };
  }, [deleteMessageId, saveMessageId]);

  return (
    <FooterBox {...rest}>
      <Grid container spacing={2} justifyContent="space-between">
        {onDelete && (
          <Grid item lg={5} md={6} xs={14}>
            <LoadingButton
              variant="outlined"
              color="error"
              fullWidth
              onClick={onDelete}
              loading={loadingDelete}
            >
              <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
              <FormattedMessage id={messagesIds.deleteId} />
            </LoadingButton>
          </Grid>
        )}

        <Grid item lg={5} md={6} xs={14} ml="auto">
          <LoadingButton
            variant="contained"
            type="submit"
            fullWidth
            onClick={onSave}
            loading={loadingSave}
          >
            <FormattedMessage id={messagesIds.saveId} />
          </LoadingButton>
        </Grid>
      </Grid>
    </FooterBox>
  );
};

export default FormFooter;
