import { Grid, styled, Typography } from '@mui/material';

export const SwitchStyles = styled(Grid)(() => ({
  width: '100%',
}));

export const SwitchTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.4px',
  textAlign: 'left',
}));

export const SwitchGroup = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  // input: {
  //   backgroundColor: 'red',
  // },
}));

export const SwitchLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[800],
}));
