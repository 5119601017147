import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MuiModal = {
  defaultProps?: ComponentsProps['MuiModal'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiModal'];
  variants?: ComponentsVariants['MuiModal'];
};

const styles = (_theme: Theme): MuiModal => ({
  styleOverrides: {
    root: {
      '& > .MuiBox-root': {
        outline: 'none',
      },
    },
  },
});

export default styles;
