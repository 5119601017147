import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';

import { clientProfileValidationSchema } from '~/helpers/yup';
import useTypedSelector from '~/hooks/useTypedSelector';

import { ClientForm, Form } from '~/components/FormComponents';
import { TabPanelContainer } from '~/components/TabComponents';

const MomProfilePanel: React.FC = () => {
  const { user } = useTypedSelector((state) => state.user);

  const methods = useForm({
    resolver: yupResolver(clientProfileValidationSchema),
  });

  return (
    <TabPanelContainer sx={{ flex: '1' }}>
      <Form {...methods}>
        <ClientForm user={user} />
      </Form>
    </TabPanelContainer>
  );
};

export default MomProfilePanel;
