import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import {
  Box,
  CardContent,
  CardMedia,
  CardProps,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import React from 'react';

import placeholder from '~/assets/images/video-placeholder.svg';

import useTranslate from '~/hooks/useTranslate';

import {
  ChipsBox,
  ClassCardActions,
  ClassCardDescription,
  ClassCardStyled,
} from './styles';

interface MyProps {
  title: string;
  description: string;
  labels: string[];
  thumbnail?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  selected?: boolean;
  onPlayVideo?: () => void;
  onMoveBack?: () => void;
  onMoveForward?: () => void;
}

type Props = MyProps & CardProps;

const ClassCard: React.FC<Props> = ({
  title,
  description,
  labels,
  thumbnail,
  onEdit,
  onDelete,
  onPlayVideo,
  onMoveBack,
  onMoveForward,
  ...rest
}) => {
  const translate = useTranslate();
  const limitLabels = labels.slice(0, 1);

  return (
    <ClassCardStyled {...rest}>
      <CardMedia
        component="img"
        image={thumbnail || placeholder}
        alt="Thumbnail Video"
        onClick={onPlayVideo}
        sx={{ cursor: 'pointer', minHeight: '150px', minWidth: '221px' }}
      />

      <CardContent>
        <Typography variant="body2">{title}</Typography>

        <ClassCardDescription variant="body2">
          {description}
        </ClassCardDescription>
      </CardContent>

      <ClassCardActions>
        {labels ? (
          <ChipsBox>
            {limitLabels.map((label) => (
              <Chip
                key={label}
                label={translate(`workout_label_${label}`)}
                color="primary"
              />
            ))}
            {labels.length > 1 && (
              <Chip color="primary" label={`+${labels.length - 1}`} />
            )}
          </ChipsBox>
        ) : (
          <span />
        )}

        <Box>
          {onEdit && (
            <Tooltip sx={{ zIndex: 10 }} title={translate('edit')}>
              <IconButton onClick={onEdit}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {onDelete && (
            <Tooltip title={translate('delete')}>
              <IconButton onClick={onDelete}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {onMoveBack && (
            <Tooltip title={translate('move_back')}>
              <IconButton color="primary" onClick={onMoveBack}>
                <ChevronLeft fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {onMoveForward && (
            <Tooltip title={translate('move_forward')}>
              <IconButton color="primary" onClick={onMoveForward}>
                <ChevronRight fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </ClassCardActions>
    </ClassCardStyled>
  );
};

export default ClassCard;
