import { AxiosResponse } from 'axios';
import { takeLatest, put, call } from 'redux-saga/effects';

import { ApiError } from '~/types/request';

import { getErrorIdFromResponse } from '~/helpers/api';
import api from '~/services/api';

import { FlashMessageActions } from '../ducks/flashMessage';
import { LevelTypes, LevelActions } from '../ducks/level';
import {
  CreateLevelRequestAction,
  DeleteLevelRequestAction,
  Level,
  UpdateLevelRequestAction,
} from '../types/level';

type LevelResponse = AxiosResponse<Level>;

function* createLevelRequest({ data, callback }: CreateLevelRequestAction) {
  try {
    const response: LevelResponse = yield call(api.post, '/levels', data);

    yield put(
      FlashMessageActions.showMessage({
        id: 'course_modal_level_create',
        variant: 'success',
      })
    );

    yield put(LevelActions.createLevelSuccess(response.data));

    callback();
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(
      response,
      'course_modal_level_create_failure'
    );

    yield put(LevelActions.createLevelFailure(errorId));
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* updateLevelRequest({
  levelId,
  data,
  callback,
}: UpdateLevelRequestAction) {
  try {
    const response: LevelResponse = yield call(
      api.put,
      `/levels/${levelId}`,
      data
    );

    yield put(
      FlashMessageActions.showMessage({
        id: 'course_modal_level_update',
        variant: 'success',
      })
    );

    yield put(LevelActions.updateLevelSuccess(response.data));
    callback();
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(
      response,
      'course_modal_level_update_failure'
    );

    yield put(LevelActions.updateLevelFailure(errorId));
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* deleteLevelRequest({ levelId, callback }: DeleteLevelRequestAction) {
  try {
    yield call(api.delete, `/levels/${levelId}`);

    yield put(LevelActions.deleteLevelSuccess());
    yield put(
      FlashMessageActions.showMessage({
        id: 'course_modal_level_deleted',
        variant: 'success',
      })
    );

    callback();
  } catch (error) {
    const { response } = error as ApiError;
    const errorId = getErrorIdFromResponse(
      response,
      'course_modal_level_deleted_failure'
    );

    yield put(LevelActions.deleteLevelFailure(errorId));
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

export default function* sagas() {
  yield takeLatest(LevelTypes.CREATE_LEVEL_REQUEST, createLevelRequest);
  yield takeLatest(LevelTypes.UPDATE_LEVEL_REQUEST, updateLevelRequest);
  yield takeLatest(LevelTypes.DELETE_LEVEL_REQUEST, deleteLevelRequest);
}
