/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  FormProvider,
  FormProviderProps,
  SubmitHandler,
} from 'react-hook-form';

import { FormComponent } from './styles';

interface Props extends FormProviderProps {
  onSubmit?: SubmitHandler<any>;
}

const Form: React.FC<Props> = ({
  onSubmit,
  handleSubmit,
  children,
  ...rest
}) => {
  return (
    <FormProvider handleSubmit={handleSubmit} {...rest}>
      <FormComponent onSubmit={onSubmit && handleSubmit(onSubmit)}>
        {children}
      </FormComponent>
    </FormProvider>
  );
};

export default Form;
