import { Divider, Typography } from '@mui/material';

import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { getPageDataBasedOnPath } from '~/helpers/pages';
import useTranslate from '~/hooks/useTranslate';
import useTypedSelector from '~/hooks/useTypedSelector';

import TopBarMenu from '../TopBarMenu';
import { AppBarContainer, AppBarStyled, AppBarToolbar } from './styles';

const TopBar: React.FC = () => {
  const { user } = useTypedSelector((state) => state.session);

  const location = useLocation();
  const translate = useTranslate();

  const title = useMemo(() => {
    const pageData = getPageDataBasedOnPath(location.pathname);
    const titleId = `${pageData?.name}_bar_title`;

    const params = {
      // name: user.name,
      name: user?.name || 'Finalisar login',
    };

    return translate(titleId, params);
  }, [location, translate]);

  return (
    <AppBarStyled>
      <AppBarContainer>
        <AppBarToolbar disableGutters>
          <Typography variant="h4">{title}</Typography>
          <TopBarMenu />
        </AppBarToolbar>

        <Divider />
      </AppBarContainer>
    </AppBarStyled>
  );
};

export default TopBar;
