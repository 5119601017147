import { ChevronRight } from '@mui/icons-material';
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';

import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';

import SearchInput from '~/components/Inputs/SearchInput';
import { PageContainer } from '~/components/PageContainer';

import MomsTable from './components/MomsTable';
import { MomsContainer, MomsTableBox } from './styles';

const Moms: React.FC = () => {
  const handleSearch = useCallback(() => {
    //
  }, []);

  return (
    <PageContainer>
      <Breadcrumbs
        aria-label="navigation-breadcrumb"
        separator={<ChevronRight />}
      >
        <Link
          component={ReactRouterLink}
          underline="hover"
          color="inherit"
          to="/"
        >
          <FormattedMessage id="platform" />
        </Link>

        <Link
          component={ReactRouterLink}
          underline="hover"
          color="text.primary"
          to="/moms"
          aria-current="page"
        >
          <Typography variant="body1">
            <FormattedMessage id="moms_breadcrumb" />
          </Typography>
        </Link>
      </Breadcrumbs>

      <MomsTableBox>
        <Grid container justifyContent="space-between">
          <Grid item xs={7}>
            <SearchInput onChange={handleSearch} />
          </Grid>
        </Grid>

        <MomsContainer>
          <MomsTable />
        </MomsContainer>
      </MomsTableBox>
    </PageContainer>
  );
};

export default Moms;
