import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ClientProfile from '~/pages/ClientProfile';
import Coupons from '~/pages/Coupons';
import Course from '~/pages/Course';
import Dashboard from '~/pages/Dashboard';
import MomProfile from '~/pages/MomProfile';
import Moms from '~/pages/Moms';
import NewCoupon from '~/pages/NewCoupon';
import PaymentDetails from '~/pages/PaymentDetails';
import Payments from '~/pages/Payments';
import Settings from '~/pages/Settings';
import SignIn from '~/pages/SignIn';
import UserProfile from '~/pages/UserProfile';
import Users from '~/pages/Users';
import WorkoutDetails from '~/pages/WorkoutDetails';
import Workouts from '~/pages/Workouts';

import AuthLayout from './components/AuthLayout';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signin" element={<SignIn />} />

        <Route
          path="/"
          element={
            <AuthLayout>
              <Dashboard />
            </AuthLayout>
          }
        />

        <Route
          path="/users"
          element={
            <AuthLayout>
              <Users />
            </AuthLayout>
          }
        />

        <Route
          path="/users/profile/:userId"
          element={
            <AuthLayout>
              <UserProfile />
            </AuthLayout>
          }
        />

        <Route
          path="/users/clients/profile/:userId"
          element={
            <AuthLayout>
              <ClientProfile />
            </AuthLayout>
          }
        />

        <Route
          path="/payments/:paymentId"
          element={
            <AuthLayout>
              <PaymentDetails />
            </AuthLayout>
          }
        />

        <Route
          path="/payments"
          element={
            <AuthLayout>
              <Payments />
            </AuthLayout>
          }
        />
        <Route
          path="/coupons"
          element={
            <AuthLayout>
              <Coupons />
            </AuthLayout>
          }
        />
        <Route
          path="/coupons/new-coupon"
          element={
            <AuthLayout>
              <NewCoupon />
            </AuthLayout>
          }
        />
        <Route
          path="/settings"
          element={
            <AuthLayout>
              <Settings />
            </AuthLayout>
          }
        />

        <Route
          path="/course"
          element={
            <AuthLayout>
              <Course />
            </AuthLayout>
          }
        />

        <Route
          path="/workouts"
          element={
            <AuthLayout>
              <Workouts />
            </AuthLayout>
          }
        />

        <Route
          path="/workouts/:workoutId"
          element={
            <AuthLayout>
              <WorkoutDetails />
            </AuthLayout>
          }
        />

        <Route
          path="/moms"
          element={
            <AuthLayout>
              <Moms />
            </AuthLayout>
          }
        />

        <Route
          path="/moms/:momId"
          element={
            <AuthLayout>
              <MomProfile />
            </AuthLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
