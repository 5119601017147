import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MuiTextField = {
  defaultProps?: ComponentsProps['MuiTextField'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField'];
  variants?: ComponentsVariants['MuiTextField'];
};

const styles = (_theme: Theme): MuiTextField => ({
  defaultProps: {
    size: 'small',
  },
});

export default styles;
