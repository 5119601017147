import { Box, styled } from '@mui/material';

export const FooterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  paddingTop: '48px',
  borderTop: `1px solid ${theme.palette.divider}`,
}));
