import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { BoxProps } from '@mui/material';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';

import useTranslate from '~/hooks/useTranslate';

import {
  DropzoneImage,
  DropzoneText,
  FileUploadBox,
  InputStyled,
} from './styles';

interface ImageChangeProps {
  imageFile: File;
  src: string;
}

interface Props extends BoxProps {
  defaultText?: string;
  defaultImageSrc?: string;
  onImageChange: (data: ImageChangeProps) => void;
}

const ImageDropzone: React.FC<Props> = ({
  defaultText,
  defaultImageSrc,
  onImageChange,
  ...rest
}) => {
  const translate = useTranslate();
  const dropzoneImageRef = useRef<HTMLImageElement>(null);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const [selectedImage, setSelectedImage] = useState('');

  const handleImageChange = useCallback(
    (imageData: ImageChangeProps) => {
      if (dropzoneImageRef.current) {
        dropzoneImageRef.current.src = imageData.src.toString();
      }

      setSelectedImage(imageData.src);
      onImageChange(imageData);
    },
    [setSelectedImage, onImageChange]
  );

  useEffect(() => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);

    reader.onload = (event) => {
      const src = event.target?.result?.toString() || '';

      const imageFile = acceptedFiles[0];

      handleImageChange({
        imageFile,
        src,
      });
    };
  }, [acceptedFiles, handleImageChange]);

  useEffect(() => {
    if (!defaultImageSrc) {
      return;
    }

    if (dropzoneImageRef.current) {
      dropzoneImageRef.current.src = defaultImageSrc;
    }

    setSelectedImage(defaultImageSrc);
  }, [defaultImageSrc]);

  const isImageSelected = useMemo(
    () => Boolean(selectedImage) || Boolean(defaultImageSrc),
    [selectedImage, defaultImageSrc]
  );

  const text = useMemo(
    () => defaultText || translate('dropzone_default_text'),
    [defaultText, translate]
  );

  return (
    <FileUploadBox {...getRootProps()} {...rest}>
      <InputStyled {...getInputProps()} accept="image/*" multiple={false} />

      {isImageSelected && (
        <DropzoneImage ref={dropzoneImageRef} alt="Dropzone" />
      )}

      {!isImageSelected && (
        <>
          <CloudUploadIcon fontSize="large" color="secondary" sx={{ mb: 1 }} />
          <DropzoneText variant="caption">{text}</DropzoneText>
        </>
      )}
    </FileUploadBox>
  );
};

export default ImageDropzone;
