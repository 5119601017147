import { SxProps, Theme } from '@mui/material';

import theme from '~/theme';

interface ProgressBreakpoint {
  value: number;
  name: string;
  style: SxProps<Theme>;
}

const progressBreakpoints: ProgressBreakpoint[] = [
  {
    value: 20,
    name: 'low',
    style: {
      color: theme.palette.error.contrastText,
      background: theme.palette.error.light,
    },
  },
  {
    value: 50,
    name: 'medium',
    style: {
      color: theme.palette.warning.contrastText,
      background: theme.palette.warning.light,
    },
  },
  {
    value: 100,
    name: 'high',
    style: {
      color: theme.palette.success.contrastText,
      background: theme.palette.success.light,
    },
  },
];

function getProgressBreakpoint(progress: number) {
  const breakpoint = progressBreakpoints.find((item) => item.value >= progress);
  return breakpoint || progressBreakpoints[0];
}

export function getProgressStyle(progress: number) {
  const breakpoint = getProgressBreakpoint(progress);

  return {
    sx: breakpoint.style,
  };
}
