import { Grid } from '@mui/material';

import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useTypedSelector from '~/hooks/useTypedSelector';

import { UsersActions } from '~/store/ducks/users';

import CircularLoading from '~/components/CircularLoading';
import SearchInput from '~/components/Inputs/SearchInput';

import ClientsTable from './components/ClientsTable';
import { ClientsTableContainer } from './styles';

const ClientsPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { loading } = useTypedSelector((state) => state.users.clients);

  const handleSearch = useCallback(
    (value: string) => {
      dispatch(UsersActions.updateClientsOptions({ search: value }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(UsersActions.loadClientsRequest());
  }, [dispatch]);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <SearchInput onChange={handleSearch} />
        </Grid>
      </Grid>

      {loading ? (
        <CircularLoading />
      ) : (
        <ClientsTableContainer>
          <ClientsTable />
        </ClientsTableContainer>
      )}
    </>
  );
};

export default ClientsPanel;
