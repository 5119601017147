import { createActions, createReducer } from 'reduxsauce';

import {
  IImageTypes,
  IImageCreators,
  ImageState,
  CreateUploadImageFailureAction,
} from '../types/image';

export const { Types: ImageTypes, Creators: ImageActions } = createActions<
  IImageTypes,
  IImageCreators
>({
  createUploadImageRequest: ['image', 'callback'],
  createUploadImageSuccess: [],
  createUploadImageFailure: ['error'],
});

const INITIAL_STATE: ImageState = {
  image: {
    id: '',
    mimetype: '',
    name: '',
    originalName: '',
    size: 0,
    url: '',
    createdAt: '',
  },
};

const createUploadImageRequest = (state = INITIAL_STATE): ImageState => ({
  ...state,
});

const createUploadImageSuccess = (state = INITIAL_STATE): ImageState => ({
  ...state,
});

const createUploadImageFailure = (
  state = INITIAL_STATE,
  { error }: CreateUploadImageFailureAction
): ImageState => ({
  ...state,
  error,
});

export default createReducer(INITIAL_STATE, {
  [ImageTypes.CREATE_UPLOAD_IMAGE_REQUEST]: createUploadImageRequest,
  [ImageTypes.CREATE_UPLOAD_IMAGE_SUCCESS]: createUploadImageSuccess,
  [ImageTypes.CREATE_UPLOAD_IMAGE_FAILURE]: createUploadImageFailure,
});
