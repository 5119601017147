import { styled, Box, Stack, Typography, Divider } from '@mui/material';

export const PurchaseDetails = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '24px 95px',
}));

export const StackStyles = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: theme.palette.secondary.main,
}));

export const Title = styled(Typography)(() => ({
  fontWeight: 'bold',
  color: '#000',
}));

export const DividerDefault = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.grey[200],
  margin: '16px 0',
}));

export const DividerTotal = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.grey[200],
  margin: '200px 0 16px 0',
}));
