import { Add, ChevronRight } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';

import useTypedSelector from '~/hooks/useTypedSelector';

import { CourseActions } from '~/store/ducks/course';
import { WorkoutsActions } from '~/store/ducks/workouts';
import { ApplicationState } from '~/store/types';
import { CourseState } from '~/store/types/course';

import CircularLoading from '~/components/CircularLoading';
import { PageContainer } from '~/components/PageContainer';

import CourseBanner from './components/CourseBanner';
import HeaderCards from './components/HeaderCards';
import LevelAccordion from './components/LevelAccordion';
import LevelModal from './components/LevelModal';

const courseSelector = (state: ApplicationState) => state.course;

const Course: React.FC = () => {
  const [openCreateNewLevelModal, setOpenCreateNewLevelModal] = useState(false);
  const dispatch = useDispatch();
  const { course }: CourseState = useSelector(courseSelector);
  const { levels } = course;
  const { loading } = useTypedSelector((state) => state.course);

  const handleOpenCreateNewLevelModal = () => {
    setOpenCreateNewLevelModal(true);
  };
  const handleCloseCreateNewLevelModal = () => {
    setOpenCreateNewLevelModal(false);
  };

  useEffect(() => {
    dispatch(CourseActions.getCourseRequest());
    dispatch(WorkoutsActions.updateWorkoutsOptions({ search: '' }));
    dispatch(WorkoutsActions.updateWorkoutsOptions({ labels: [] }));
  }, [dispatch]);

  return (
    <>
      <LevelModal
        open={openCreateNewLevelModal}
        level={{ courseId: course.id }}
        onClose={handleCloseCreateNewLevelModal}
      />

      <PageContainer>
        <Breadcrumbs
          aria-label="navigation-breadcrumb"
          separator={<ChevronRight />}
        >
          <Link
            component={ReactRouterLink}
            underline="hover"
            color="inherit"
            to="/"
          >
            <FormattedMessage id="platform" />
          </Link>

          <Link
            component={ReactRouterLink}
            underline="hover"
            color="text.primary"
            to="/course"
            aria-current="page"
          >
            <Typography variant="body1">
              <FormattedMessage id="course_breadcrumb" />
            </Typography>
          </Link>
        </Breadcrumbs>

        <Grid container mt={6}>
          <Grid
            item
            lg={10}
            sm={14}
            xs={14}
            sx={{
              '@media(max-width: 1200px)': {
                marginBottom: '20px',
                paddingRight: '0px',
              },
              paddingRight: '24px',
            }}
          >
            <CourseBanner course={course} />
          </Grid>

          <HeaderCards />
        </Grid>

        <Grid container mt={4} justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" onClick={handleOpenCreateNewLevelModal}>
              <Add sx={{ mr: 1 }} />
              <FormattedMessage id="course_new_level" />
            </Button>
          </Grid>
        </Grid>

        {loading ? (
          <CircularLoading />
        ) : (
          <Box mt={3}>
            {levels.map((level) => (
              <LevelAccordion key={level.id} level={level} />
            ))}
          </Box>
        )}
      </PageContainer>
    </>
  );
};

export default Course;
