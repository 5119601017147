import { Typography, Box, styled } from '@mui/material';

export const FileUploadBox = styled(Box)(() => ({
  position: 'relative',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  borderRadius: '4px',
  cursor: 'pointer',
}));

export const InputStyled = styled('input')(() => ({}));

export const DropzoneImage = styled('img')(() => ({
  width: '100%',
  height: '100%',

  borderRadius: '5px',
  objectFit: 'cover',
}));

// export const DropzoneCircleImage = styled('img')(() => ({
//   width: '166px',
//   height: '166px',

//   borderRadius: '50%',
//   objectFit: 'cover',
// }));

export const DropzoneText = styled(Typography)(() => ({
  maxWidth: '41%',
  textAlign: 'center',
}));
