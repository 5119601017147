import { Edit as EditIcon } from '@mui/icons-material';
import {
  Avatar,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { get } from 'lodash';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getSortOptionOnHandle } from '~/helpers/table/pagination';
import {
  getClientStatusColor,
  getClientStatusIntl,
  getProgressStyle,
} from '~/helpers/user';
import useTranslate from '~/hooks/useTranslate';
import useTypedSelector from '~/hooks/useTypedSelector';

import { UsersActions } from '~/store/ducks/users';
import { User } from '~/store/types/session';

import {
  LimitChangeProps,
  TableHeadOnSortItem,
  TableHeadSort,
  TableHeadSortItem,
  TablePagination,
} from '~/components/TableComponents';

const tableHeadItems: TableHeadSortItem[] = [
  { id: 'avatar' },
  { id: 'name', labelId: 'users_clients_name' },
  { id: 'email', labelId: 'users_clients_email' },
  { id: 'role', labelId: 'users_clients_progress' },
  { id: 'status', labelId: 'users_clients_status' },
  { id: 'actions' },
];

const ClientsTable: React.FC = () => {
  const { clients, options } = useTypedSelector((state) => state.users.clients);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslate();

  const handlePaginationPageChange = useCallback(
    (newPage: number) => {
      const newOptions = {
        page: newPage,
      };

      dispatch(UsersActions.updateClientsOptions(newOptions));
    },
    [dispatch]
  );

  const handlePaginationLimitChange = useCallback(
    (props: LimitChangeProps) => {
      dispatch(UsersActions.updateClientsOptions(props));
    },
    [dispatch]
  );

  const handleTableSort = useCallback(
    (item: TableHeadOnSortItem) => {
      if (item.id === 'status') {
        // eslint-disable-next-line no-console
        console.log('The part of sort by status is not implemented on backend');

        return;
      }

      const newOptions = getSortOptionOnHandle(options, item.id);
      dispatch(UsersActions.updateClientsOptions(newOptions));
    },
    [dispatch, options]
  );

  const handleEdit = useCallback(
    (user: User) => {
      navigate(`/users/clients/profile/${user.id}`);
    },
    [navigate]
  );

  return (
    <>
      <Table aria-label="clients table">
        <TableHeadSort
          items={tableHeadItems}
          sort={options.sort}
          direction={options.direction}
          onSort={handleTableSort}
        />

        <TableBody>
          {clients.map((client) => (
            <TableRow key={client.id}>
              <TableCell>
                <Avatar
                  placeholder={client.name}
                  sx={{ background: grey[400] }}
                >
                  {client.name.substring(0, 2)}
                </Avatar>
              </TableCell>

              <TableCell>{client.name}</TableCell>

              <TableCell>{client.email}</TableCell>

              <TableCell>
                <Chip
                  size="medium"
                  label={`${Math.floor(
                    get(client, 'students[0].progress', 0)
                  )}%`}
                  {...getProgressStyle(get(client, 'students[0].progress', 0))}
                />
              </TableCell>

              <TableCell>
                <Typography
                  variant="body2"
                  color={getClientStatusColor('active')}
                >
                  <FormattedMessage id={getClientStatusIntl('active')} />
                </Typography>
              </TableCell>

              <TableCell align="right">
                <Tooltip title={translate('edit')}>
                  <IconButton onClick={() => handleEdit(client)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        options={options}
        onPageChange={handlePaginationPageChange}
        onLimitChange={handlePaginationLimitChange}
      />
    </>
  );
};

export default ClientsTable;
