import { styled, Link, Stack } from '@mui/material';

const CardItemsStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  height: '100%',
  rowGap: theme.spacing(1),

  borderRadius: '10px',
  padding: '15px 0 12px 0',
  textAlign: 'center',
  backgroundColor: theme.palette.common.white,
}));

export const RegisteredMothersStack = styled(CardItemsStack)(() => ({}));

export const CompletedLevelsStack = styled(CardItemsStack)(() => ({
  background: 'linear-gradient(141.32deg, #C3EAE5 0%, #71C6BC 100%)',
}));

export const SeeMore = styled(Link)(({ theme }) => ({
  marginTop: 'auto',

  color: theme.palette.secondary.main,
  textDecorationColor: theme.palette.secondary.light,

  '&:hover': {
    textDecorationColor: theme.palette.secondary.main,
  },
}));
