import {
  Backdrop,
  Button,
  Fade,
  Modal,
  Stack,
  Typography,
} from '@mui/material';

import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingButton from '~/components/Buttons/LoadingButton';

import { ModalBox } from '../styles';

export interface DeleteModalRef {
  handleOpen: (item?: unknown) => void;
  handleClose: () => void;
}

interface Props {
  onClose?: () => void;
  onConfirm: (item?: unknown) => void;

  loadingDeletion?: boolean;
}

const DeleteModal: ForwardRefRenderFunction<DeleteModalRef, Props> = (
  { onClose, onConfirm, loadingDeletion },
  ref
) => {
  const submitItemRef = useRef<unknown>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback((item?: unknown) => {
    setOpen(true);
    submitItemRef.current = item || null;
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    submitItemRef.current = null;

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm(submitItemRef.current || undefined);
  }, [onConfirm]);

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
  }));

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <ModalBox>
          <Typography variant="button">
            <FormattedMessage id="modal_delete_title" />
          </Typography>

          <Stack direction="row" spacing={3} mt={4}>
            <LoadingButton
              variant="outlined"
              color="error"
              fullWidth
              onClick={handleConfirm}
              loading={loadingDeletion}
            >
              <FormattedMessage id="modal_delete_yes" />
            </LoadingButton>

            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClose}
            >
              <FormattedMessage id="modal_delete_no" />
            </Button>
          </Stack>
        </ModalBox>
      </Fade>
    </Modal>
  );
};

export default forwardRef(DeleteModal);
