import { Button, styled, Typography } from '@mui/material';

interface ItemButtonProps {
  active: number;
  to: string;
}

export const ItemContainer = styled('li')(() => ({}));

export const ItemButton = styled(Button)<ItemButtonProps>(
  ({ theme, active }) => ({
    alignItems: 'center',
    justifyContent: 'flex-start',

    width: '100%',
    padding: '8px 16px',

    textDecoration: 'none',
    borderRadius: 0,
    background: active ? theme.palette.primary.light : '',

    '&:hover': {
      background: active ? theme.palette.primary.light : '',
    },

    'svg, p': {
      color: active ? theme.palette.text.primary : theme.palette.text.secondary,
    },
  })
);

export const ItemTypography = styled(Typography)(() => ({
  marginLeft: '18px',
  textTransform: 'none',
}));
