import {
  Box,
  Card,
  CardActions,
  CardProps,
  styled,
  Typography,
} from '@mui/material';

type ClassCardProps = CardProps & {
  selected?: boolean;
};

export const ClassCardStyled = styled(Card)<ClassCardProps>(
  ({ selected, theme }) => ({
    minWidth: '200px',
    maxWidth: '354px',

    display: 'flex',
    flexDirection: 'column',

    outline: `2px solid ${
      selected ? theme.palette.primary.main : 'transparent'
    }`,
  })
);

export const ClassCardDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,

  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const ClassCardActions = styled(CardActions)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  marginTop: 'auto',
}));

export const ChipsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(0.5),
}));
