import { Box, Stack, Typography } from '@mui/material';

import React from 'react';

import LinearProgressWithLabel from '~/components/LinearProgressWithLabel';

import LevelProgressAccordion from './components/LevelProgressAccordion';
import { LastOnlineBox, MomProgressContainer, OnlineCircle } from './styles';

const levels = [
  {
    id: '1',
    title: 'Level 1',
    courses: [
      {
        id: '1',
        title: 'Exercise 1',
        description: 'This is the description of the video',
        labels: ['Mandatory'],
      },
      {
        id: '2',
        title: 'Exercise 2',
        description: 'This is the description of the video',
        labels: ['Mandatory'],
      },
      {
        id: '3',
        title: 'Exercise 3',
        description: 'This is the description of the video',
        labels: ['Mandatory'],
      },
    ],
  },
  {
    id: '2',
    title: 'Level 2',
    courses: [
      {
        id: '4',
        title: 'Exercise 4',
        description: 'This is the description of the video',
        labels: ['Mandatory'],
      },
    ],
  },
];

const MomProgresPanel: React.FC = () => {
  return (
    <MomProgressContainer maxWidth="lg">
      <Stack spacing={4}>
        <LinearProgressWithLabel variant="determinate" value={50} />

        <LastOnlineBox>
          <Stack spacing={1} direction="row" alignItems="center">
            <OnlineCircle />

            <Typography variant="caption" fontWeight="500">
              Last time online
            </Typography>
          </Stack>

          <Typography variant="caption">23 January 2022</Typography>
        </LastOnlineBox>

        <Box>
          {levels.map((level) => (
            <LevelProgressAccordion
              key={level.id}
              title={level.title}
              courses={level.courses}
            />
          ))}
        </Box>
      </Stack>
    </MomProgressContainer>
  );
};

export default MomProgresPanel;
