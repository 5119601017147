import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MuiButton = {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
};

const styles = (_theme: Theme): MuiButton => ({
  styleOverrides: {
    root: {
      paddingTop: '8px', // to centralize

      // svg: {
      //   marginTop: '-2px', // to centralize
      // },

      '&.loading': {
        paddingTop: '6px',

        svg: {
          marginTop: '0',
        },
      },
    },
  },
});

export default styles;
