import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import useTypedSelector from '~/hooks/useTypedSelector';

import { FlashMessageActions } from '~/store/ducks/flashMessage';

const SnackbarMessage: React.FC = () => {
  const dispatch = useDispatch();
  const flashMessage = useTypedSelector((state) => state.flashMessage);

  const handleClose = useCallback(() => {
    dispatch(FlashMessageActions.hideMessage());
  }, [dispatch]);

  if (!flashMessage.open || !flashMessage.id) {
    return null;
  }

  return (
    <Snackbar
      open
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={flashMessage.variant}
        onClose={handleClose}
      >
        <FormattedMessage id={flashMessage.id} />
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
