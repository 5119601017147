import { CircularProgress } from '@mui/material';

import React from 'react';

import { LoadingBox } from './styles';

const CircularLoading: React.FC = () => {
  return (
    <LoadingBox>
      <CircularProgress />
    </LoadingBox>
  );
};

export default CircularLoading;
