import { Box, styled, Typography } from '@mui/material';

type MenuProps = {
  open: boolean;
};

export const ContainerAside = styled('aside')<MenuProps>(({ theme, open }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  transition: '0.2s',
  height: '100%',

  background: theme.palette.common.white,
  boxShadow: theme.shadows[2],
  zIndex: theme.zIndex.modal,

  '@media(max-width: 1200px)': {
    minWidth: '255px',
    position: 'fixed',
    left: open ? '-300px' : 0,
  },

  '@media(min-width: 1200px)': {
    maxWidth: '260px',
  },
}));

export const SidebarHeader = styled(Box)(() => ({
  marginTop: '36px',
  marginBottom: '24px',
  marginInline: 'auto',
}));

export const Section = styled(Box)(() => ({
  marginTop: '60px',
}));

export const SectionTitle = styled(Typography)(() => ({
  marginLeft: '16px',
  marginRight: '16px',
}));

export const SectionItems = styled('ul')(() => ({}));

export const ToggleMenu = styled('div')<MenuProps>(({ open }) => ({
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  transition: '0.2s',
  cursor: 'pointer',

  position: 'absolute',
  width: 24,
  height: 24,
  right: open ? -80 : -30,
  top: '50%',
  transform: open ? 'rotate(0deg)' : 'rotate(180deg)',

  '@media(max-width: 1199px)': {
    display: 'flex',
  },
}));

export const Pelicle = styled(Box)<MenuProps>(({ theme, open }) => ({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0,0,0,0.25)',
  zIndex: theme.zIndex.drawer,

  display: open ? 'none' : 'initial',
}));
