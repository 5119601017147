import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { Grid, Typography } from '@mui/material';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  CompletedLevelsStack,
  RegisteredMothersStack,
  SeeMore,
} from './styles';

const HeaderCards: React.FC = () => {
  return (
    <Grid
      container
      item
      lg={4}
      sm={14}
      xs={14}
      sx={{
        height: '156px',
        borderRadius: '10px',
        boxShadow: '0px 10px 43px rgba(0, 0, 0, 0.08)',
      }}
    >
      <Grid item xs={7.5}>
        <RegisteredMothersStack>
          <FaceRetouchingNaturalIcon />

          <Typography variant="h5">122</Typography>

          <Typography variant="caption">
            <FormattedMessage id="course_banner_total_moms" />
          </Typography>

          <SeeMore href="/#">
            <Typography variant="caption">
              <FormattedMessage id="course_banner_see_moms" />
            </Typography>
          </SeeMore>
        </RegisteredMothersStack>
      </Grid>

      <Grid item xs={6.5}>
        <CompletedLevelsStack>
          <InsertChartIcon />

          <Typography variant="h5">70%</Typography>

          <Typography variant="caption">
            <FormattedMessage id="course_banner_finished_levels" />
          </Typography>

          <SeeMore href="/#">
            <Typography variant="caption">
              <FormattedMessage id="course_banner_see_more" />
            </Typography>
          </SeeMore>
        </CompletedLevelsStack>
      </Grid>
    </Grid>
  );
};

export default HeaderCards;
