export default {
  // General messages
  platform: 'Platform',
  educational: 'Educational',
  search: 'Search',
  settings: 'Settings',

  see_more: 'See more',

  edit: 'Edit',
  save: 'Save',

  create_account: 'Create Account',

  delete: 'Delete',
  delete_account: 'Delete Account',
  delete_level: 'Delete Level',

  move_back: 'Move back',
  move_forward: 'Move forward',

  // Errors
  default_error: 'Something went wrong',

  // Form validation messages
  form_validation_invalid: 'The value is invalid',
  form_validation_required: 'This field is required',
  form_validation_email: 'Please enter a valid email address',
  form_validation_minLength: 'Please enter at least {minLength} characters',
  form_validation_maxLength: 'Please enter no more than {maxLength} characters',
  form_validation_minValue:
    'Please enter a value greater than or equal to {minValue}',
  form_validation_maxValue:
    'Please enter a value less than or equal to {maxValue}',
  form_validation_number: 'Please enter a number',
  form_validation_integer: 'Please enter an integer',
  form_validation_positive: 'Please enter a positive value',
  form_validation_password_confirm: 'Passwords do not match',
  form_validation_array_min: 'Please enter at least {minValue} item(s)',
  form_validation_array_max: 'Please enter no more than {maxValue} item(s)',

  // Delete Modal
  modal_delete_title: 'Are you sure do you want to delete this?',
  modal_delete_yes: 'Yes, Delete',
  modal_delete_no: 'No',
  modal_delete_workout: 'Are you sure do you want to delete this workout?',
  modal_delete_level: 'Are you sure do you want to delete this level?',
  modal_workout_name: 'Name:',
  modal_workout_description: 'Description:',

  // Sign In
  signin_title: 'Welcome to Valana',
  signin_field_email: 'E-mail',
  signin_field_password: 'Password',
  signin_button_signin: 'Enter',
  signin_remember_me: 'Remember-me',

  signin_error_email_password: 'Incorrect email/password combination',

  // Sidebar
  sidebar_dashboard: 'Dashboard',
  sidebar_users: 'Users',
  sidebar_payments: 'Payments',
  sidebar_coupons: 'Coupons',
  sidebar_settings: 'Settings',
  sidebar_course: 'Course',
  sidebar_workouts: 'Workouts',
  sidebar_moms: 'Moms',

  // Image Dropzone
  dropzone_default_text: 'Drag and drop image or click to upload',

  // TopBar
  topbar_menu_profile: 'Profile',
  topbar_menu_logout: 'Logout',

  // Dashboard
  dashboard_title: 'Valana | Dashboard',
  dashboard_bar_title: 'Welcome back, {name}!',

  // Users
  users_title: 'Valana | Users',
  users_bar_title: 'Users',
  users_breadcrumb: 'Users',

  users_team: 'Valana Team',
  users_team_new_member: 'New User',
  users_team_name: 'Name',
  users_team_email: 'Email',
  users_team_role: 'Role',

  users_team_role_admin: 'Admin',
  users_team_role_user: 'User',

  users_clients: 'Clients',
  users_clients_name: 'Name',
  users_clients_email: 'Email',
  users_clients_progress: 'Progress',
  users_clients_status: 'Status',

  users_clients_status_active: 'Active',
  users_clients_status_expired: 'Expired',

  // User Roles
  users_role_admin: 'Admin',

  // Messages
  users_load_failure: 'Failure to load users',
  users_creation_success: 'User created successfully!',
  users_creation_failure: 'Failure to create user',
  users_update_success: 'User updated successfully!',
  users_update_failure: 'Failure to update user',
  users_deletion_success: 'User deleted successfully!',
  users_deletion_failure: 'Failure to delete user',

  users_deletion_failure_delete_admin: "You can't delete an administrator",

  // Users Profile
  users_profile_section_general: 'General',
  users_profile_name: 'Name',
  users_profile_email: 'E-mail',
  users_profile_new_password: 'New Password',
  users_profile_role: 'Role',
  users_profile_select_role: 'Select role',
  users_profile_select_equipments: 'Equipments',

  users_profile_section_permissions: 'Permissions',

  // Clients Profile
  clients_profile_section_general: 'General',
  clients_profile_name: 'Name',
  clients_profile_email: 'E-mail',
  clients_profile_new_password: 'New Password',

  clients_profile_section_address: 'Address',
  clients_profile_address: 'Address',
  clients_profile_state: 'State',
  clients_profile_country: 'Country',
  clients_profile_postcode: 'Postcode',

  // Payments
  payments_title: 'Valana | Payments',
  payments_bar_title: 'Payments',
  payments_breadcrumb: 'Payments',

  payments_users_name: 'Name',
  payments_users_email: 'Email',
  payments_date: 'Last payment',
  payments_status: 'Status',
  payments_see_more: 'See more',

  payments_purchase_date: 'Purchase date',
  payments_method: 'PayPal',
  payments_price: 'Price',
  payments_total: 'Total',

  payments_status_active: 'Active',
  payments_status_expired: 'Expired',

  payments_filter_label: 'Filter by',
  payments_filter_all: 'All Payments',

  // Notifications
  notifications_trainingreminder_title: 'TRAINING REMINDER',
  notifications_trainingreminder_message:
    'The training reminder will notify the user by Email everyday when the users have training on the training plan',
  notifications_switch_weekly_training_plan_title: 'Weekly training plan',
  notifications_switch_weekly_training_plan_label:
    'Weekly training plan email at the start of the week.',
  notifications_switch_newsletter_title: 'Newsletter',
  notifications_switch_newsletter_label:
    'Active newsletter to receive information by Email.',

  // Settings
  settings_title: 'Valana | Settings',
  settings_bar_title: 'Settings',

  // Coupons
  coupons_breadcrumbs: 'Coupons',
  coupons_title: 'Coupons',
  coupons_bar_title: 'Coupons',
  coupons_description: 'Description',
  coupons_created_at: 'Created at',
  coupons_expire_at: 'Expire at',
  coupons_code: 'Code',
  coupons_discount: 'Discount',
  coupons_days_remaining: 'Days remaining',
  coupons_status: 'Status',
  coupons_expired: 'Expired',
  coupons_valid: 'Valid',
  coupons_times_used: 'Times used',
  coupons_uses_limit: 'Uses',
  coupons_new_coupon: 'New Coupon',
  new_coupon_breadcrumbs: 'New Coupon',
  new_coupon_bar_title: 'New Coupon',
  new_coupon_title: 'New Coupon',
  new_coupon_number_of_coupons: 'Coupons Quantity',
  new_coupon_description: 'Description',
  new_coupon_number_of_uses: 'Uses Quantity',
  new_coupon_expiration_date: 'Expiration date',
  new_coupon_discount: 'Discount percentage (1-100)',
  new_coupon_code: 'Code',
  new_coupon_use_unique: 'Unique use',
  new_coupon_create_coupon: 'Create Coupon',
  new_coupon_quantity_info:
    'Code is only available when the quantity is 1, otherwise the code will be generated automatically.',
  coupon_creation_failure: 'Something went wrong with the coupons creation',
  coupon_creation_success: 'Coupon(s) were created successfully',
  coupon_deletion_failure: 'Something went wrong with the coupons deletion',
  coupon_deletion_success: 'Coupon deleted successfully',

  // Course
  course_title: 'Valana | Course',
  course_bar_title: 'Course',
  course_breadcrumb: 'Course',
  course_rounds: 'Rounds',

  course_banner_total_moms: 'Total Registered moms',
  course_banner_see_moms: 'See moms',
  course_banner_finished_levels: 'Finished all levels',
  course_banner_see_more: 'See more',

  course_new_level: 'New Level',
  course_level_intro: 'Intro',
  course_level_mandatory: 'Mandatory',
  course_level_optional: 'Optional',

  course_modal_banner_title: 'Course configuration',
  course_modal_banner_name: 'Course Name',
  course_modal_banner_cover: 'Cover',
  course_modal_banner_price: 'Price',
  course_modal_banner_currency: 'Currency',

  course_modal_level_title: 'Level configuration',
  course_modal_level_delete: 'Delete Level',
  course_modal_level_save: 'Save',
  course_modal_level_name: 'Level name',
  course_modal_level_rounds: 'Rounds',

  course_modal_newlevel_title: 'Create new level',
  course_modal_newlevel_name: 'Level name',
  course_modal_newlevel_rounds: 'Rounds',

  // Messages
  course_banner_update_success: 'Course updated successfully!',
  course_banner_update_failure: 'Failure to update course',

  course_modal_level_update: 'Level updated successfully!',
  course_modal_level_update_failure: 'Failure to update level',

  course_modal_level_create: 'Level created successfully!',
  course_modal_level_create_failure: 'Failure to create level',

  course_modal_level_deleted: 'Level deleted successfully!',
  course_modal_level_deleted_failure: 'Failure to delete level',

  // Workouts
  workouts_title: 'Valana | Workouts',
  workouts_bar_title: 'Workouts',
  workouts_breadcrumb: 'Workouts',
  workouts_breadcrumb_new: 'New workout',

  workouts_new_workout: 'New Workout',
  workouts_add_workout: 'Add Workout',
  workouts_create_workout: 'Create Workout',

  workouts_filter_tag: 'Filter by tag',
  workouts_filter_by: 'Filter by',
  workouts_filter_by_first_added: 'First added',
  workouts_filter_by_last_added: 'Last added',

  // Messages
  workouts_load_failure: 'Failure to load workouts',

  // Workout Details
  workout_create: 'Create Workout',
  workout_delete: 'Delete Workout',
  workout_thumbnail: 'Thumbnail',
  workout_title: 'Video title',
  workout_mandatory: 'Mandatory',
  workout_labels: 'Labels',
  workout_description: 'Description',
  workout_goals: 'Goals',
  workout_advice: 'Advice',
  workout_optional_description: 'Optional description',
  workout_video: 'Video',

  workout_label: 'Label',
  workout_equipments: 'Equipments',
  workout_label_power: 'Power',
  workout_label_pelvic: 'Pelvic',
  workout_label_mobilization: 'Mobilization',
  workout_label_breathing: 'Breathing',
  workout_label_abdominal_muscles_strengthening:
    'Abdominal muscles strengthening',
  workout_label_pelvic_floor_activation: 'Pelvic floor activation',
  workout_label_buttocks_strengthening: 'Buttocks strengthening',
  workout_label_whole_body_strengthening: 'Whole body strengthening',
  workout_label_activate_pelvic_floor: 'Activate pelvic floor',
  workout_label_shoulder_girdle_mobilization: 'Shoulder girdle mobilization',
  workout_label_spine_mobilization: 'Spine mobilization',
  workout_label_back_strengthening: 'Back strengthening',
  workout_label_abdominal_muscles_activation: 'Abdominal muscles activation',
  workout_label_relaxation: 'Relaxation',
  workout_label_hip_mobilization: 'Hip mobilization',
  workout_label_pelvis_mobilization: 'Pelvis mobilization',
  workout_label_feel_pelvic_floor: 'Feel pelvic floor',
  workout_label_buttocks_relaxation_strengthening:
    'Buttocks relaxation strengthening',
  workout_label_intro: 'Intro',

  workout_yoga_mat: 'Yoga mat',
  workout_nursing_pillow_blanket: 'Nursing pillow/blanket',
  workout_possibly_two_towel_rolls: 'Possibly two towel rolls',
  workout_chair_or_stool: 'Chair or stool',
  workout_towel: 'Towel',
  workout_no_equip: 'No equipment required',

  workout_trainer: 'Trainer',
  trainer_rob: 'Rob',
  trainer_gloria: 'Gloria',
  trainer_mana: 'Mana',

  // Messages
  workout_video_upload_failure: 'Failure to load video',

  workout_load_failure: 'Failure to load workout',
  workout_creation_failure: 'Failure to create workout',
  workout_creation_success: 'Workout created successfully!',
  workout_update_failure: 'Failure to update workout',
  workout_update_success: 'Workout updated successfully!',
  workout_delete_failure: 'Failure to delete workout',
  workout_delete_success: 'Workout deleted successfully!',

  workouts_level_success: 'Workouts added successfully',
  workouts_level_failure: 'Failure to add workouts',

  workout_level_delete_success: 'Workout deleted successfully',
  workout_level_delete_failure: 'Failure to delete workout',

  // Image

  image_create_upload_failure: 'Failure to upload image',

  // Moms
  moms_title: 'Valana | Moms',
  moms_bar_title: 'Moms',
  moms_breadcrumb: 'Moms',

  moms_name: 'Name',
  moms_email: 'Email',
  moms_progress: 'Progress',
  moms_date: 'Date',

  // Profile
  profile_title: 'Valana | Profile',
  profile_bar_title: 'Users',
  profile_breadcrumb: 'Profile',
  profile_breadcrumb_me: 'My Profile',
  profile_breadcrumb_new_user: 'New User',

  // Client Profile
  client_profile_title: 'Valana | Client Profile',
  client_profile_bar_title: 'Users',
  client_profile_breadcrumb: 'Profile',

  client_profile_profile_tab: 'Profile',
  client_profile_notification_tab: 'Notification',
  client_profile_payment_tab: 'Payment',

  // Mom Profile
  mom_profile_profile_tab: 'Profile',
  mom_profile_progress_tab: 'Progress',

  // Currencies
  currency_eur: 'Euro',
  currency_usd: 'US Dollar',
};
