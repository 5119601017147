import { Settings as SettingsIcon } from '@mui/icons-material';
import { styled, Box, Typography } from '@mui/material';

export const Banner = styled(Box)(() => ({
  width: '100%',
  height: '156px',
  position: 'relative',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '10px',
}));

export const Image = styled('img')(() => ({
  width: '100%',
  height: '100%',
}));

export const SettingsButtonStyles = styled(SettingsIcon)(({ theme }) => ({
  position: 'absolute',
  width: '25px',
  height: '25px',
  top: '8px',
  right: '8px',
  padding: '6px',
  borderRadius: '50%',
  cursor: 'pointer',
  zIndex: 1,
  backgroundColor: theme.palette.background.default,
}));

export const Gradient = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  background:
    'linear-gradient(84.92deg, rgba(0, 0, 0, 0.8) 35.03%, rgba(0, 0, 0, 0) 100.44%)',
}));

export const Title = styled(Typography)(({ theme }) => ({
  width: '350px',
  position: 'absolute',
  color: theme.palette.primary.contrastText,
  zIndex: 1,
  top: '36px',
  left: '54px',

  '@media(max-width: 500px)': {
    width: '280px',
  },
}));
