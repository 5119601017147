import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { workoutsKeywords } from '~/helpers/pages/workouts';

import { FormFooter } from '~/components/FormComponents';
import { FormFooterProps } from '~/components/FormComponents/FormFooter';

type WorkoutFormFooterProps = FormFooterProps & {
  onDeleteWorkout: () => void;
};

const WorkoutFormFooter: React.FC<WorkoutFormFooterProps> = ({
  onDeleteWorkout,
  ...rest
}) => {
  const { workoutId } = useParams();
  const isToCreateWorkout = workoutId === workoutsKeywords.newWorkout;

  const footerSaveMessageId = useMemo(() => {
    return isToCreateWorkout ? 'workout_create' : 'save';
  }, [workoutId]);

  return (
    <FormFooter
      {...rest}
      saveMessageId={footerSaveMessageId}
      onDelete={isToCreateWorkout ? undefined : onDeleteWorkout}
    />
  );
};

export default WorkoutFormFooter;
