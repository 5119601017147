import { LinearProgressProps, Typography } from '@mui/material';

import React, { useMemo } from 'react';

import { Progress, ProgressBox } from './styles';

type Props = LinearProgressProps & {
  value: number;
};

const LinearProgressWithLabel: React.FC<Props> = ({ value, ...rest }) => {
  const valuePercent = useMemo(() => {
    return `${Math.round(value)}%`;
  }, [value]);

  return (
    <ProgressBox>
      <Progress variant="determinate" value={value} {...rest} />

      <Typography variant="body2" color="text.secondary">
        {valuePercent}
      </Typography>
    </ProgressBox>
  );
};

export default LinearProgressWithLabel;
