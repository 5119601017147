import * as yup from 'yup';

yup.setLocale({
  mixed: {
    notType: { id: 'form_validation_invalid' },
    required: { id: 'form_validation_required' },
  },
  string: {
    email: { id: 'form_validation_email' },
    max: ({ max }) => ({
      id: 'form_validation_maxLength',
      values: { maxLength: max },
    }),
    min: ({ min }) => ({
      id: 'form_validation_minLength',
      values: { minLength: min },
    }),
  },
  number: {
    integer: { id: 'form_validation_integer' },
    positive: { id: 'form_validation_positive' },
    moreThan: ({ more }) => ({
      id: 'form_validation_maxValue',
      values: { maxValue: more },
    }),
    lessThan: { id: 'form_validation_invalid' },
    max: ({ max }) => ({
      id: 'form_validation_maxValue',
      values: { maxValue: max },
    }),
    min: ({ min }) => ({
      id: 'form_validation_minValue',
      values: { minValue: min },
    }),
  },
  array: {
    max: ({ max }) => ({
      id: 'form_validation_array_max',
      values: { maxValue: max },
    }),
    min: ({ min }) => ({
      id: 'form_validation_array_min',
      values: { minValue: min },
    }),
  },
});

export default yup;
