import { Divider, styled } from '@mui/material';

import { TabPanelContainer } from '~/components/TabComponents';

export const FormCouponContainer = styled(TabPanelContainer)(() => ({
  marginTop: '64px',
}));

export const DividerStyle = styled(Divider)(() => ({
  marginTop: '20px',
  marginLeft: '20px',
}));
