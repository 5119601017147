import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

import _ from 'lodash';
import React, { useCallback } from 'react';

import useTranslate from '~/hooks/useTranslate';

interface Props extends Omit<TextFieldProps, 'onChange' | 'defaultValue'> {
  onChange: (value: string) => void;
}

const defaultDebounce = 200;

const SearchInput: React.FC<Props> = ({ onChange, value, ...rest }) => {
  const translate = useTranslate();

  const handleChange = useCallback(
    _.debounce((newValue: string) => onChange(newValue), defaultDebounce),
    [onChange]
  );

  return (
    <TextField
      fullWidth
      label={translate('search')}
      placeholder={translate('search')}
      defaultValue={value} // mui: need to be defaultValue
      onChange={(event) => handleChange(event.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default SearchInput;
