import yup from './default';

export const levelFormValidationSchema = yup.object().shape({
  name: yup.string().required(),
  rounds: yup.number().required(),
});

export const createNewLevelValidationSchema = yup.object().shape({
  name: yup.string().required(),
  rounds: yup.number().required(),
});

export const configurationCourseValidationSchema = yup.object().shape({
  name: yup.string().required(),
  // image: yup.string().required(),
});
