import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: ' 50%',
  left: '50%',

  width: '100%',
  maxWidth: '400px',

  padding: '40px 24px',
  transform: 'translate(-50%, -50%)',

  textAlign: 'center',
  borderRadius: '8px',

  background: theme.palette.background.paper,
  boxShadow: '-10px 16px 70px rgba(0, 0, 0, 0.25)',
}));
