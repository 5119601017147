import { styled, Grid, Divider } from '@mui/material';

export const GridFlex = styled(Grid)(() => ({
  display: 'flex',
}));

export const DividerStyle = styled(Divider)(() => ({
  width: '100%',
  marginBottom: '24px',
}));
