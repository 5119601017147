import {
  CloseSharp as CloseSharpIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material/';
import { IconButton, Modal } from '@mui/material';

import { omit } from 'lodash';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import useTypedSelector from '~/hooks/useTypedSelector';

import { CourseActions } from '~/store/ducks/course';
import { ImageActions } from '~/store/ducks/image';
import { Course, CourseForm } from '~/store/types/course';
import { ImageUploadCallbackData } from '~/store/types/image';

import LoadingButton from '~/components/Buttons/LoadingButton';

import ConfigurationCourseModalForm from './components/ConfigurationCourseModalForm';
import {
  CloseButton,
  BoxStyles,
  ActionButtonGroup,
  ModalTitle,
} from './styles';

interface Props {
  openModal: boolean;
  onClose: () => void;
  course: Course;
}

const ConfigurationCourseModal: React.FC<Props> = ({
  openModal,
  onClose,
  course,
}) => {
  const dispatch = useDispatch();

  const { loadingUpdate } = useTypedSelector((state) => state.course);

  const callback = () => {
    dispatch(CourseActions.getCourseRequest());
    onClose();
  };

  const handleImageUploaded = useCallback(
    (imageData: ImageUploadCallbackData, data: CourseForm) => {
      dispatch(
        CourseActions.updateCourseRequest(
          { imageId: imageData.id, name: data.name },
          callback
        )
      );
    },
    [dispatch, course]
  );

  const handleSubmit = useCallback(
    (data: CourseForm) => {
      const imageFile = data.image?.imageFile as File;
      if (imageFile) {
        dispatch(
          ImageActions.createUploadImageRequest(
            imageFile as File,
            (imageData) => handleImageUploaded(imageData, data)
          )
        );
      } else {
        dispatch(
          CourseActions.updateCourseRequest(omit(data, ['image']), callback)
        );
      }
    },
    [dispatch, course]
  );

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BoxStyles>
        <CloseButton>
          <IconButton onClick={onClose}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </CloseButton>

        <ModalTitle id="modal-modal-title" variant="h6" mb={3}>
          <SettingsIcon fontSize="small" sx={{ mr: 2 }} />
          <FormattedMessage id="course_modal_banner_title" />
        </ModalTitle>

        <ConfigurationCourseModalForm
          initialValues={{
            name: course.name,
            image: course.image,
            currency: course.currency,
            price: Number(course.price),
          }}
          onSubmit={handleSubmit}
        />

        <ActionButtonGroup>
          <LoadingButton
            variant="contained"
            color="primary"
            form="course-modal-form"
            type="submit"
            loading={loadingUpdate}
          >
            <FormattedMessage id="course_modal_level_save" />
          </LoadingButton>
        </ActionButtonGroup>
      </BoxStyles>
    </Modal>
  );
};

export default ConfigurationCourseModal;
