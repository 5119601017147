import {
  Delete as DeleteIcon,
  CloseSharp as CloseSharpIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material/';
import { IconButton, Modal } from '@mui/material';

import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Modify } from '~/types/util';

import useTypedSelector from '~/hooks/useTypedSelector';

import { CourseActions } from '~/store/ducks/course';
import { LevelActions } from '~/store/ducks/level';
import { Level, LevelForm } from '~/store/types/level';
import { Workout } from '~/store/types/workouts';

import LoadingButton from '~/components/Buttons/LoadingButton';
import DeleteModal, { DeleteModalRef } from '~/components/Modals/DeleteModal';

import LevelModalForm from './components/LevelModalForm';
import {
  CloseButton,
  BoxStyles,
  ActionButtonGroup,
  ModalTitle,
} from './styles';

type LevelProp = Modify<
  Level,
  {
    id?: string;
    mandatory?: Workout[];
    optional?: Workout[];
    intro?: Workout[];
  }
>;
interface Props {
  open: boolean;
  onClose: () => void;
  level?: LevelProp;
}

const LevelModal: React.FC<Props> = ({ open, onClose, level }) => {
  const { loadingDeletion, loadingUpdate, loadingCreation } = useTypedSelector(
    (state) => state.level
  );

  const dispatch = useDispatch();
  const isEdit = !!level?.id;

  const deleteModalRef = useRef<DeleteModalRef>(null);
  const callback = () => {
    dispatch(CourseActions.getCourseRequest());
    onClose();
  };

  const handleSubmit = useCallback(
    (data: LevelForm) => {
      if (level?.id) {
        dispatch(LevelActions.updateLevelRequest(level?.id, data, callback));
      } else {
        dispatch(LevelActions.createLevelRequest(data, callback));
      }
    },
    [dispatch, level]
  );

  const handleDeleteLevel = useCallback(() => {
    dispatch(LevelActions.deleteLevelRequest(level?.id as string, callback));
    deleteModalRef.current?.handleClose();
  }, [dispatch, level]);

  return (
    <>
      <DeleteModal
        ref={deleteModalRef}
        onConfirm={handleDeleteLevel}
        loadingDeletion={loadingDeletion}
      />

      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="level-modal"
        aria-describedby="level-modal"
      >
        <BoxStyles>
          <ModalTitle id="modal-modal-title" variant="h6" mb={3}>
            <SettingsIcon fontSize="small" sx={{ mr: 2 }} />
            <FormattedMessage id="course_modal_level_title" />
          </ModalTitle>

          <LevelModalForm
            initialValues={
              isEdit ? { rounds: level.rounds, name: level.name } : level
            }
            onSubmit={handleSubmit}
          />

          <CloseButton>
            <IconButton onClick={onClose}>
              <CloseSharpIcon fontSize="small" />
            </IconButton>
          </CloseButton>

          <ActionButtonGroup>
            {isEdit ? (
              <LoadingButton
                variant="outlined"
                color="error"
                loading={loadingDeletion}
                startIcon={<DeleteIcon fontSize="small" />}
                onClick={() => deleteModalRef.current?.handleOpen()}
              >
                <FormattedMessage id="course_modal_level_delete" />
              </LoadingButton>
            ) : (
              <span />
            )}

            <LoadingButton
              variant="contained"
              type="submit"
              form="level-modal-form"
              loading={isEdit ? loadingUpdate : loadingCreation}
            >
              <FormattedMessage id="course_modal_level_save" />
            </LoadingButton>
          </ActionButtonGroup>
        </BoxStyles>
      </Modal>
    </>
  );
};

export default LevelModal;
