export const workoutsLabelsOptions = [
  { value: 'breathing', labelId: 'workout_label_breathing' },
  {
    value: 'abdominal_muscles_strengthening',
    labelId: 'workout_label_abdominal_muscles_strengthening',
  },
  {
    value: 'pelvic_floor_activation',
    labelId: 'workout_label_pelvic_floor_activation',
  },
  {
    value: 'buttocks_strengthening',
    labelId: 'workout_label_buttocks_strengthening',
  },
  {
    value: 'whole_body_strengthening',
    labelId: 'workout_label_whole_body_strengthening',
  },
  {
    value: 'activate_pelvic_floor',
    labelId: 'workout_label_activate_pelvic_floor',
  },
  {
    value: 'shoulder_girdle_mobilization',
    labelId: 'workout_label_shoulder_girdle_mobilization',
  },
  { value: 'spine_mobilization', labelId: 'workout_label_spine_mobilization' },
  { value: 'back_strengthening', labelId: 'workout_label_back_strengthening' },
  {
    value: 'abdominal_muscles_activation',
    labelId: 'workout_label_abdominal_muscles_activation',
  },
  { value: 'relaxation', labelId: 'workout_label_relaxation' },
  { value: 'hip_mobilization', labelId: 'workout_label_hip_mobilization' },
  {
    value: 'pelvis_mobilization',
    labelId: 'workout_label_pelvis_mobilization',
  },
  { value: 'feel_pelvic_floor', labelId: 'workout_label_feel_pelvic_floor' },
  {
    value: 'buttocks_relaxation_strengthening',
    labelId: 'workout_label_buttocks_relaxation_strengthening',
  },
  {
    value: 'intro',
    labelId: 'workout_label_intro',
  },
];
