import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MuiChip = {
  defaultProps?: ComponentsProps['MuiChip'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiChip'];
  variants?: ComponentsVariants['MuiChip'];
};

const styles = (_theme: Theme): MuiChip => ({
  defaultProps: {
    size: 'small',
  },
});

export default styles;
