import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MuiGrid = {
  defaultProps?: ComponentsProps['MuiGrid'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiGrid'];
  variants?: ComponentsVariants['MuiGrid'];
};

const styles = (_theme: Theme): MuiGrid => ({
  defaultProps: {
    columns: 14,
  },
});

export default styles;
