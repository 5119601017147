import { ButtonBase, styled } from '@mui/material';

export const SeeMoreButton = styled(ButtonBase)(({ theme }) => ({
  width: 'fit-content',

  marginTop: theme.spacing(5),
  marginInline: 'auto',

  '&:hover > .MuiTypography-root': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
