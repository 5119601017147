export const workoutsEquipmentsOptions = [
  { value: 'yoga_mat', labelId: 'workout_yoga_mat' },
  {
    value: 'nursing_pillow_blanket',
    labelId: 'workout_nursing_pillow_blanket',
  },
  {
    value: 'possibly_two_towel_rolls',
    labelId: 'workout_possibly_two_towel_rolls',
  },
  { value: 'chair_or_stool', labelId: 'workout_chair_or_stool' },
  { value: 'towel', labelId: 'workout_towel' },
  { value: 'no_equipment', labelId: 'workout_no_equip' },
];
