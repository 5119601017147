import {
  TablePagination as MuiTablePagination,
  TablePaginationProps as MuiTablePaginationProps,
} from '@mui/material';

import React, { useCallback } from 'react';

import { getNewPageBasedOnLimit } from '~/helpers/table/pagination';
import { defaultTableOptions } from '~/helpers/table/state';

export interface TablePaginationOptions {
  page: number;
  limit: number;
  total: number;
}

export interface LimitChangeProps {
  page: number;
  limit: number;
}

type MuiPaginationProps = Omit<
  MuiTablePaginationProps,
  'page' | 'count' | 'rowsPerPage' | 'onPageChange' | 'onRowsPerPageChange'
>;

interface MyProps {
  onPageChange: (newPage: number) => void;
  onLimitChange: (props: LimitChangeProps) => void;
  options: TablePaginationOptions;
}

type Props = MyProps & MuiPaginationProps;

const defaultRowsPerPage = defaultTableOptions.pagination.rowsPerPageOptions;

const TablePagination: React.FC<Props> = ({
  options,
  onPageChange,
  onLimitChange,
  ...rest
}) => {
  const { page, limit, total } = options;

  const handleChangePage = useCallback(
    (_, newPage) => {
      onPageChange(newPage + 1); // on mui start with 0 but on api start with 1
    },
    [onPageChange]
  );

  const handleChangeLimit = useCallback(
    (event) => {
      const newLimit = parseInt(event.target.value, 10);

      const newPage = getNewPageBasedOnLimit({
        page,
        limit,
        newLimit,
      });

      onLimitChange({
        page: newPage,
        limit: newLimit,
      });
    },
    [page, limit, onLimitChange]
  );

  return (
    <MuiTablePagination
      {...rest}
      component="div"
      rowsPerPageOptions={defaultRowsPerPage}
      colSpan={3}
      count={total}
      rowsPerPage={limit}
      page={page - 1} // on mui start with 0 but on api start with 1
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page',
        },
      }}
      sx={{
        overflow: 'initial',
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeLimit}
    />
  );
};

export default TablePagination;
