import { Avatar, Box, Button, styled } from '@mui/material';

export const AvatarBox = styled(Box)(() => ({
  position: 'relative',
  width: 'fit-content',

  marginLeft: 'auto',
  marginRight: 'auto',
}));

export const AvatarStyled = styled(Avatar)(() => ({
  width: '166px',
  height: '166px',
  cursor: 'pointer',
}));

export const AvatarEditButton = styled(Button)(() => ({
  position: 'absolute',
  right: '2px',
  top: 'calc(50% + 26px)',

  minWidth: '42px',
  height: '42px',

  padding: '0',
  borderRadius: '50%',

  '&:active, &:hover': {
    boxShadow: 'none',
  },
}));

export const InputStyled = styled('input')(() => ({}));
