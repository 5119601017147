/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  TextField,
  TextFieldProps,
} from '@mui/material';

import React, { SyntheticEvent, useCallback, useMemo } from 'react';

import useTranslate from '~/hooks/useTranslate';

type AutocompleteProps = Omit<
  MuiAutocompleteProps<TextFieldProps, boolean, boolean, boolean>,
  'options' | 'renderInput' | 'onChange' | 'value'
>;

export type FilterAutocompleteOptionId = {
  labelId: string;
  value: string;
};

export type FilterAutocompleteOption = FilterAutocompleteOptionId & {
  label: string;
};

export type OptionLabel = FilterAutocompleteOption | string;

export interface FilterAutocompleteProps extends AutocompleteProps {
  labelId: string;
  optionsLabelsIds?: FilterAutocompleteOptionId[];

  helperText?: React.ReactNode;
  error?: boolean;
  inputRef?: React.Ref<any>;

  value?: FilterAutocompleteOptionId | FilterAutocompleteOptionId[];
  onChange?: (
    event: SyntheticEvent,
    option: OptionLabel | OptionLabel[]
  ) => void;
}

const FilterAutocomplete: React.FC<FilterAutocompleteProps> = ({
  optionsLabelsIds,
  labelId,
  helperText,
  error,
  inputRef,
  onChange,
  ...rest
}) => {
  const translate = useTranslate();

  const optionsLabels = useMemo(() => {
    if (!optionsLabelsIds) {
      return [];
    }

    return optionsLabelsIds.map((option) => ({
      label: translate(option.labelId),
      labelId: option.labelId,
      value: option.value,
    }));
  }, [optionsLabelsIds, translate]);

  const findOptionByValue = useCallback(
    (value: string) => optionsLabels.find((option) => option.value === value),
    [optionsLabels]
  );

  const isOptionEqualToValue = useCallback(
    (option: FilterAutocompleteOption, optionLabel: OptionLabel) => {
      const isString = typeof optionLabel === 'string';

      return isString
        ? option.value === optionLabel
        : option.value === optionLabel.value;
    },
    []
  );

  const getOptionLabel = useCallback(
    (optionLabel: OptionLabel) => {
      const isString = typeof optionLabel === 'string';

      if (isString) {
        const option = findOptionByValue(optionLabel);
        return option?.label || '';
      }

      if (optionLabel.label) {
        return optionLabel.label;
      }

      const option = findOptionByValue(optionLabel.value);
      return option?.label || '';
    },
    [findOptionByValue]
  );

  const handleChange = useCallback(
    (event, value) => {
      if (onChange) onChange(event, value as any);
    },
    [onChange]
  );

  return (
    <Autocomplete
      disableClearable // can be overwritten
      {...rest}
      options={optionsLabels}
      isOptionEqualToValue={(option, value) =>
        isOptionEqualToValue(option as any, value as any)
      }
      getOptionLabel={(optionLabel) => getOptionLabel(optionLabel as any)}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          label={translate(labelId)}
          helperText={helperText}
          error={error}
        />
      )}
    />
  );
};

export default FilterAutocomplete;
