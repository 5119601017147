import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, Link as ReactRouterLink } from 'react-router-dom';

const Breadcrumbs: React.FC = () => {
  const { momId } = useParams();

  return (
    <MuiBreadcrumbs
      aria-label="navigation-breadcrumb"
      separator={<ChevronRightIcon />}
    >
      <Link
        component={ReactRouterLink}
        underline="hover"
        color="inherit"
        to="/"
      >
        <FormattedMessage id="platform" />
      </Link>

      <Link
        component={ReactRouterLink}
        underline="hover"
        color="text.primary"
        to="/moms"
      >
        <Typography variant="body1">
          <FormattedMessage id="moms_breadcrumb" />
        </Typography>
      </Link>

      <Link
        underline="hover"
        component={ReactRouterLink}
        color="text.primary"
        to={`/moms/${momId}`}
        aria-current="page"
      >
        <Typography variant="body1">Carla Peres</Typography>
      </Link>
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
