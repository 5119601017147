import {
  CouponsYuploadOutput,
  CouponsCreationOutput,
} from '~/store/types/coupons';

export const formatOutputCouponCreation = (data: CouponsYuploadOutput) => {
  const code = data.quantity > 1 ? null : data.code;
  const { quantity } = data;
  const usesLimit = data.unique ? 1 : data.usesLimit;
  const discount = Number(data.discount / 100);

  const newData: CouponsCreationOutput = {
    quantity,
    expireAt: data.expirationDate,
    usesLimit,
    discount,
  };

  if (code) {
    newData.code = code;
  }

  return newData;
};
