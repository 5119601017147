import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import useTypedSelector from '~/hooks/useTypedSelector';

import { WorkoutsActions } from '~/store/ducks/workouts';
import { PaginationOptions } from '~/store/types/pagination';

import FilterAutocomplete, {
  FilterAutocompleteOption,
  OptionLabel,
} from '~/components/Inputs/FilterAutocomplete';

const workoutsLabelsIds = [
  {
    labelId: 'workouts_filter_by_first_added',
    value: 'first_added',
  },
  {
    labelId: 'workouts_filter_by_last_added',
    value: 'last_addded',
  },
];

const WorkoutsFilterBy: React.FC = () => {
  const { options } = useTypedSelector((state) => state.workouts);

  const dispatch = useDispatch();

  const validateFilterByAndUpdate = useCallback(
    (_event, option: OptionLabel | OptionLabel[]) => {
      const optionTyped = option as FilterAutocompleteOption;

      const newOptions: PaginationOptions = {
        sort: 'createdAt',
        direction: optionTyped.value === 'first_added' ? 'asc' : 'desc',
      };

      dispatch(WorkoutsActions.updateWorkoutsOptions(newOptions));
    },
    [options]
  );

  const filterBy = useMemo(() => {
    if (options.sort === '' || options.sort !== 'createdAt') {
      return workoutsLabelsIds[0];
    }

    return options.direction === 'asc'
      ? workoutsLabelsIds[0]
      : workoutsLabelsIds[1];
  }, [options]);

  return (
    <FilterAutocomplete
      value={filterBy}
      labelId="workouts_filter_by"
      optionsLabelsIds={workoutsLabelsIds}
      onChange={validateFilterByAndUpdate}
    />
  );
};

export default WorkoutsFilterBy;
