import {
  InsertChart as InsertChatIcon,
  SupervisorAccount as SupervisorAccountIcon,
  // Settings as SettingsIcon,
  School as SchoolIcon,
  AccessibilityNew as AccessibilityNewIcon,
  // FaceRetouchingNatural as FaceRetouchingNaturalIcon,
  AttachMoney as AttachMoneyIcon,
  Discount as DiscountIcon,
} from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from '@mui/material';

import React, { useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { ReactComponent as LogoSvg } from '~/assets/images/logo.svg';

import useTranslate from '~/hooks/useTranslate';

import SidebarItem from '../SidebarItem';
import {
  ContainerAside,
  Pelicle,
  Section,
  SectionItems,
  SectionTitle,
  SidebarHeader,
  ToggleMenu,
} from './styles';

const Sidebar: React.FC = () => {
  const translate = useTranslate();

  const [open, setOpen] = useState(true);

  const handleMenu = () => {
    setOpen(!open);
  };

  return (
    <>
      <ContainerAside open={open}>
        <SidebarHeader>
          <Link component={ReactRouterLink} to="/">
            <LogoSvg width="150px" height="68px" />
          </Link>
        </SidebarHeader>
        <Section>
          <SectionTitle variant="overline">Platform</SectionTitle>

          <SectionItems>
            <SidebarItem
              href="/"
              text={translate('sidebar_dashboard')}
              Icon={InsertChatIcon}
            />
            <SidebarItem
              href="/users"
              text={translate('sidebar_users')}
              Icon={SupervisorAccountIcon}
            />
            <SidebarItem
              href="/payments"
              text={translate('sidebar_payments')}
              Icon={AttachMoneyIcon}
            />
            <SidebarItem
              href="/coupons"
              text={translate('sidebar_coupons')}
              Icon={DiscountIcon}
            />
            {/* <SidebarItem
              href="/settings"
              text={translate('sidebar_settings')}
              Icon={SettingsIcon}
            /> */}
          </SectionItems>
        </Section>
        <Section>
          <SectionTitle variant="overline">Educational</SectionTitle>

          <SectionItems>
            <SidebarItem
              href="/course"
              text={translate('sidebar_course')}
              Icon={SchoolIcon}
            />
            <SidebarItem
              href="/workouts"
              text={translate('sidebar_workouts')}
              Icon={AccessibilityNewIcon}
            />

            {/* Will be no continued for now */}

            {/* <SidebarItem
            href="/moms"
            text={translate('sidebar_moms')}
            Icon={FaceRetouchingNaturalIcon}
          /> */}
          </SectionItems>
        </Section>
        <ToggleMenu onClick={handleMenu} open={open}>
          <ArrowForwardIosIcon fontSize="small" color="secondary" />
        </ToggleMenu>
      </ContainerAside>
      <Pelicle open={open} onClick={handleMenu} />
    </>
  );
};

export default Sidebar;
