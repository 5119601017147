import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

type MuiInput = {
  defaultProps?: ComponentsProps['MuiInput'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInput'];
  variants?: ComponentsVariants['MuiInput'];
};

const styles = (theme: Theme): MuiInput => ({
  styleOverrides: {
    underline: {
      '&:before': {
        borderBottomColor: theme.palette.grey[600],
      },
    },
  },
});

export default styles;
