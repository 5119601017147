import { styled, Typography } from '@mui/material';

export const SignInFormRoot = styled('form')(() => ({
  width: '100%',
}));

export const WelcomeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: 0,
  textAlign: 'center',
}));
