import { TabContext, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';

import React, { useCallback, useState } from 'react';

import useTranslate from '~/hooks/useTranslate';

import { PageContainerBottom } from '~/components/PageContainer';
import { TabList } from '~/components/TabComponents';

import Breadcrumbs from './components/Breadcrumbs';
import MomProfilePanel from './components/MomProfilePanel';
import MomProgresPanel from './components/MomProgresPanel';

const MomProfile: React.FC = () => {
  const translate = useTranslate();

  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = useCallback((_event, newValue: string) => {
    setActiveTab(newValue);
  }, []);

  return (
    <PageContainerBottom>
      <Breadcrumbs />

      <TabContext value={activeTab}>
        <TabList
          onChange={handleTabChange}
          aria-label="navigation-tabs"
          variant="fullWidth"
        >
          <Tab label={translate('mom_profile_profile_tab')} value="1" />
          <Tab label={translate('mom_profile_progress_tab')} value="2" />
        </TabList>

        <TabPanel value="1">
          <MomProfilePanel />
        </TabPanel>

        <TabPanel value="2">
          <MomProgresPanel />
        </TabPanel>
      </TabContext>
    </PageContainerBottom>
  );
};

export default MomProfile;
