import { Avatar, Box, styled } from '@mui/material';

export const ChecksBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  columnGap: theme.spacing(1),
  marginLeft: theme.spacing(3),
}));

export const LevelUserAvatar = styled(Avatar)(({ theme }) => ({
  width: '32px',
  height: '32px',

  marginLeft: 'auto',
  border: `2px solid ${theme.palette.primary.main}`,
}));
