/* eslint-disable @typescript-eslint/no-explicit-any */
import { SliderProps, Slider } from '@mui/material';

import React from 'react';
import { Control, Controller } from 'react-hook-form';

type RhfProps = {
  name: string;
  control: Control;
  min: number;
  max: number;
  variant?: 'filled' | 'standard' | 'outlined';
};

type Props = SliderProps & RhfProps;

const RhfSlider: React.FC<Props> = ({ name, control, ...rest }) => {
  return (
    <Controller
      render={({ field: { onChange, value, ref, ...props } }) => (
        <Slider
          value={value || 1}
          onChange={onChange}
          ref={ref}
          {...props}
          {...rest}
        />
      )}
      name={name}
      control={control}
    />
  );
};

export default RhfSlider;
